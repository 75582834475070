import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useListController, useTranslate, AutocompleteInput, ListView, Datagrid, DateField, TextField, Filter, ReferenceInput, BulkExportButton, TextInput, NumberInput, TopToolbar, CreateButton, ExportButton, sanitizeListRestProps, ListContext } from 'react-admin';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import SearchInput from '../Inputs/SearchInput';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import customExporter, { dateFormatter, enumFormatter } from '../../exporter';
import EnumField from '../Fields/EnumField';
import { ability } from "../../abilityProvider";
import EnumInput from '../Inputs/EnumInput'
import TagArrayField from "../Tag/TagArrayField";
import TagChip from "../Tag/TagChip";
import StatusChip from "./GasMeterStatusChip";
import MoreButton from "../Buttons/MoreButton";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";



const matchTrue = () => true;


const useFilterStyles = makeStyles(theme => ({
    mediumWidth: { width: theme.spacing(19) },
}));

const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const GasMeterBulkActionButtons = props => (
    <>
        {ability.can('remove', 'GasMeter') && <BulkRemoveButton {...props} />}
        {ability.can('getList', 'GasMeter') && <BulkExportButton {...props} />}
    </>
);

const GasMeterFilter = (props) => {
    const classes = useFilterStyles();
    return (
        <ManualSubmitFilter {...props}>
            <SearchInput source="q" alwaysOn resettable />
            {ability.can('getList', 'GasMeter', 'dbId') && <NumberInput source="dbId" alwaysOn resettable className={classes.mediumWidth} />}
            {ability.can('getList', 'GasMeter', 'device') && <ReferenceInput source="device.id" reference="Device" label="resources.GasMeter.fields.device" allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {ability.can('getList', 'GasMeter', 'gasMeterUnit') && <ReferenceInput source="gasMeterUnit.id" reference="GasMeterUnit" label="resources.GasMeter.fields.gasMeterUnit" allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {ability.can('getList', 'GasMeter', 'configuration') && <ReferenceInput source="configuration.id" reference="GasMeterConfiguration" label="resources.GasMeter.fields.configuration" allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {ability.can('getList', 'GasMeter', 'serialNumber') && <TextInput source="serialNumber" />}
            {ability.can('getList', 'GasMeter', 'status') && <EnumInput source="status" Enum="GasMeterStatus" />}
            {ability.can('getList', 'GasMeter', 'type') && <EnumInput source="type" Enum="GasMeterType" option="resources.GasMeterType.enums.type" />}
            {ability.can('getList', 'GasMeter', 'tag') && <ReferenceInput source="tags_some.id" label="resources.Tag.fields.tags_some" reference="Tag" filter={{ resource: "GasMeter" }} allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>}
        </ManualSubmitFilter>
    )
};

const enumTypeFormatter = (key, value, model, record, translate) => translate(`resources.GasMeterType.enums.${key}.${value}`, { _: value });

const modelExport = {
    id: true,
    dbId: true,
    name: true,
    description: true,
    createdBy: {
        id: true,
        name: true,
        __resource: "User",
    },
    createdAt: dateFormatter,
    updatedAt: dateFormatter,
    status: enumFormatter,
    type: enumTypeFormatter,
    port: true,
    serialNumber: true,
    commercialHour: true,
    timeZone: true,
    controller: {
        id: true,
        name: true,
        __resource: "Controller",
    },
    gasMeterUnit: {
        id: true,
        name: true,
        __resource: "GasMeterUnit",
    },
    configuration: {
        id: true,
        name: true,
        __resource: "GasMeterConfiguration",
    },
    tags: {
        id: true,
        name: true,
        __resource: "Tag",
    },
    __resource: "GasMeter",
};


const GasMeterList = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const controllerProps = useListController(props);
    const exporter = customExporter(controllerProps.defaultTitle, modelExport, { translate })
    const manualSubmitForm = React.useRef(null);

    return (
        <ListContext.Provider value={{ ...controllerProps, exporter }}>
            <ListView
                {...props}
                {...controllerProps}
                bulkActionButtons={<GasMeterBulkActionButtons />}
                filters={<GasMeterFilter formRef={manualSubmitForm} />}
            >
                <Datagrid rowClick="show" classes={{ row: classes.row }}>
                    {ability.can('getList', 'GasMeter', 'status') && <StatusChip cellClassName={classes.cellMinWidth} />}
                    {ability.can('getList', 'GasMeter', 'name') && <TextField source="name" />}
                    {ability.can('getList', 'GasMeter', 'description') && <TextField source="description" />}
                    {ability.can('getList', 'GasMeter', 'type') && <EnumField source="type" option="resources.GasMeterType.enums.type" />}
                    {ability.can('getList', 'GasMeter', 'serialNumber') && <TextField source="serialNumber" label="resources.GasMeter.fields.sn" sortable={false} />}
                    {/* {ability.can('getList', 'GasMeter', 'configuration') && <TextField source="configuration.name" label="resources.GasMeter.fields.configuration" sortable={false}/>} */}
                    {ability.can('getList', 'GasMeter', 'tags') && <TagArrayField source="tags" sortable={false}>
                        <TagChip />
                    </TagArrayField>}
                    <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                        {ability.can('getOne', 'GasMeter') && <ShowBlankItem />}
                        {ability.can('update', 'GasMeter') && <EditMenuItem />}
                        {ability.can('remove', 'GasMeter') && <RemoveMenuItem />}
                    </MoreButton>
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

GasMeterList.defaultProps = {
    filter: {},
    perPage: 25,
    sort: { field: 'name', order: 'ASC' }
};


export default GasMeterList;

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import RestoreIcon from '@material-ui/icons/RestoreFromTrash';
import { useRefresh, useNotify, useRedirect, fetchStart, fetchEnd, Button } from 'react-admin';
import restoreFromTrash from '../../dataProvider/Core/restoreFromTrash';



export const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    undoable,
    redirect,
    submitOnEnter,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    restoreButton: {},
}));

const RestoreButton = ({
    classes: classesOverride,
    className,
    onClick,
    resource,
    record,
    basePath,
    redirect: redirectTo,
    ...rest
}) => {
    const classes = useStyles({ classes: classesOverride });
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    const handleRestore = () => {
        setLoading(true);
        dispatch(fetchStart()); // start the global loading indicator 

        restoreFromTrash({
            resource,
            where: { id: record.id }
        })
            .then(() => {
                notify('ra.notification.restored_from_trash', 'info', { smart_count: 1 });
                redirect(redirectTo, basePath);
                refresh();
            })
            .catch((error) => {
                notify(
                    typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
                    'warning'
                )
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    };

    return (
        <Button
            onClick={handleRestore}
            disabled={loading}
            variant="contained"
            size='medium'
            label='ra.action.restore'
            className={`ra-restore-button ${classes.restoreButton} ${className}`}
            key="button"
            {...sanitizeRestProps(rest)}
        >
            <RestoreIcon />
        </Button>
    );
};

RestoreButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string,
};

RestoreButton.defaultProps = {
    redirect: false,
};

export default RestoreButton;

import React, { useContext } from 'react';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import { makeStyles } from '@material-ui/core/styles';
import ConnectContext from "./ConnectContext";


const useStyles = makeStyles(theme => ({
    root: {
        margin: ".125rem",
    },
    icon: ({ loading, error }) => ({
        color: error ? "#e53935" : loading ? "#616161" : "#388e3c"
    }),
}));


const ConnectChip = (props) => {
    const { loading, error } = useContext(ConnectContext)
    const classes = useStyles({ loading, error });


    const icon = error ? <PowerOffIcon className={classes.icon} /> : loading ? <CircularProgress className={classes.icon} size={16} thickness={4} /> : <PowerIcon className={classes.icon} />
    const title = error ? "Ошибка" : loading ? "Подключение..." : "Подключено"

    return <Chip
        classes={{ root: classes.root }}
        size="small"
        icon={icon}
        title={title}
        label="Шина данных"
    />;
}

export default ConnectChip;
import { ChipField, DateField, EditButton, Pagination, ReferenceManyField, SimpleShowLayout, SingleFieldList, TextField, TopToolbar, ShowContextProvider, useShowController } from 'react-admin';
import Alert from '../Fields/Alert';
import CardBlankLayout from '../Layout/CardBlankLayout';
import CardShowLayout from '../Layout/CardShowLayout';
import CloneButton from '../Buttons/CloneButton';
import ControllerBanner from './ControllerBanner';
import EnumField from '../Fields/EnumField';
import JsonField from '../Fields/JsonField';
import JsonVariableField from '../Fields/JsonVariableField';
import JsonVariableFields from '../Fields/JsonVariableFields';
import MoreContent from '../Layout/MoreContent';
import NestedArrayField from '../Fields/NestedArrayField';
import NestedField from '../Fields/NestedField';
import PhoneField from '../Fields/PhoneField';
import React from 'react';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import Separator from '../Layout/Separator';
import { ShowView } from '../Layout/ShowView';
import SwapButton from './ControllerSwapButton';
import TitleForRecord from '../Layout/TitleForRecord';
import { ability } from "../../abilityProvider";

// import ConnectButton from '../Buttons/ConnectButton';




const ControllerShowActions = ({ resource, basePath, className, data, hasEdit, hasList, ...props }) => {

    const isActive = data && data.status === "ACTIVE"

    return (
        <TopToolbar {...props}>
            {ability.can('create', 'Controller') && <CloneButton basePath={basePath} record={data} omit={['id', 'status', 'gasMeters', 'gasMetersIds', 'gasMeterUnits', 'gasMeterUnitsIds']} />}
            {ability.can('swap', 'Controller') && isActive && <SwapButton resource={resource} basePath={basePath} record={data} />}
            {/* {ability.can('connect', 'Controller') && <ConnectButton resource={resource} basePath={basePath} record={data} />} */}
            {ability.can('update', 'Controller') && <EditButton basePath={basePath} record={data} />}
        </TopToolbar>
    );
};

export const ControllerQuickShowLayout = props => {
    return (
        <SimpleShowLayout {...props} >
            {ability.can('getOne', 'Controller', 'type') && <EnumField source="type" option="resources.ControllerType.enums.type" />}
            {ability.can('getOne', 'Controller', 'configuration') && <NestedField source="configuration" reference="ControllerConfiguration" >
                <TextField source="name" />
            </NestedField>}
            {ability.can('getOne', 'ControllerConfiguration', 'mode') && <EnumField source="configuration.mode" label="resources.ControllerConfiguration.fields.mode" option="resources.ControllerConfiguration.enums.mode" />}
            {ability.can('getOne', 'Controller', 'serialNumber') && <TextField source="serialNumber" />}
            {ability.can('getOne', 'Controller', 'url') && <TextField source="url" />}
            {ability.can('getOne', 'Controller', 'phoneNumber') && <PhoneField source="phoneNumber" />}
            {ability.can('getOne', 'Controller', 'metaVariable') && <JsonVariableFields source="meta.metaVariable" >
                <JsonVariableField />
            </JsonVariableFields>}
            {ability.can('getOne', 'Controller', 'meta') && <JsonField source="meta" fullWidth />}
            {ability.can('getOne', 'Controller', 'gasMeters') && <NestedArrayField source="gasMeters" reference="GasMeter" linkType="show" >
                <ChipField source="name" />
            </NestedArrayField>}
        </SimpleShowLayout>
    );
};

const ControllerShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);
    const configurationStatus = controllerProps.record && controllerProps.record.configuration && controllerProps.record.configuration.status;

    return (
        <ShowContextProvider value={controllerProps}>
            <ShowView actions={<ControllerShowActions />} title={<TitleForRecord source="name" {...props} />} {...props} {...controllerProps}  >
                <ControllerBanner />

                <CardShowLayout>
                    {ability.can('getOne', 'Controller', 'type') && <EnumField source="type" option="resources.ControllerType.enums.type" />}
                    {ability.can('getOne', 'Controller', 'configuration') && <NestedField source="configuration" reference="ControllerConfiguration" >
                        <TextField source="name" />
                    </NestedField>}
                    {(configurationStatus && configurationStatus !== "RELEASE") && <Alert severity="warning">Внимание. Установленная конфигурация еще не выпущена в Релиз!</Alert>}
                    {ability.can('getOne', 'ControllerConfiguration', 'mode') && <EnumField source="configuration.mode" label="resources.ControllerConfiguration.fields.mode" option="resources.ControllerConfiguration.enums.mode" />}
                    {ability.can('getOne', 'Controller', 'serialNumber') && <TextField source="serialNumber" />}
                    {ability.can('getOne', 'Controller', 'url') && <TextField source="url" />}
                    {ability.can('getOne', 'Controller', 'phoneNumber') && <PhoneField source="phoneNumber" />}
                    {ability.can('getOne', 'Controller', 'metaVariable') && <JsonVariableFields source="meta.metaVariable" >
                        <JsonVariableField />
                    </JsonVariableFields>}
                    {ability.can('getOne', 'Controller', 'meta') && <JsonField source="meta" fullWidth />}
                    {ability.can('getOne', 'Controller', 'gasMeters') && <NestedArrayField source="gasMeters" reference="GasMeter" linkType="show" >
                        <ChipField source="name" />
                    </NestedArrayField>}
                    {ability.can('getOne', 'Controller', 'device') && <ReferenceManyField target="controllerId" reference="Device" label="resources.Controller.fields.device" >
                        <SingleFieldList linkType="show">
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceManyField>}
                </CardShowLayout>

                <Separator action={(<div ref={showMoreButton} ></div>)} />

                <MoreContent showMoreButton={showMoreButton}>
                    <CardShowLayout>
                        {ability.can('getOne', 'Controller', 'id') && <TextField source="id" />}
                        {ability.can('getOne', 'Controller', 'createdAt') && <DateField source="createdAt" />}
                        {ability.can('getOne', 'Controller', 'createdBy') && <TextField source="createdBy.name" label="resources.Controller.fields.createdBy" />}
                        {ability.can('getOne', 'Controller', 'updatedAt') && <DateField source="updatedAt" />}
                    </CardShowLayout>
                </MoreContent>

                <Separator action={(<div ref={showHistoryButton} ></div>)} />

                <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                    <CardBlankLayout>
                        <ReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                            <ReferenceHistoryDatagrid source="Controller" />
                        </ReferenceManyField>
                    </CardBlankLayout>
                </MoreContent>
            </ShowView>
        </ShowContextProvider>
    );
};

export default ControllerShow;
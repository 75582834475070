import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useInput, FieldTitle, InputHelperText, regex } from 'react-admin';
import sanitizeRestProps from './sanitizeRestProps';
import cronstrue from 'cronstrue/i18n';

const croneValidation = (value, allValues) => {
  if (!value) return undefined;
  try {
    cronstrue.toString(value);
  } catch (error) {
    return error.replace("Error: ", "");
  }
  return undefined;
};
const validateCrone = [croneValidation];

const CronInput = ({
  format,
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  resource,
  source,
  validate = validateCrone,
  variant = 'filled',
  inputProps,
  className,
  ...rest
}) => {

  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
} = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    resource,
    source,
    validate,
    ...rest,
});

let cronHumanize;
let cronError;

try {

  cronHumanize = cronstrue.toString(input.value, { 
    // throwExceptionOnParseError: false,
    use24HourTimeFormat :  true,
    verbose: true,
    locale: "ru",
  });

} catch (err) {

}


  return (
    <TextField
      id={id}
      {...input}
      className={className}
      variant={variant}
      error={!!(touched && (error || cronError))}
      helperText={
          (touched && (error || cronError)) || helperText || cronHumanize ? (
              <InputHelperText
                  touched={touched}
                  error={(error || cronError)}
                  helperText={helperText || cronHumanize}
              />
          ) : null
      }
      label={
          <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
          />
      }
      margin={margin}
      inputProps={inputProps}
      {...options}
      {...sanitizeRestProps(rest)}
  />
  
)};


export default CronInput;

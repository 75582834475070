import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { TITLE_APP } from '../../appsettings'
import { useGetOne, useQuery, GET_ONE, GET_MANY, Loading, Error } from 'react-admin';

import WidgetBanner from './WidgetBanner';



const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 16,
    },
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Title title={TITLE_APP} />
            <WidgetBanner />
        </div>

    );
};
import React from 'react';
import { Route } from 'react-router-dom';
import { WithPermissions } from 'ra-core';
import ProfileEdit from './components/Profile/ProfileEdit';
import DeviceConnect from './components/Device/DeviceConnect';
// import ControllerConnect from './components/Controller/ControllerConnect';
import GasMeterConnect from './components/GasMeter/GasMeterConnect';


export default [
    <Route path="/Profile" component={props => <ProfileEdit {...props} />} />,
    <Route
        path="/Device/:id/connect"
        render={routeProps => (
                <WithPermissions
                    component={DeviceConnect}
                    basePath="/Device"
                    resource="Device"
                    id={decodeURIComponent(routeProps.match.params.id)}
                    {...routeProps}
                />
            )
        }
    />,
    // <Route
    //     path="/Controller/:id/connect"
    //     render={routeProps => (
    //             <WithPermissions
    //                 component={ControllerConnect}
    //                 basePath="/Controller"
    //                 resource="Controller"
    //                 id={decodeURIComponent(routeProps.match.params.id)}
    //                 {...routeProps}
    //             />
    //         )
    //     }
    // />,
    <Route
        path="/GasMeter/:id/connect"
        render={routeProps => (
                <WithPermissions
                    component={GasMeterConnect}
                    basePath="/GasMeter"
                    resource="GasMeter"
                    id={decodeURIComponent(routeProps.match.params.id)}
                    {...routeProps}
                />
            )
        }
    />  
];
import russianMessages from 'ra-language-russian'
import raPreferencesLanguageRussian from './raPreferencesLanguageRussian'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    resources: {
        Device: {
            name: 'Устройство |||| Устройства',
            menu: 'Устройство |||| Устройства',
            fields: {
                id: 'ID',
                alias_contains: 'Псевдоним',
                name: 'Название',
                description: 'Описание',
                serialNumber: 'S/N, Серийный номер',
                sn: 'S/N',
                aisId: 'ID АИС',
                dbId: 'ID Шины',
                dbId_in: 'ID Шины (И)',
                url: 'URL адрес',
                ip: 'IP адрес',
                port: 'Порт',
                commercialHour: 'Коммерческий час',
                meta: 'meta',
                timeZone: 'Часовой пояс',
                mode: 'Режим работы',
                configuration: 'Конфигурация',
                gasMeterUnit: 'УУГ',
                controllerId: 'ID Контроллера',
                controller: 'Контроллер',
                controllerType: 'Тип Контроллера',
                gasMeterId: 'ID Корректора',
                gasMeter: 'Корректор',
                gasMeterType: 'Тип Корректора',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                status: 'Статус',
                deleted: 'Удален',
                device: 'Устройство',
                tags: 'Метки',
                gasMeterUnitReference: 'УУГ',
                value: 'Значение',
                ownershipControllers: 'Владение контроллерами',
                ownershipGasMeters: 'Владение корректорами',
                dateFrom: 'Дата с',
                dateTo: 'Дата по',
                dateFromOwnership: 'Дата начала владения',
                xmlArchiveLink: 'Ссылка на XML архив',
                uploadMods: 'Тип',
            },
            enums: {
                status: {
                    DRAFT: 'Черновик',
                    TEST: 'Тест',
                    ACTIVE: 'Активен',
                    SWAP: 'Заменен',
                },
                type: {
                    TELEOFIS: 'ТЕЛЕОФИС',
                    AXI1: 'АКСИ-1',
                    AXI2: 'АКСИ-2',
                    AXITEL: 'КАМ25А',
                    AXI1MR: 'АКСИ-1МР',
                    AXIS: 'АКСИС',
                    KAM200: 'КАМ-200',
                    KAM25: 'КАМ25',
                    KAM214: 'КАМ214',
                    FX: 'FX',
                    WS: 'WS',
                    BECKHOFF: 'Beckhoff',
                    AXITELJSONRPC: 'АКСИТЕЛ JSON-RPC',
                },
                mode: {
                    CACHE: 'Кэширование',
                    PROXY: 'Прозрачный канал',
                },
            },
            empty: 'Корректоры отсутствуют',
            invite: 'Вы хотите создать?',
        },
        GasMeter: {
            name: 'Корректор |||| Корректоры',
            menu: 'Корректор |||| Корректоры',
            fields: {
                id: 'ID',
                alias_contains: 'Псевдоним',
                name: 'Название',
                description: 'Описание',
                serialNumber: 'S/N, Серийный номер',
                sn: 'S/N',
                dbId: 'ID Шины',
                ip: 'IP адрес',
                port: 'Порт',
                commercialHour: 'Коммерческий час',
                dst: 'Переход на летнее время',
                meta: 'meta',
                timeZone: 'Часовой пояс',
                type: 'Тип',
                configuration: 'Конфигурация',
                gasMeterUnit: 'УУГ',
                controller: 'Контроллер',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                status: 'Статус',
                deleted: 'Удален',
                tags: 'Метки',
                gasMeter: 'Корректор',
                reason: 'Причина',
                dateFrom: 'С даты',
                dateTo: 'По дату',
                device: 'Устройство',
                gasMeterUnitReference: 'УУГ',
                xmlArchiveLink: 'Ссылка на XML архив',
            },
            enums: {
                status: {
                    DRAFT: 'Черновик',
                    TEST: 'Тест',
                    ACTIVE: 'Активен',
                    SWAP: 'Заменен',
                },
            },
            empty: 'Корректоры отсутствуют',
            invite: 'Вы хотите создать?',
        },
        GasMeterConfiguration: {
            name: 'Конфигурация корректора |||| Конфигурации корректоров',
            menu: 'Корректор |||| Корректоров',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                type: 'Тип',
                ns: 'Пространство имен',
                parameterSet: 'Параметры',
                functionalGroupSet: 'Группы',
                protocolSet: 'Протокол',
                schedulerSet: 'Расписание',
                converterSet: 'Конвертер',
                scadaSet: 'Скада',
                metaTemplate: 'Шаблон meta',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                status: 'Статус',
                deleted: 'Удален',
            },
            enums: {
                status: {
                    DRAFT: 'Черновик',
                    TEST: 'Тест',
                    RELEASE: 'Релиз',
                },
            },
            empty: 'Конфигурации корректоров отсутствуют',
            invite: 'Вы хотите создать?',
        },
        GasMeterType: {
            enums: {
                type: {
                    BK: 'БК',
                    EK260: 'EK-260',
                    EK270: 'EK-270',
                    EK280: 'EK-280',
                    EK290: 'EK-290',
                    ELSTERSERVICE: 'Сервис Elster',
                    IRVISRS4ULTRA: 'ИРВИС-РС4-УЛЬТРА',
                    IRVISRSK300: 'ИРВИС-K-300',
                    VKG2: 'ВКГ-2',
                    VKG3T: 'ВКГ-3Т',
                    VKG3D: 'ВКГ-3Д',
                    SPG741: 'СПГ-741',
                    SPG742: 'СПГ-742',
                    SPG761: 'СПГ-761',
                    GASDEVICESERVICE: 'Сервис Газдевайс',
                    GASDEVICEOMEGAEK: 'Газдевайс ОМЕГА ЭК',
                    GIPERFLOW3PM: 'ГиперФлоу-ЗПм',
                    GIPERFLOWUS: 'ГиперФлоу-УС',
                    GRANDSPI: 'Гранд SPI',
                    TECHNOMER: 'Техномер',
                    TECHNOMERSERVICE: 'Сервис Техномер',
                    TURBODONSERVICE: 'Сервис Турбулентность-ДОН',
                    SUPERFLOW21V: 'СуперФлоу-21В',
                    SUPERFLOW2E: 'СуперФлоу-2Е',
                    SUPERFLOW2ET: 'СуперФлоу-2ЕТ',
                    FLOBOSS: 'FloBoss',
                    FLOWGAS: 'ФЛОУГАЗ',
                    TC220: 'ТС220',
                    TURBOFLOWUFG: 'Turbo Flow UFG',
                    TURBOFLOWGFG: 'Turbo Flow GFG',
                    TURBOFLOWTFG: 'Turbo Flow TFG',
                    TELEFLOWEGM3530: 'TeleFlow EGM 3530',
                    FLOWSIC600: 'FLOWSIC600',
                    ROSTELECOM: 'Ростелеком',
                    SGT16E: 'СГТ16Э',
                    VYMPEL500: 'Вымпел-500',
                    JSONRPC: 'JSON-RPC',
                    ERZ2000DI: 'ERZ 2000-DI',
                },
            }
        },
        Controller: {
            name: 'Контроллер |||| Контроллеры',
            menu: 'Контроллер |||| Контроллеры',
            fields: {
                id: 'ID',
                alias_contains: 'Псевдоним',
                name: 'Название',
                description: 'Описание',
                serialNumber: 'S/N, Серийный номер',
                sn: 'S/N',
                phoneNumber: 'Номер телефона',
                ip: 'IP адрес',
                url: 'URL адрес',
                type: 'Тип',
                configuration: 'Конфигурация',
                device: 'Устройства',
                gasMeterUnits: 'УУГ',
                gasMeters: 'Корректоры',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                status: 'Статус',
                deleted: 'Удален',
                tags: 'Метки',
                controller: 'Контроллер',
                reason: 'Причина',
                dateFrom: 'С даты',
                dateTo: 'По дату',
            },
            enums: {
                status: {
                    DRAFT: 'Черновик',
                    TEST: 'Тест',
                    ACTIVE: 'Активен',
                    SWAP: 'Заменен',
                },
            },
            empty: 'Контроллеры отсутствуют',
            invite: 'Вы хотите создать?',
        },
        ControllerConfiguration: {
            name: 'Конфигурация контроллера |||| Конфигурации контроллеров',
            menu: 'Контроллер |||| Контроллеров',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                type: 'Тип',
                mode: 'Режим работы',
                ns: 'Пространство имен',
                parameterSet: 'Параметры',
                functionalGroupSet: 'Группы',
                protocolSet: 'Протокол',
                schedulerSet: 'Расписание',
                converterSet: 'Конвертер',
                scadaSet: 'Скада',
                metaTemplate: 'Шаблон meta',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                status: 'Статус',
                deleted: 'Удален',
            },
            enums: {
                status: {
                    DRAFT: 'Черновик',
                    TEST: 'Тест',
                    RELEASE: 'Релиз',
                },
                mode: {
                    CACHE: 'Кэширование',
                    PROXY: 'Прозрачный канал',
                },
            },
            empty: 'Конфигурации контроллеров отсутствуют',
            invite: 'Вы хотите создать?',
        },
        ControllerType: {
            enums: {
                type: {
                    TELEOFIS: 'ТЕЛЕОФИС',
                    AXI1: 'АКСИ-1',
                    AXI2: 'АКСИ-2',
                    AXITEL: 'КАМ25А',
                    AXI1MR: 'АКСИ-1МР',
                    AXIS: 'АКСИС',
                    KAM200: 'КАМ-200',
                    KAM25: 'КАМ25',
                    KAM214: 'КАМ214',
                    FX: 'FX',
                    WS: 'WS',
                    BECKHOFF: 'Beckhoff',
                    AXITELJSONRPC: 'АКСИТЕЛ JSON-RPC',
                },
            }
        },
        GasMeterUnit: {
            fields: {
                id: 'ID',
                name: 'Название',
            },
        },
        Tag: {
            name: 'Метка |||| Метки',
            menu: 'Метка |||| Метки',
            fields: {
                id: 'ID',
                name: 'Название',
                description: 'Описание',
                type: 'Тип',
                resource: 'Ресурс',
                color: 'Цвет',
                createdAt: 'Создана',
                createdBy: 'Создал',
                updatedAt: 'Обновлена',
                tags_some: 'Метка',
            },
            enums: {
                type: {
                    USER: 'Пользовательская',
                    SYSTEM: 'Системная',
                },
                resource: {
                    GasMeter: 'Корректор',
                    Controller: 'Контроллер',

                },
            },
            empty: 'Метки отсутствуют',
            invite: 'Вы хотите создать?',
        },
        TrashItem: {
            name: 'Корзина',
            menu: 'Корзина',
            fields: {
                id: 'ID',
                resource: 'Ресурс',
                name: 'Название',
                description: 'Описание',
                deletedBy: 'Удалил',
                deletedAt: 'Удален',
                deleted: 'Удален',
            },
            enums: {
                resource: {
                    Controller: 'Контроллер',
                    ControllerConfiguration: 'Конфигурация контроллера',
                    GasMeter: 'Корректор',
                    GasMeterConfiguration: 'Конфигурация корректора',
                    User: 'Пользователь',
                },
            }
        },
        History: {
            name: 'История',
            menu: 'История',
            fields: {
                id: 'ID',
                resource: 'Ресурс',
                name: 'Название',
                action: 'Действие',
                description: 'Описание',
                'data.data': 'Текущее состояние',
                'data.previous': 'Предыдущее состояние',
                createdAt: 'Дата',
                createdBy: 'Пользователь',
                deleted: 'Удален',
            },
            enums: {
                action: {
                    CREATED: 'Создан',
                    UPDATED: 'Обновлен',
                    DELETED: 'Удален навсегда',
                    REMOVED: 'Удален',
                    RESTORED: 'Восстановлен',
                    IMPORTED: 'Импортирован',
                    SWAPPED: 'Заменен',
                    UNINSTALLED: 'Демонтирован',
                },
                resource: {
                    Controller: 'Контроллер',
                    ControllerConfiguration: 'Конфигурация контроллера',
                    GasMeter: 'Корректор',
                    GasMeterConfiguration: 'Конфигурация корректора',
                    User: 'Пользователь',
                    Device: 'Устройство',
                    GasMeterUnit: 'УУГ',
                },
            },
        },
        User: {
            name: 'Пользователь |||| Пользователи',
            menu: 'Пользователь |||| Пользователи',
            fields: {
                id: 'ID',
                name: 'Наименование',
                description: 'Описание',
                createdAt: 'Создан',
                createdBy: 'Создал',
                updatedAt: 'Обновлен',
                email: 'Email',
                phone: 'Телефон',
                phoneContact: 'Телефон для связи',
                role: 'Роль',
                login: 'Логин',
                regions: 'Регионы',
                password: 'Пароль',
                deleted: 'Удален',
                online: 'Онлайн',
                banned: 'Заблокирован',
                position: "Должность",
            },
            enums: {
                role: {
                    GUEST: 'Гость',
                    SERVICE: 'Сервис',
                    SUPERADMIN: 'Суперадмин',
                    ADMIN: 'Администратор',
                    ENGINEER: 'Инженер',
                    CONSUMER: 'Потребитель',
                    DISPATCHER: 'Диспетчер'
                },
            },
            empty: 'Пользователи отсутствуют',
            invite: 'Вы хотите создать?',
        }
    },
    connect: {
        fields: {
            fromAt: 'С даты',
            toAt: 'По дату',
        },
    },
    menu: {
        groups: {
            default: "",
            equipment: "Оборудование",
            configurations: "Конфигурации",
            access: "Доступ",
            directories: "Справочники",
            utils: "Утилиты",
        }
    },
    ra: {
        action: {
            ...russianMessages.ra.action,
            unselect: "снять выделение",
            show_blank: "Просмотр в новом окне",
            bulk_actions: "%{smart_count} выбрано |||| %{smart_count} выбрано |||| %{smart_count} выбрано",
            create_and_continue: "Coздать и продолжить",
            save_and_continue: "Сохранить и продолжить",
            show_history: "Показать историю",
            delete: "Удалить навсегда",
            restore: "Восстановить",
            check_all: "Выбрать все",
            trash_open: "Корзина",
            more: "Дополнительно",
            connect: "Подключиться",
            swap: "Заменить",
            ban: "Заблокировать",
            unban: "Разблокировать",
            one_of_fields_is_required: "Требуется хотя бы одно из полей: Логин, Телефон или Email",
            apply_filter: "Применить фильтр",
            tcp_connect: "Проверить TCP"
        },
        boolean: {
            ...russianMessages.ra.boolean,
        },
        page: {
            ...russianMessages.ra.page,
            connect: "%{name} #%{id}",
            removed: "Удалено",
        },
        input: {
            ...russianMessages.ra.input,
            password: {
                toggle_visible: 'Показать',
                toggle_hidden: 'Скрыть',
            },
        },
        message: {
            ...russianMessages.ra.message,
            copy: "Копия от %{from}",
            removed: "Ресурс перемещен в Корзину",
        },
        navigation: {
            ...russianMessages.ra.navigation,
            user_apps: "Приложения",
        },
        auth: {
            ...russianMessages.ra.auth,
            permissions_error: 'Нет доступа',
            access_error: 'Нет доступа',
            User_is_not_access: 'Пользователь не имеет прав доступа. Обратитесь к администратору',

            role_SERVICE_not_allowed: 'Роль пользователя "Сервис" не подходит',
            ['User with such login does not exist.']: 'Пользователь с таким логином не существует',
            ['User with such login and password does not exist.']: 'Пользователь с таким логином и паролем не существует',
            ['User with such login banned.']: 'Пользователь с таким логином заблокирован. Обратитесь к администратору',
            ['Аuthorization error!']: 'Ошибка авторизации!',
            'Not authenticated': 'Ошибка аутентификации!',
            not_authorized_user: 'Пользователь с таким логином и паролем не существует. Осталась последняя попытка |||| Пользователь с таким логином и паролем не существует. Осталось %{smart_count} попытки |||| Пользователь с таким логином и паролем не существует.  Осталось %{smart_count} попыток',
            banned_user: 'Пользователь с таким логином заблокирован. Обратитесь к администратору',
        },
        notification: {
            ...russianMessages.ra.notification,
            configuration_not_found: 'Kонфигурация не найдена',
            profile_saved: 'Профиль сохранен',
            value_is_written: 'Значение записано',
            moved_to_trash: '1 элемент перемещен в корзину |||| %{smart_count} элемента перемещено в корзину |||| %{smart_count} элементов перемещено в корзину',
            restored_from_trash: '1 элемент восстановлен из корзины |||| %{smart_count} элемента восстановлено из корзины |||| %{smart_count} элементов восстановлено из корзины',
            deleted_from_trash: '1 элемент удален из корзины |||| %{smart_count} элементов(а) удалено из корзины |||| %{smart_count} элементов(а) удалено из корзины',
            parameter_not_defined: 'Параметр не определен (%{id})',
            swap: '%{resource} заменен',
            relation_error: "Удаление невозможно. Ограничение отношений между ресурсами, проверьте консоль",
            delete_error: "Ошибка удаления, проверьте консоль",
        },
        validation: {
            ...russianMessages.ra.validation,
            ip_invalid: 'Должен быть в формате 255.255.255.255',
            phone_invalid: 'Должен быть в формате +7 (999) 999-9999',
            password_invalid: 'Пароль должен содержать: \n - минимум 8 латинских символов \n - минимум одну заглавную букву \n - минимум одну строчную букву \n - минимум одну цифру \n - минимум один спецсимвол \n',
            date_invalid: 'Недопустимая дата',
        },
        uploadXML: {
            title: "Выгрузка XML архива |||| Выгрузка XML архивов",
            content: "Вы хотите выгрузить XML архив этого устройста в папку \archivecor за заданный период? |||| Вы хотите выгрузить XML архивы выбранных устройств в папку \archivecor за заданный период?",
            upload: "Выгрузить XML",
            uploaded: "XML архив выгружен |||| %{smart_count} XML архива выгружено |||| %{smart_count} XML архивов выгружено"
        },
        uninstallGasMeterUnit: {
            title: "Демонтаж Устройства |||| Демонтаж Устройств",
            content: 'Это действие приведет к удалению связей между Устройством и ресурсами АСКУГ, добавлению в УУГ системной Метки "Демонтирован", созданию соответствующей записи в журнале владения УУГ',
            question: 'Вы действительно хотите демонтировать Устройство? |||| Вы действительно хотите демонтировать Устройства?',
            uninstall: "Демонтировать",
            uninstalled: "Устройство демонтировано |||| %{smart_count} Устройства демонтировано |||| %{smart_count} Устройств демонтировано"
        },
        swapGasMeter: {
            title: "Замена Корректора |||| Замена Корректоров",
            swap: "Заменить",
            swaped: "Корректор заменен |||| %{smart_count} Корректора заменено |||| %{smart_count} Корректоров заменено",
            fields: {
                gasMeter: 'Корректор',
                reason: 'Причина',
                dateFrom: 'Дата замены',
            }
        },
        swapController: {
            title: "Замена Контроллера |||| Замена Контроллеров",
            swap: "Заменить",
            swaped: "Контроллер заменен |||| %{smart_count} Контроллера заменено |||| %{smart_count} Контроллеров заменено",
            fields: {
                сontroller: 'Контроллер',
                reason: 'Причина',
                dateFrom: 'Дата замены',
            }
        }
    },
    ...raPreferencesLanguageRussian,
};
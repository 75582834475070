import { AutocompleteArrayInput, BooleanInput, NumberInput, ReferenceArrayInput, SaveButton, SimpleForm, TextInput, Toolbar, required } from 'react-admin';
import CardBlankLayout from '../Layout/CardBlankLayout';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import EnumInput from '../Inputs/EnumInput';
import GasMeterBanner from './GasMeterBanner';
import JsonInput from '../Inputs/JsonInput';
import JsonVariableInput from '../Inputs/JsonVariableInput';
import JsonVariableInputs from '../Inputs/JsonVariableInputs';
import React from 'react';
import ReferenceConfigurationInput from './ReferenceConfigurationInput';
// import ReferenceControllerInput from '../Controller/ReferenceControllerInput';
import ReferenceGasMeterUnitInput from './ReferenceGasMeterUnitInput';
import RemoveButton from '../Buttons/RemoveButton';
import TitleForRecord from '../Layout/TitleForRecord';
import TypeInput from './TypeInput';
import { ability } from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
}));

const GasMeterToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {ability.can("update", "GasMeter") && <SaveButton />}
            {ability.can("update", "GasMeter") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {ability.can("delete", "GasMeter") && <RemoveButton redirect="list" />}
        </Toolbar>
    );
};

const GasMeterEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasMeterBanner />
            <CardBlankLayout >
                {/*  либо так, но в форму нельзя установить значение null, либо у JsonInput`ов всегада при заменен ключей, старые ключи будут null */}
                <SimpleForm toolbar={<GasMeterToolbar />} >
                    {ability.can('update', 'GasMeter', 'status') && <EnumInput source="status" Enum="GasMeterStatus" />}
                    {ability.can('update', 'GasMeter', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                    {ability.can('update', 'GasMeter', 'description') && <TextInput source="description" multiline fullWidth />}
                    {ability.can('update', 'GasMeter', 'gasMeterUnit') && <ReferenceGasMeterUnitInput source="gasMeterUnit.id" reference="GasMeterUnit" filter={{ gasMeter: null }} label="resources.GasMeter.fields.gasMeterUnit" allowEmpty emptyText="ra.message.no" />}
                    {ability.can('update', 'GasMeter', 'type') && <TypeInput source="type" Enum="GasMeterType" option="resources.GasMeterType.enums.type" validate={required()} />}
                    {ability.can('update', 'GasMeter', 'configuration') && <ReferenceConfigurationInput source="configuration.id" reference="GasMeterConfiguration" label="resources.GasMeter.fields.configuration" allowEmpty emptyText="ra.message.no" />}
                    {/* Убрал из-за путаницы с созданием Устройства. Теперь связь назначается только в Устройстве */}
                    {/* {ability.can('update', 'GasMeter', 'controller') && <ReferenceControllerInput source="controller.id" reference="Controller" label="resources.GasMeter.fields.controller" allowEmpty emptyText="ra.message.no"/>} */}
                    {ability.can('update', 'GasMeter', 'dbId') && <NumberInput source="dbId" />}
                    {ability.can('update', 'GasMeter', 'serialNumber') && <TextInput source="serialNumber" />}
                    {ability.can('update', 'GasMeter', 'port') && <NumberInput source="port" />}
                    {ability.can('update', 'GasMeter', 'timeZone') && <NumberInput source="timeZone" step={1} />}
                    {ability.can('update', 'GasMeter', 'commercialHour') && <NumberInput source="commercialHour" step={1} />}
                    {ability.can('update', 'GasMeter', 'dst') && <BooleanInput source="dst" fullWidth />}
                    {ability.can('update', 'GasMeter', 'metaVariable') && <JsonVariableInputs source="meta.metaVariable">
                        <JsonVariableInput />
                    </JsonVariableInputs>}
                    {ability.can('update', 'GasMeter', 'meta') && <JsonInput source="meta" fullWidth />}
                    {ability.can('update', 'GasMeter', 'tags') && <ReferenceArrayInput source="tagsIds" filter={{ resource: "GasMeter" }} reference="Tag" label="resources.GasMeter.fields.tags" fullWidth>
                        <AutocompleteArrayInput />
                    </ReferenceArrayInput>}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default GasMeterEdit
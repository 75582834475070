import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { useShowController, TextField, DateField } from 'react-admin';
import { Show } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import EnumField from '../Fields/EnumField';
import JsonField from '../Fields/JsonField';
import LinkField from './LinkField';





const HistoryShow = props => {
    const showMoreButton = React.useRef(null);

    return (
        <Show title={<TitleForRecord source="name" {...props} />} {...props} >
            <CardShowLayout>
                <EnumField source="action" noWrap />
                <EnumField source="resource" />
                <LinkField source="name" />
                <TextField source="description" />
                <JsonField source="data.data" />
                <JsonField source="data.previous" />
            </CardShowLayout>

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    <TextField source="id" />
                    <DateField source="createdAt" />
                    <TextField source="createdBy.name" label="resources.History.fields.createdBy" />
                </CardShowLayout>
            </MoreContent>

        </Show>
    );
};

export default HistoryShow;
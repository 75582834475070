import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';

const USER_TEMPLATE = `{
    id
    name
    description
    createdAt
    updatedAt
    deleted
    authId
    banned
    email
    phone
    role
    online
    counterparty {
        id 
        accessResource
    }
}`

export const GET_ONE_USER = gql`
    query User( $where: UserWhereUniqueInput! ) {
        data: User( where: $where ) ${USER_TEMPLATE}
    }
`

export default (args) => {
    return apolloCoreClient.query({
        query: GET_ONE_USER,
        variables: { ...args },
        fetchPolicy: 'network-only',
        onError: (error) => {
            alert(error)
        }
    });
};

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ConnectGroupTreeLeaf from './ConnectGroupTreeLeaf';


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginBottom: 4,
    },
    tree: {},
    expandIcon: {
        color: "#fbc02d"
    },
    item: {},
    content: {},
    group: {},
    expanded: {},
    selected: {},
    label: {
        padding: 4,
        display: 'flex',
        alignItems: 'center',
    },
}));




const ConnectGroupTreeNode = ({node, ...props}) => {
    const classes = useStyles();

    const id = node.id.toString();
    const name = node.name;
    const nodes = node.nodes;
    const leafs = node.leafs;

    return (
        (id && name) ? <TreeItem
            key={id}
            nodeId={id}
            label={<Typography variant="body2" className={classes.labelName}>{name}</Typography>}
            classes={{
                root: classes.item,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
        >
            {Array.isArray(nodes) ? nodes.map((node) => <ConnectGroupTreeNode node={node} key={node.id} />) : null}
            {Array.isArray(leafs) ? leafs.map((node) => <ConnectGroupTreeLeaf node={node} key={node.id} />) : null}
        </TreeItem> : null
    )

}

ConnectGroupTreeNode.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
        name: PropTypes.string.isRequired,
        nodes: PropTypes.array,
        leafs: PropTypes.array,
    }),
};

export default React.memo(ConnectGroupTreeNode);


import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAccessToken } from '../../authPrivider/getAccessToken';
import { URI_EXPORTXML } from '../../appsettings';


const httpLink = createHttpLink({
  uri: URI_EXPORTXML
})

const xmlLink = setContext(async (_, { headers }) => {              
  const token = await getAccessToken(); 

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

export default new ApolloClient({
  link: xmlLink.concat(httpLink),
  cache: new InMemoryCache(),
});

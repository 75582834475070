import React, { useState, cloneElement } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import CardHeader from '@material-ui/core/CardHeader';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslate } from 'react-admin';
import { useFormState } from "react-final-form";
import { useSelector, shallowEqual } from 'react-redux';
import { get } from 'lodash';


const useStyles = makeStyles({
    button: {
        verticalAlign: "bottom",
        position: "relative",
        marginTop: 8,
        marginBottom: 4,
    },
    paper: {
        minWidth: "16rem",
    },
    mlAuto: { marginLeft: "auto" },
    drawerForm: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    drawerToolbar: {
        marginTop: "auto",
    },
});

const QuickShowButton = ({ resource, source, reference, basePath, children, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const { values } = useFormState();
    const id = get(values, source)

    const [open, setOpen] = useState(false);

    const record = useSelector(state => state.admin.resources[reference] ? state.admin.resources[reference].data[id] : null, shallowEqual);
    const version = useSelector(state => state.admin.ui.viewVersion, shallowEqual);
    useSelector(state => state.admin.loading > 0);


    if (!id) return null

    const handleClick = (event) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleCloseClick = (event) => {
        event.preventDefault();
        setOpen(false);
    };




    return (
        <>
            <Tooltip title={translate("ra.action.show")} enterDelay={700}>
                <IconButton onClick={handleClick} aria-label="QuickShowButton" className={classes.button}>
                    <ImageEye />
                </IconButton>
            </Tooltip>
            <Drawer anchor="right" open={open} onClose={handleCloseClick} classes={{ paper: classes.paper }}>
                <CardHeader
                    // title={defaultTitle}
                    action={
                        <IconButton onClick={handleCloseClick} aria-label="Close" size="small">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                />
                {cloneElement(children, {
                    basePath: basePath.replace(resource, reference),
                    resource: reference,
                    record,
                    version,
                })}
            </Drawer>
        </>
    );

}

export default QuickShowButton;
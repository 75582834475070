import { ResourceContextProvider, ListContextProvider, Datagrid, DateField, TextField, useTranslate } from 'react-admin';

import CardContent from '@material-ui/core/CardContent';
import EnumField from '../Fields/EnumField';
import React from 'react';
import StatusChip from "../Controller/ControllerStatusChip";
import Typography from '@material-ui/core/Typography';
import { dateTimeOptions } from '../../utils';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const OwnershipControllersDatagrid = ({ basePath, resource, source, record, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    if (!record) return null;
    const ownership = get(record, source);

    if (ownership.length === 0) {
        return (
            <CardContent>
                <Typography variant="body2">
                    {translate('ra.navigation.no_results')}
                </Typography>
            </CardContent>
        )
    }

    const data = ownership.map(item => ({
        id: item.id,
        controllerId: item.controller.id,
        name: item.controller.name,
        description: item.controller.description,
        status: item.controller.status,
        type: item.controller.type,
        dateFrom: item.dateFrom,
        dateTo: item.dateTo,
        reason: item.reason,
        createdAt: item.createdAt,
        parent: item.parent,
    }))

    const controllerRowClick = (id, basePath, record) => `${basePath}/${record.controllerId}/show`;

    return (
        <ResourceContextProvider value={'Controller'}>
            <ListContextProvider
                value={{
                    resource: 'Controller',
                    basePath: '/Controller',
                    data: keyBy(data, 'id'),
                    ids: data.map(({ id }) => id),
                    total: data.length,
                    currentSort: { field: 'name', order: 'ASC' },
                    selectedIds: [],
                }}
            >
                <Datagrid rowClick={controllerRowClick} classes={{ row: classes.row }} >
                    <StatusChip cellClassName={classes.cellMinWidth} sortable={false} />
                    <TextField source="name" sortable={false} />
                    <TextField source="description" sortable={false} />
                    <EnumField source="type" option="resources.ControllerType.enums.type" sortable={false} />
                    <TextField source="reason" sortable={false} />
                    <DateField source="dateFrom" textAlign="right" options={dateTimeOptions} headerClassName={classes.noWrap} sortable={false} />
                    <DateField source="dateTo" textAlign="right" options={dateTimeOptions} headerClassName={classes.noWrap} sortable={false} />
                    <DateField source="createdAt" textAlign="right" options={dateTimeOptions} headerClassName={classes.noWrap} sortable={false} />
                </Datagrid>
            </ListContextProvider>
        </ResourceContextProvider>
    );
};

export default OwnershipControllersDatagrid;

import { DateField, EditButton, Pagination, ReferenceField, ReferenceManyField, TextField, TopToolbar, ShowContextProvider, UrlField, useShowController } from 'react-admin';
import Alert from '../Fields/Alert';
import CardBlankLayout from '../Layout/CardBlankLayout';
import CardShowLayout from '../Layout/CardShowLayout';
import ConnectButton from '../Buttons/ConnectButton';
import UploadXMLButton from '../Buttons/UploadXMLButton';
import UninstallGasMeterUnitButton from '../Buttons/UninstallGasMeterUnitButton';
import DeviceBanner from './DeviceBanner';
import EnumField from '../Fields/EnumField';
import JsonField from '../Fields/JsonField';
import JsonVariableField from '../Fields/JsonVariableField';
import JsonVariableFields from '../Fields/JsonVariableFields';
import MoreContent from '../Layout/MoreContent';
import OwnershipControllersDatagrid from "./OwnershipControllersDatagrid";
import OwnershipGasMetersDatagrid from "./OwnershipGasMetersDatagrid";
import React from 'react';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import Separator from '../Layout/Separator';
import { ShowView } from '../Layout/ShowView';
import TitleForRecord from '../Layout/TitleForRecord';
import { ability } from "../../abilityProvider";
import useGasMeterUnitOwnerships from '../../dataProvider/Core/useGasMeterUnitOwnerships';

// import SwapButton from './DeviceSwapButton';




const DeviceShowActions = ({ basePath, className, data, hasEdit, hasList, resource, ...props }) => {

    // const isActive = data && data.status === "ACTIVE"

    return (
        <TopToolbar {...props}>
            {/*{ability.can('create', 'Device') && <CloneButton resource={resource} basePath={basePath} record={data} omit={['id', 'dbId', 'status', 'controller' , 'controller.id', 'gasMeterUnit', 'gasMeterUnit.id']}/>}*/}
            {/*{ability.can('swap', 'Device') && isActive && <SwapButton resource={resource} basePath={basePath} record={data} />}*/}
            {ability.can('update', 'Device') && <UninstallGasMeterUnitButton resource={resource} basePath={basePath} record={data} />}
            {ability.can('update', 'Device') && <UploadXMLButton resource={resource} basePath={basePath} record={data} />}
            {ability.can('connect', 'Device') && <ConnectButton resource={resource} basePath={basePath} record={data} />}
            {ability.can('update', 'Device') && <EditButton resource={resource} basePath={basePath} record={data} />}
        </TopToolbar>
    )
};

const DeviceShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);
    const configurationStatus = controllerProps.record && controllerProps.record.configuration && controllerProps.record.configuration.status;
    const ownership = useGasMeterUnitOwnerships(controllerProps.record && controllerProps.record.id)

    return (
        <ShowContextProvider value={controllerProps}>
            <ShowView
                actions={<DeviceShowActions />}
                title={<TitleForRecord source="name" {...props} />}
                {...props}
                {...controllerProps}
            >
                <DeviceBanner />

                <CardShowLayout>
                    {ability.can('getOne', 'Device', 'type') && <EnumField source="type" />}
                    {ability.can('getOne', 'Device', 'mode') && <EnumField source="mode" />}
                    {/*{ability.can('getOne', 'Device', 'configuration') && <NestedField source="configuration" reference="DeviceConfiguration" >*/}
                    {/*    <TextField source="name" />*/}
                    {/*</NestedField>}*/}
                    {(configurationStatus && configurationStatus !== "RELEASE") && <Alert severity="warning">Внимание. Установленная конфигурация еще не выпущена в Релиз!</Alert>}
                    {ability.can('getOne', 'Device', 'gasMeterUnit') && <ReferenceField source="id" reference="GasMeterUnit" link={false} label="resources.Device.fields.gasMeterUnit" >
                        <TextField source="name" />
                    </ReferenceField>}
                    {ability.can('getOne', 'Device', 'controller') && <ReferenceField source="controllerId" reference="Controller" link="show" label="resources.Device.fields.controller" >
                        <TextField source="name" />
                    </ReferenceField>}
                    {ability.can('getOne', 'Device', 'gasMeter') && <ReferenceField source="gasMeterId" reference="GasMeter" link="show" label="resources.Device.fields.gasMeter" >
                        <TextField source="name" />
                    </ReferenceField>}
                    {ability.can('getOne', 'Device', 'aisId') && <TextField source="aisId" />}
                    {ability.can('getOne', 'Device', 'dbId') && <TextField source="dbId" />}
                    {ability.can('getOne', 'Device', 'xmlArchiveLink') && <UrlField source="xmlArchiveLink" />}
                    {ability.can('getOne', 'Device', 'serialNumber') && <TextField source="serialNumber" />}
                    {ability.can('getOne', 'Device', 'port') && <TextField source="url" />}
                    <JsonVariableFields source="meta.metaVariable" >
                        <JsonVariableField />
                    </JsonVariableFields>
                    {ability.can('getOne', 'Device', 'meta') && <JsonField source="meta" fullWidth />}
                </CardShowLayout>

                <CardBlankLayout label="resources.Device.fields.ownershipControllers">
                    <OwnershipControllersDatagrid record={ownership.data} source="ownershipControllers" />
                </CardBlankLayout>

                <CardBlankLayout label="resources.Device.fields.ownershipGasMeters">
                    <OwnershipGasMetersDatagrid record={ownership.data} source="ownershipGasMeters" />
                </CardBlankLayout>


                <Separator action={(<div ref={showMoreButton} ></div>)} />

                <MoreContent showMoreButton={showMoreButton}>
                    <CardShowLayout>
                        {ability.can('getOne', 'Device', 'id') && <TextField source="id" />}
                        {/*{ability.can('getOne', 'Device', 'dbId') && <TextField source="dbId" />}*/}
                        {/*{ability.can('getOne', 'Device', 'createdAt') && <DateField source="createdAt" />}*/}
                        {/*{ability.can('getOne', 'Device', 'createdBy') && <TextField source="createdBy.name" label="resources.Device.fields.createdBy" />}*/}
                        {ability.can('getOne', 'Device', 'updatedAt') && <DateField source="updatedAt" />}
                    </CardShowLayout>
                </MoreContent>

                <Separator action={(<div ref={showHistoryButton} ></div>)} />

                <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                    <CardBlankLayout>
                        <ReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} filter={{ resource_in: ['Device', 'GasMeterUnit', 'Controller', 'GasMeter'] }} perPage={10} pagination={<Pagination />} >
                            <ReferenceHistoryDatagrid source="Device" />
                        </ReferenceManyField>
                    </CardBlankLayout>
                </MoreContent>
            </ShowView>
        </ShowContextProvider>
    );
};

export default DeviceShow;
import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';


export const UNINSTALL_GASMETERUNIT = gql`
    mutation uninstallGasMeterUnit( $where: GasMeterUnitWhereUniqueInput! $input: GasMeterUnitUninstallinput) {
        data: uninstallGasMeterUnit( where: $where, input: $input  ) { id }
    }
`

export default ({ where, input }) => {
    return apolloCoreClient.mutate({
        mutation: UNINSTALL_GASMETERUNIT,
        variables: { where, input },
        onError: (error) => {
            alert(error)
        }
    });
};
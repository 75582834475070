import Icon from '../Icons/Corrector';
import GasMeterList from './GasMeterList';
import GasMeterCreate from './GasMeterCreate';
import GasMeterEdit from './GasMeterEdit';
import GasMeterShow from './GasMeterShow';

const GasMeterIcon = Icon;


export {
    GasMeterIcon,
    GasMeterList,
    GasMeterShow,
    GasMeterCreate,
    GasMeterEdit,
};


import React from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import sanitizeRestProps from './sanitizeRestProps';


const useStyles = makeStyles(
    theme => ({
        label: {
            position: 'relative',
        },
        value: {
            fontFamily: theme.typography.fontFamily,
            color: 'currentColor',
            padding: `${theme.spacing(1)}px 0 ${theme.spacing(1) / 2}px`,
            border: 0,
            boxSizing: 'content-box',
            verticalAlign: 'middle',
            background: 'none',
            margin: 0, // Reset for Safari
            display: 'block',
            width: '100%',
        },
    }),
    { name: 'JsonVariable' }
);

const JsonVariableField = ({ id, className, resource, source, record = {}, margin = 'dense', ...props }) => {
    const classes = useStyles();
    const label = get(record, `${source}.name`);
    const value = get(record, `${source}.value`);
    
    return label !== undefined && value !== undefined ? (
        <FormControl
            className={className}
            margin={margin}
        >
            <InputLabel htmlFor={id} shrink className={classes.label}>{label}</InputLabel>
            <div className={classes.value}>
                <Typography
                    component="span"
                    variant="body2"
                    {...sanitizeRestProps(props)}
                >
                    {value}
                </Typography>
            </div>
        </FormControl>
    ) : null
};

JsonVariableField.defaultProps = {
    addLabel: true,
};

export default JsonVariableField;
import React from 'react';
import { ability, abilitySubjectConditions as asc, rulesToFields } from '../../abilityProvider';
import { makeStyles } from '@material-ui/core/styles';
import { useListController, ListView, DateField, Datagrid, TextField, Filter, NullableBooleanInput, BulkExportButton, useTranslate, ListContext } from 'react-admin';
import SearchInput from '../Inputs/SearchInput';
import EnumField from '../Fields/EnumField';
import PhoneField from '../Fields/PhoneField';
import OnlineBadge from '../Fields/OnlineBadge';
import AvatarField from '../Fields/AvatarField';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import EnumInput from '../Inputs/EnumInput';
import customExporter, { dateFormatter, enumFormatter } from '../../exporter';
import MoreButton from "../Buttons/MoreButton";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";



const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const UserBulkActionButtons = props => (
    <>
        {ability.can('remove', 'User') && <BulkRemoveButton {...props} />}
        {ability.can('getList', 'User') && <BulkExportButton {...props} />}
    </>
);

const filterRoles = (value) => {
    const { role_in, role } = rulesToFields("getList", "User");
    return role_in ? role_in.includes(value.name) : true;
}

const UserFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn resettable />
        <EnumInput source="role" Enum="UserRole" filter={filterRoles} emptyText="ra.message.no" />
        <NullableBooleanInput source="banned" style={{ width: 150 }} />
    </Filter>
);

export const DefaultDatagrid = (props) => {
    const classes = useStyles();

    return (
        <Datagrid {...props} rowClick="show" classes={{ row: classes.row }}>
            <OnlineBadge>
                <AvatarField name="name" />
            </OnlineBadge>
            <TextField source="name" />
            <TextField source="description" />
            {ability.can('getList', 'User', 'role') && <EnumField source="role" />}
            {ability.can('getList', 'User', 'login') && <TextField source="login" />}
            {ability.can('getList', 'User', 'phone') && <PhoneField source="phone" />}
            {ability.can('getList', 'User', 'email') && <TextField source="email" />}
            <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
            <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
            <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                {ability.can('getOne', 'User') && <ShowBlankItem />}
                {ability.can('update', 'User') && <EditMenuItem />}
                {ability.can('remove', 'User') && <RemoveMenuItem />}
            </MoreButton>
        </Datagrid>
    );
}

const modelExport = {
    id: true,
    name: true,
    description: true,
    createdBy: {
        id: true,
        name: true,
        __resource: "User",
    },
    createdAt: dateFormatter,
    updatedAt: dateFormatter,
    email: true,
    login: true,
    phone: true,
    phoneContact: true,
    position: true,
    role: enumFormatter,
    // counterparty: {
    //     id: true,
    //     name: true,
    //     __resource: "Counterparty",
    // },
    __resource: "User",
};

const UserList = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const controllerProps = useListController(props);
    const exporter = customExporter(controllerProps.defaultTitle, modelExport, { translate })

    return (
        <ListContext.Provider value={{ ...controllerProps, exporter }}>
            <ListView
                {...props}
                {...controllerProps}
                bulkActionButtons={<UserBulkActionButtons />}
                filters={<UserFilter />}
            >
                <DefaultDatagrid />
            </ListView>
        </ListContext.Provider>
    )
};

UserList.defaultProps = {
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};


export default UserList;
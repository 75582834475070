import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3 5c0-1.1.9-2 2-2h14a2 2 0 012 2v12a2 2 0 01-2 2v2h-2v-2h-2v2h-2v-2h-2v2H9v-2H7v2H5v-2a2 2 0 01-2-2V5zm2 1a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-1 1H6a1 1 0 01-1-1V6zm10 6a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1h-3z" />
    </SvgIcon>
  );
}

export default Icon;
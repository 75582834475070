import Icon from '../Icons/ControllerSettings';
import ControllerConfigurationList from './ControllerConfigurationList';
import ControllerConfigurationCreate from './ControllerConfigurationCreate';
import ControllerConfigurationEdit from './ControllerConfigurationEdit';
import ControllerConfigurationShow from './ControllerConfigurationShow';

const ControllerConfigurationIcon = Icon;


export {
    ControllerConfigurationIcon,
    ControllerConfigurationList,
    ControllerConfigurationShow,
    ControllerConfigurationCreate,
    ControllerConfigurationEdit,
};


import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import {ability} from "../../abilityProvider";
import {TextInput} from "react-admin";



const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
       
    },
}));

const TagBanner = ({classes: classesOverride, className, ...props}) => {    
    const classes = useStyles({ classes: classesOverride });
    const { record } = props;
    if (!record)  {return null}
    const name = record.name;
    const description = record.description;

    return (
        <Card className={`${classes.root} ${className}`}>
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item xs >
                        {ability.can('getOne', 'Tag', 'name')&&<Typography variant="h6" gutterBottom>{name}</Typography>}
                        {ability.can('getOne', 'Tag', 'description')&&<Typography variant="subtitle1" >{description}</Typography>}
                    </Grid>
                </Grid>
            </CardContent> 
        </Card>
    );
};



export default TagBanner;
import { ChipField, DateField, EditButton, Pagination, ReferenceManyField, SimpleShowLayout, SingleFieldList, TextField, TopToolbar, ShowContextProvider, UrlField, useShowController } from 'react-admin';
import Alert from '../Fields/Alert';
import CardBlankLayout from '../Layout/CardBlankLayout';
import CardShowLayout from '../Layout/CardShowLayout';
import CloneButton from '../Buttons/CloneButton';
import ConnectButton from '../Buttons/ConnectButton';
import EnumField from '../Fields/EnumField';
import GasMeterBanner from './GasMeterBanner';
import JsonField from '../Fields/JsonField';
import JsonVariableField from '../Fields/JsonVariableField';
import JsonVariableFields from '../Fields/JsonVariableFields';
import MoreContent from '../Layout/MoreContent';
import NestedField from '../Fields/NestedField';
import React from 'react';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import Separator from '../Layout/Separator';
import { ShowView } from '../Layout/ShowView';
import SwapButton from './GasMeterSwapButton';
import TitleForRecord from '../Layout/TitleForRecord';
import { ability } from "../../abilityProvider";

export const GasMeterQuickShowLayout = props => {
    return (
        <SimpleShowLayout {...props} >
            {ability.can('getOne', 'GasMeter', 'type') && <EnumField source="type" option="resources.GasMeterType.enums.type" />}
            {ability.can('getOne', 'GasMeter', 'configuration') && <NestedField source="configuration" reference="GasMeterConfiguration" >
                <TextField source="name" />
            </NestedField>}
            {ability.can('getOne', 'GasMeter', 'serialNumber') && <TextField source="serialNumber" />}
            {ability.can('getOne', 'GasMeter', 'port') && <TextField source="port" />}
            {ability.can('getOne', 'GasMeter', 'timeZone') && <TextField source="timeZone" />}
            {ability.can('getOne', 'GasMeter', 'commercialHour') && <TextField source="commercialHour" />}
            {ability.can('getOne', 'GasMeter', 'metaVariable') && <JsonVariableFields source="meta.metaVariable" >
                <JsonVariableField />
            </JsonVariableFields>}
            {ability.can('getOne', 'GasMeter', 'meta') && <JsonField source="meta" fullWidth />}
        </SimpleShowLayout>
    );
};


const GasMeterShowActions = ({ basePath, className, data, hasEdit, hasList, resource, ...props }) => {

    const isActive = data && data.status === "ACTIVE"

    return (
        <TopToolbar {...props}>
            {ability.can('create', 'GasMeter') && <CloneButton resource={resource} basePath={basePath} record={data} omit={['id', 'dbId', 'status', 'controller', 'controller.id', 'gasMeterUnit', 'gasMeterUnit.id']} />}
            {ability.can('swap', 'GasMeter') && isActive && <SwapButton resource={resource} basePath={basePath} record={data} />}
            {ability.can('connect', 'GasMeter') && <ConnectButton resource={resource} basePath={basePath} record={data} />}
            {ability.can('update', 'GasMeter') && <EditButton resource={resource} basePath={basePath} record={data} />}
        </TopToolbar>
    )
};

const GasMeterShow = props => {
    const controllerProps = useShowController(props);
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);
    const configurationStatus = controllerProps.record && controllerProps.record.configuration && controllerProps.record.configuration.status;

    return (
        <ShowContextProvider value={controllerProps}>
            <ShowView actions={<GasMeterShowActions />} title={<TitleForRecord source="name" {...props} />} {...props} {...controllerProps} >
                <GasMeterBanner />

                <CardShowLayout>
                    {ability.can('getOne', 'GasMeter', 'type') && <EnumField source="type" option="resources.GasMeterType.enums.type" />}
                    {ability.can('getOne', 'GasMeter', 'configuration') && <NestedField source="configuration" reference="GasMeterConfiguration" >
                        <TextField source="name" />
                    </NestedField>}
                    {(configurationStatus && configurationStatus !== "RELEASE") && <Alert severity="warning">Внимание. Установленная конфигурация еще не выпущена в Релиз!</Alert>}
                    {ability.can('getOne', 'Controller', 'device') && <ReferenceManyField target="gasMeterId" reference="Device" label="resources.Controller.fields.device" >
                        <SingleFieldList linkType="show">
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceManyField>}
                    {ability.can('getOne', 'GasMeter', 'controller') && <NestedField source="controller" reference="Controller" >
                        <TextField source="name" />
                    </NestedField>}
                    {ability.can('getOne', 'GasMeter', 'serialNumber') && <TextField source="serialNumber" />}
                    {ability.can('getOne', 'GasMeter', 'xmlArchiveLink') && <UrlField source="xmlArchiveLink" />}
                    {ability.can('getOne', 'GasMeter', 'port') && <TextField source="port" />}
                    {ability.can('getOne', 'GasMeter', 'timeZone') && <TextField source="timeZone" />}
                    {ability.can('getOne', 'GasMeter', 'commercialHour') && <TextField source="commercialHour" />}
                    {ability.can('getOne', 'GasMeter', 'metaVariable') && <JsonVariableFields source="meta.metaVariable" >
                        <JsonVariableField />
                    </JsonVariableFields>}
                    {ability.can('getOne', 'GasMeter', 'meta') && <JsonField source="meta" fullWidth />}
                </CardShowLayout>

                <Separator action={(<div ref={showMoreButton} ></div>)} />

                <MoreContent showMoreButton={showMoreButton}>
                    <CardShowLayout>
                        {ability.can('getOne', 'GasMeter', 'id') && <TextField source="id" />}
                        {ability.can('getOne', 'GasMeter', 'dbId') && <TextField source="dbId" />}
                        {ability.can('getOne', 'GasMeter', 'createdAt') && <DateField source="createdAt" />}
                        {ability.can('getOne', 'GasMeter', 'createdBy') && <TextField source="createdBy.name" label="resources.GasMeter.fields.createdBy" />}
                        {ability.can('getOne', 'GasMeter', 'updatedAt') && <DateField source="updatedAt" />}
                    </CardShowLayout>
                </MoreContent>

                <Separator action={(<div ref={showHistoryButton} ></div>)} />

                <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                    <CardBlankLayout>
                        <ReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                            <ReferenceHistoryDatagrid source="GasMeter" />
                        </ReferenceManyField>
                    </CardBlankLayout>
                </MoreContent>
            </ShowView>
        </ShowContextProvider>
    );
};

export default GasMeterShow;
import React, { useCallback, useContext, useState } from 'react';

import ConnectContext from "./ConnectContext";
import ConnectTagField from "./ConnectTagField";
import Dialog from '@material-ui/core/Dialog';
import DialogReadTagHistory from "./ConnectDialogReadTagHistory";
import DialogWriteTag from "./ConnectDialogWriteTag";
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    item: {},
    content: {},
    group: {},
    expanded: {},
    selected: {},
    label: {
        padding: 4,
        display: 'flex',
        alignItems: 'flex-start',
        '& > *:not(:first-child)': {
            marginLeft: theme.spacing(),
        },
    },
    labelName: {
        flexGrow: 1,
    },
    icon: {
        color: theme.palette.text.secondary
    },
}));




const ConnectGroupTreeLeaf = ({ node, ...props }) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const { data: connect } = useContext(ConnectContext)

    const handleClick = useCallback(() => {
        if (node.isWrite || node.isHistory) setOpen(true);
    }, [node]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);



    if (!node) return null;

    const id = node.id.toString();
    const name = node.name;
    const unit = node.unit;
    const isWrite = node.isWrite;
    const isHistory = node.isHistory;
    const tags = connect && connect.tags;
    const value = tags && tags[id] && tags[id].value;

    return (
        <>
            <TreeItem
                key={id}
                nodeId={id}
                label={
                    <>
                        <Typography variant="body2" className={classes.labelName}>{`${name}${unit ? ` ( ${unit} )` : ""}`}</Typography>
                        <ConnectTagField node={node} tags={tags} />
                        {isHistory && <HistoryIcon className={classes.icon} fontSize="small" />}
                        {isWrite && <EditIcon className={classes.icon} fontSize="small" />}
                    </>
                }
                onLabelClick={handleClick}
                classes={{
                    root: classes.item,
                    content: classes.content,
                    expanded: classes.expanded,
                    selected: classes.selected,
                    group: classes.group,
                    label: classes.label,
                }}
            />
            <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
                {isWrite && <DialogWriteTag node={{ ...node, value }} handleClose={handleClose} />}
                {isHistory && <DialogReadTagHistory node={{ ...node, value }} handleClose={handleClose} />}
            </Dialog>



        </>
    )
};


ConnectGroupTreeLeaf.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        ns: PropTypes.string.isRequired,
        serial: PropTypes.number.isRequired,
        access: PropTypes.string,
        unit: PropTypes.string,
        type: PropTypes.string,
        isRead: PropTypes.bool,
        isWrite: PropTypes.bool,
        isHistory: PropTypes.bool,
    }),
};

export default React.memo(ConnectGroupTreeLeaf);
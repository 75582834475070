import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { TitleForRecord } from 'react-admin';


const useStyles = makeStyles({
    root: {},
    main: {
        display: 'flex',
    },
    noActions: {
        marginTop: '1em',
    },
    content: {
        flex: '1 1 auto',
    },
});

const sanitizeRestProps = ({
    actions,
    aside,
    title,
    children,
    className,
    id,
    data,
    loading,
    loaded,
    resource,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    version,
    match,
    location,
    history,
    options,
    locale,
    permissions,
    ...rest
}) => rest;

export const ConnectView = ({
    actions,
    aside,
    basePath,
    children,
    classes: classesOverride,
    className,
    defaultTitle,
    hasEdit,
    hasList,
    record,
    tags,
    resource,
    title,
    version,
    ...rest
}) => {
    const classes = useStyles({ classes: classesOverride });
    if (!children) return null;

    return (
        <div
            className={classnames('show-page', classes.root, className)}
            {...sanitizeRestProps(rest)}
        >
            <TitleForRecord
                title={title}
                record={record}
                defaultTitle={defaultTitle}
            />
            {actions &&
                React.cloneElement(actions, {
                    basePath,
                    data: record,
                    hasList,
                    hasEdit,
                    resource,
                    //  Ensure we don't override any user provided props
                    ...actions.props,
                })}
            <div
                className={classnames(classes.main, {
                    [classes.noActions]: !actions,
                })}
            >
                <div className={`${classes.content}`} >
                {record && React.Children.map(children, (field, index) => (
                    React.isValidElement(field) ? React.cloneElement(field, {
                        key: index,
                        resource,
                        basePath,
                        record,
                        version,
                    }) : null
                ))}
                </div>
                {aside &&
                    React.cloneElement(aside, {
                        resource,
                        basePath,
                        record,
                        version,
                    })}
            </div>
        </div>
    );
};

ConnectView.propTypes = {
    actions: PropTypes.element,
    aside: PropTypes.element,
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    defaultTitle: PropTypes.any,
    hasEdit: PropTypes.bool,
    hasList: PropTypes.bool,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    record: PropTypes.object,
    resource: PropTypes.string,
    title: PropTypes.any,
    version: PropTypes.node,
};

ConnectView.defaultProps = {
    classes: {},
};

import jsonExport from 'jsonexport/dist';
import { downloadCSV  } from 'react-admin';
import booleanFormatter from './booleanFormatter';
import dateFormatter from './dateFormatter';
import enumFormatter from './enumFormatter';




const parseHeaders = (model, externalKey) => {
    const {__resource, ...object} = model

    const array = Object.keys(object).reduce((result, key) => {
        const value = object[key]
        if (typeof value === 'object') {
            const nested = parseHeaders(value, key)
            result.push(...nested)
        } else {
            if (Boolean(value)) {
                const item = externalKey ? `${externalKey}.${key}` : key ;
                result.push(item)
            } 
        };

        return result;
    }, []);

    return array
}

const parseHeadesTitles = (model, translate, externalObject, externalKey) => {
    const {__resource, ...object} = model

    const array = Object.keys(object).reduce((result, key) => {
        const value = object[key]
        if (typeof value === 'object') {
            const nested = parseHeadesTitles(value, translate, model, key)
            result.push(...nested)
        } else {
            if (Boolean(value)) {
                const item = externalObject ? `${translate(`resources.${externalObject.__resource}.fields.${externalKey}`)}.${translate(`resources.${__resource}.fields.${key}`)}` : translate(`resources.${__resource}.fields.${key}`) ;
                result.push(item)
            } 
        };

        return result;
    }, []);

    return array
}

const parseRecord = (model, record, translate) => {
    const {__resource, ...object} = model
    const data = {}
    Object.keys(object).forEach( key => {
        const value = object[key]
        const valueRecord = record[key]
        if (typeof value === 'object') {
            if (valueRecord instanceof Array) {
                data[key] = valueRecord.map(item => parseRecord(value, item, translate))
            } else if (typeof valueRecord === 'object') {
                data[key] = parseRecord(value, valueRecord, translate)
            }
        } else {
            if (Boolean(value)) {
                data[key] = (typeof value === 'function') ? value(key, valueRecord, model, record, translate) :  valueRecord
            } 
        };

    });

    return data
}

const exporter = (title, model, {translate, ...options}) => (records, fetchRelatedRecords, dataProvider, resource) => {

    const headers = parseHeaders(model)
    const rename = parseHeadesTitles(model, translate)
    const data = records.map(record => parseRecord(model, record, translate));

    const BOM = "\uFEFF";

    jsonExport(data, {
        rowDelimiter: ";",
        headers,
        rename,
        ...options,
    }, (err, csv) => {
        downloadCSV(BOM + csv, title);
    });
};



export default exporter
export {
    booleanFormatter,
    dateFormatter,
    enumFormatter,
}
import apolloDatabusClient from './apolloDatabusClient';
import gql from 'graphql-tag'

const TAG_HISTORY = gql`
    query tagHistory($id: IdentifierUniqueInput!, $timestamp_gte: Time!, $timestamp_lte: Time) {
        data: tagHistory(id: $id, timestamp_gte: $timestamp_gte, timestamp_lte: $timestamp_lte) {
            id {
                id
                ns
                serial
            }
            data {
                value
                quality
                timestamp
                timestampSrv
            }
            timestamp_gte
            timestamp_lte
        }
    }
`;

export default (args) => {

    return apolloDatabusClient.query({
        query: TAG_HISTORY,
        variables: {
            ...args
        },
        onError: (error) => {
            alert(error)
        }
    });
};
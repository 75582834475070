import React, { useContext } from 'react';
import get from 'lodash/get';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import { makeStyles } from '@material-ui/core/styles';
import ConnectContext from "./ConnectContext";


const useStyles = makeStyles(theme => ({
    root: {
        margin: ".125rem",
    },
    iconLoading: {
        color: "#616161"
    },
    iconConnect: {
        color: "#388e3c"
    },
    iconDisconnect: {
        color: "#e53935"
    },
}));


const TCPConnectChip = (props) => {
    const { loading, data } = useContext(ConnectContext)
    const classes = useStyles();

    const tcpConnect = get(data, ["tags", "7022", "value"]) === "Да"
    const icon = loading ? <CircularProgress className={classes.iconLoading} size={16} thickness={4} /> : tcpConnect ? <PowerIcon className={classes.iconConnect} /> : <PowerOffIcon className={classes.iconDisconnect} />
    const title = loading ? "Подключение..." : tcpConnect ? "Подключено" : "Не подключено"

    return <Chip
        classes={{ root: classes.root }}
        size="small"
        icon={icon}
        title={title}
        label="TCP-соединение"
    />;
}

export default TCPConnectChip;
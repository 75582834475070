// load .env
require('dotenv').config();

const global_env = (process.env.NODE_ENV === 'development') ? process.env : window.ENV;

export const TITLE_APP = global_env.REACT_APP_TITLE_APP || 'АСКУГ Управление'
export const PERMISSIONS_NAMESPACE = 'ASKUG_SERVICE'
export const GROUP_NAME_APP = 'ASKUG'
export const AUTH_TOKEN_COOKIE = `${GROUP_NAME_APP}-AUTH-TOKEN`
export const REFRESH_TOKEN_COOKIE = `${GROUP_NAME_APP}-REFRESH-TOKEN`
export const DOMAIN_COOKIE = global_env.REACT_APP_DOMAIN_COOKIE || "localhost"
export const PACKAGE_NAME = global_env.REACT_APP_NAME || "web-askug-main-portal"
export const PACKAGE_VERSION = global_env.REACT_APP_VERSION || "0.0.1"
export const PACKAGE_VERSION_COOKIE = `${GROUP_NAME_APP}-PACKAGE-VERSION`

// SERVICE URI's
export const URI_CORE = global_env.REACT_APP_URI_CORE || 'http://localhost:5550'
export const WS_CORE = global_env.REACT_APP_WS_CORE || 'ws://localhost:5550'
export const URI_CASL = global_env.REACT_APP_URI_CASL || 'http://localhost:4555'
export const URI_AUTH = global_env.REACT_APP_URI_AUTH || 'http://localhost:5000'
export const URI_DATABUS = global_env.REACT_APP_URI_DATABUS || 'http://localhost:50052/message'
export const WS_DATABUS = global_env.REACT_APP_WS_DATABUS || 'ws://localhost:50052/subscriptions'
export const URI_EXPORTXML = global_env.REACT_APP_URI_EXPORTXML || 'http://localhost:9130/graphql'

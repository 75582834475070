import React from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { makeStyles, MenuItem, ListItemText } from '@material-ui/core';
import classnames from 'classnames';
import StatusChip from "./ControllerConfigurationStatusChip";


const useStyles = makeStyles(theme => ({
        root: {},
        icon: {
            marginRight: 16
        },
        selected: { fontWeight: 500 },
        highlight: { 
            fontWeight: 500,
            color: "#000"
        },
        title: { },
        ml1: { marginLeft: 8 },
    }),
    { name: 'RaAutocompleteSuggestionItem' }
);

const Suggestion = ({
    suggestion,
    index,
    highlightedIndex,
    isSelected,
    filterValue,
    classes: classesOverride,
    getSuggestionText,
    ...props
}) => {
    const classes = useStyles({ classes: classesOverride });
    const suggestionText = getSuggestionText(suggestion);
    if (!suggestion.id) return <MenuItem key={suggestion.id} {...props}>{suggestionText}</MenuItem>;
    const isHighlighted = highlightedIndex === index;
    const matches = match(suggestionText, filterValue);
    const parts = parse(suggestionText, matches);
    

    return (
        <MenuItem
            key={suggestion.id}
            selected={isHighlighted}
            className={classnames(classes.root, {
                [classes.selected]: isSelected,
            })}
            {...props}
        >
            <ListItemText>
                {parts.map((part, index) => (<span key={index} className={(part.highlight) ? classes.highlight : ""}>{part.text}</span>))}
            </ListItemText>
            <StatusChip {...props} record={suggestion} className={classes.ml1}/>
        </MenuItem>
    );
};

Suggestion.defaultProps = {
    resource: "ControllerConfiguration"
}

export default Suggestion;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TreeView from '@material-ui/lab/TreeView';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ConnectChip from './ConnectChip';
import TCPConnectChip from './TCPConnectChip';
import Error from './Error';
import ConnectGroupTreeNode from './ConnectGroupTreeNode';



const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        '&:first-child': {
            marginTop: 0,
        },
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginBottom: 4,
    },
    tree: {},
    expandIcon: {
        color: "#fbc02d"
    },
    item: {},
    content: {},
    group: {},
    expanded: {},
    selected: {},
}));

const readRegex = /R/i;
const writeRegex = /W/i;
const historyRegex = /H/i;

const buildTree = (functionalGroupSet, ns, dbId, parameterSet) => {
    const id = functionalGroupSet.ID;
    const name = functionalGroupSet.Name;
    const nodes = functionalGroupSet.FunctionalGroup;
    const leafs = functionalGroupSet.uaIDref;
    const tree = { id, name }

    if (!id || !name) console.log(`ошибка узла: ID=${id}, Name=${name}`);
    if (nodes && Array.isArray(nodes)) {
        tree.nodes = nodes.map(node => buildTree(node, ns, dbId, parameterSet))
    };
    if (leafs && Array.isArray(leafs)) {
        tree.leafs = leafs.reduce((result, uaID) => {
            const uaVariable = parameterSet.uaVariable.find(item => item.ID === uaID);
            if (uaVariable) {

                const { ID, Name, AccessLevel, units, vType, ...other } = uaVariable

                result.push({
                    id: ID,
                    name: Name,
                    ns: ns,
                    serial: dbId,
                    access: AccessLevel,
                    unit: units,
                    type: vType,
                    isRead: readRegex.test(AccessLevel),
                    isWrite: writeRegex.test(AccessLevel),
                    isHistory: historyRegex.test(AccessLevel),
                    ...other,
                })
            } else {
                console.log(`Отсутствует параметр: uaID=${uaID}`);
            }

            return result
        }, [])
    };

    return tree
}

const ConnectGroupTree = ({ className, configuration, record, ...props }) => {
    const classes = useStyles();
    const { ns, parameterSet, functionalGroupSet } = configuration;
    const dbId = record && record.dbId;

    if (!ns || !dbId || !parameterSet || !functionalGroupSet) return <Error errorPrimary="ra.notification.configuration_not_found" error={{ message: "Конфигурация этого корректора не содержит необходимых параметров для получения данных" }} />;

    const groupTree = buildTree(functionalGroupSet, ns, dbId, parameterSet);



    return (
        <div className={`${classes.root} ${className}`}>
            <div className={classes.title}>
                <Typography variant="subtitle2" color="textSecondary" noWrap>Параметры</Typography>
                <div>
                    <TCPConnectChip />
                    <ConnectChip />
                </div>
            </div>
            <Card >
                <CardContent>
                    <TreeView
                        className={classes.tree}
                        defaultExpanded={['root']}
                        defaultCollapseIcon={<FolderOpenIcon className={classes.expandIcon} />}
                        defaultExpandIcon={<FolderIcon className={classes.expandIcon} />}
                    >
                        <ConnectGroupTreeNode node={groupTree} />
                    </TreeView>
                </CardContent>
            </Card>
        </div>
    );
}

ConnectGroupTree.propTypes = {
    configuration: PropTypes.shape({
        ns: PropTypes.string,
        parameterSet: PropTypes.object,
        functionalGroupSet: PropTypes.object,
    }),
};

ConnectGroupTree.defaultProps = {
    configuration: {
        ns: undefined,
        parameterSet: undefined,
        functionalGroupSet: undefined,
    },
};


export default ConnectGroupTree;
import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNotify, useUnselectAll, useRefresh, fetchStart, fetchEnd, Button } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import deleteManyFromTrash from "../../dataProvider/Core/deleteManyFromTrash";

export const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    undoable,
    redirect,
    submitOnEnter,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    deleteButton: {},
}));

const BulkDeleteButton = ({
    basePath,
    resource,
    classes: classesOverride,
    className,
    filterValues,
    selectedIds,
    ...rest }) => {

    const trashItems = useSelector(state => state.admin.resources.TrashItem.data, shallowEqual);
    const dispatch = useDispatch();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const classes = useStyles({ classes: classesOverride });
    const [loading, setLoading] = useState(false);

    const handleClick = () => {

        setLoading(true);
        dispatch(fetchStart());

        deleteManyFromTrash({
            resource,
            items: trashItems,
            ids: selectedIds,
        }).then(() => {
            refresh();
            notify('ra.notification.deleted_from_trash', 'info', { smart_count: selectedIds.length });
            unselectAll(resource)

        })
            .catch((error) => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    };

    return (
        <Button
            onClick={handleClick}
            disabled={loading}
            label='ra.action.delete'
            color="secondary"
            className={`ra-delete-button ${classes.deleteButton} ${className}`}
            key="button"
            {...sanitizeRestProps(rest)}
        >
            <DeleteForeverIcon />
        </Button>
    );

};

export default BulkDeleteButton;
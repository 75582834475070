import Icon from '../Icons/Controller';
import ControllerList from './ControllerList';
import ControllerCreate from './ControllerCreate';
import ControllerEdit from './ControllerEdit';
import ControllerShow from './ControllerShow';

const ControllerIcon = Icon;


export {
    ControllerIcon,
    ControllerList,
    ControllerShow,
    ControllerCreate,
    ControllerEdit,
};


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, AutocompleteInput, SimpleForm, ReferenceInput, Toolbar, SaveButton, required, ReferenceField } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import DeviceBanner from './DeviceBanner';
import Alert from '@material-ui/lab/Alert';
import WarningIcon from '@material-ui/icons/Warning';
import { ability } from "../../abilityProvider";



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
}));

const DeviceToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {ability.can("update", "Device") && <SaveButton />}
            {ability.can("update", "Device") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {/*{ability.can("delete", "Device") && <RemoveButton className={classes.mlAuto} redirect="list"/>}*/}
        </Toolbar>
    );
};

const DeviceEdit = props => {
    // const classes = useStyles();
    const validate = [required()];

    return (
        <>
            <Alert severity="warning" icon={<WarningIcon />} style={{ border: "1px solid #ff9800", marginTop: 24 }} >
                <div>Внимание. Будьте осторожны при редактировании <strong>Устройства</strong>.</div>
                <div>При изменении Контроллера, Корректора через эту форму не будут созданы записи в журналах владения УУГ.</div>
                <div>Если вам необходимо заменить Контроллер или Корректор, то воспользуйтесь специальными функциями ЗАМЕНИТЬ в режиме просмотра Контроллера или Корректора.</div>
            </Alert>
            <Edit title={<TitleForRecord source="name" {...props} />} {...props}  >
                <DeviceBanner />
                <CardBlankLayout >
                    <SimpleForm toolbar={<DeviceToolbar />}>
                        {ability.can('getOne', 'Device', 'gasMeterUnit') && <ReferenceField source="id" reference="GasMeterUnit" link={false} label="resources.Device.fields.gasMeterUnit" >
                            <TextField source="name" />
                        </ReferenceField>}
                        <ReferenceInput source="controllerId" validate={validate} reference="Controller" label="resources.Device.fields.controller" filter={{ status: 'DRAFT' }} sort={{ field: 'name', order: 'ASC' }} fullWidth>
                            <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput source="gasMeterId" validate={validate} reference="GasMeter" label="resources.Device.fields.gasMeter" filter={{ gasMeterUnitReference: null, controller: null, status: 'DRAFT' }} sort={{ field: 'name', order: 'ASC' }} fullWidth>
                            <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                    </SimpleForm>
                </CardBlankLayout>
            </Edit>
        </>
    );
};

export default DeviceEdit
import { useEffect, useState } from 'react';

import apolloCoreClient from './apolloCoreClient';
import gql from 'graphql-tag';

export const GET_ONE_GAS_METER_UNIT = gql`
    query GasMeterUnit( $where: GasMeterUnitWhereUniqueInput! ) {
        data: GasMeterUnit( where: $where ) {
            id
            ownershipControllers(orderBy: createdAt_DESC) {
                id
                controller {
                    id
                    name
                    description
                    status
                    type
                }
                dateFrom
                dateTo
                createdAt
                parent { id }
                reason
            }
            ownershipGasMeters(orderBy: createdAt_DESC) {
                id
                gasMeter {
                    id
                    name
                    description
                    status
                    type
                }
                dateFrom
                dateTo
                createdAt
                parent { id }
                reason
            }
        }
    }
`


export default function (id) {

    const [state, setState] = useState({
        data: undefined,
        error: undefined,
        loading: true,
    })

    useEffect(() => {

        if (!id) return;

        apolloCoreClient.query({
            query: GET_ONE_GAS_METER_UNIT,
            variables: { where: { id } },
            fetchPolicy: 'network-only',
        })
            .then(({ data: { data } }) => {
                setState({
                    data,
                    loading: false,
                    error: undefined,
                });
            })
            .catch(error => {
                setState({
                    error,
                    loading: false,
                });
            });
    }, [id]);


    return state;
}

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'



const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing()
  },
  content: {
      maxWidth: theme.spacing(96),
      display: "flex", 
      flexDirection: "column", 
      alignItems: "center",
  },
  icon: {},
  action: {marginTop: theme.spacing()},
  title: {},
  subheader: {},
}));



const Separator = ({ action, icon, classes: classesOverride, subheader, title, color,  ...props }) => {
  const classes = useStyles({ classes: classesOverride });
    return (
      <div className={`${classes.root}`}>
          <div className={`${classes.content}`}>
            { icon && <Typography component="span" className={classes.icon} color={color || "default"} >{icon}</Typography> }
            { title && <Typography variant="body2" gutterBottom align="center" color={color || "default"}  className={classes.title}>{title}</Typography> }
            { subheader && <Typography variant="caption"  gutterBottom align="center" className={classes.subheader}>{subheader}</Typography>}
            {action && <div className={classes.action}>{action}</div>}
          </div>
      </div>
    );
  
}

Separator.propTypes = {
  action: PropTypes.node,
  icon: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  subheader: PropTypes.node,
  title: PropTypes.node,
};



export default Separator;

import gql from 'graphql-tag'
import apolloDatabusClient from './apolloDatabusClient';



const WRITE_TAG = gql`
    mutation writeTag($input: WriteTagInput!) {
        data: writeTag(input: $input) {
            id {
                id
                ns
                serial
            }
            value
            quality
            timestamp
        }
    }
`;

export default ({writeTag, ...args}) => {
    
    return apolloDatabusClient.mutate({
        mutation: WRITE_TAG,
        variables: {
            input: writeTag
        },
        onError: (error) => {
            alert(error)
        }
    })
     

};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2 5.9l.8-.8A7.05 7.05 0 008 3c-1.8 0-3.6.7-5 2.1l.8.8C5 4.8 6.5 4.2 8 4.2s3 .6 4.2 1.7zm-.9.8A4.7 4.7 0 008 5.3c-1.2 0-2.4.5-3.3 1.4l.8.8c.7-.7 1.6-1 2.5-1 .9 0 1.8.3 2.5 1l.8-.8zM9 13h6.4a7 7 0 00-3.11 8H5a2 2 0 01-2-2v-4a2 2 0 012-2h2V9h2v4zm-3 5v-2h2v2H6zm3.5 0v-2h2v2h-2zm8.24-3.79A.26.26 0 0118 14h2c.11 0 .22.09.23.21l.19 1.32c.31.12.58.29.85.47l1.23-.5c.13 0 .26 0 .32.13l1 1.73c.06.11.03.24-.06.32l-1.06.82a2.81 2.81 0 010 .97v.03l1.07.82c.09.08.12.21.06.32l-1 1.73c-.07.13-.2.13-.33.13l-1.23-.5c-.27.18-.54.34-.84.47l-.19 1.32A.25.25 0 0120 24h-2a.25.25 0 01-.26-.21l-.19-1.32a4.11 4.11 0 01-.85-.47l-1.24.5c-.12 0-.25 0-.31-.13l-1-1.73a.26.26 0 01.06-.32l1.06-.82a4.2 4.2 0 010-1l-1.06-.82a.25.25 0 01-.06-.32l1-1.73c.07-.13.2-.13.31-.13l1.24.5a5 5 0 01.85-.47l.19-1.32zM19 17.5a1.5 1.5 0 110 3 1.5 1.5 0 110-3z" />
    </SvgIcon>
  );
}

export default Icon;
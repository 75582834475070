import AutocompleteInput from '../Inputs/AutoompleteInput'
import { ControllerQuickCreateForm } from './ControllerCreate';
import { ControllerQuickShowLayout } from './ControllerShow';
import QuickCreateButton from "../Buttons/QuickCreateButton";
import QuickShowButton from "../Buttons/QuickShowButton";
import React from "react";
import { ReferenceInput } from "react-admin";
import Suggestion from "./ControllerSuggestion";
import { makeStyles } from "@material-ui/core/styles";

const matchTrue = () => true;

const useStyles = makeStyles({
    container: {
        display: "inline-block",
    }
});

const ControllerReferenceInput = props => {
    const classes = useStyles();

    return (
        <>
            <ReferenceInput {...props} classes={{ container: classes.container }} >
                <AutocompleteInput optionText="name" suggestionItem={<Suggestion />} matchSuggestion={matchTrue} />
            </ReferenceInput>
            <QuickCreateButton {...props}>
                <ControllerQuickCreateForm />
            </QuickCreateButton>
            <QuickShowButton {...props} >
                <ControllerQuickShowLayout />
            </QuickShowButton>

        </>
    );
};

export default ControllerReferenceInput;

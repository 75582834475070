import { useVersion, useTranslate, useNotify } from 'ra-core';

import useDevice from "./useDevice";
// import useController from "./useController";
import useGasMeter from './useGasMeter';

const hookFactory = {
  Device: useDevice,
  // Controller: useController,
  GasMeter: useGasMeter,
  
}

export default function(props) {
  const { basePath, id, resource } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const version = useVersion();
  const { data: record, loading, error } = hookFactory[resource](resource, id, version);
  
  if (error) notify('ra.notification.item_doesnt_exist', 'warning');

  const defaultTitle = "Подключение к корректору";



  return {
    loading,
    defaultTitle,
    resource,
    basePath,
    record,
    version,
  };
}

import React from 'react';
import { ability, abilitySubjectConditions as asc, rulesToFields } from '../../abilityProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, TextInput, required, minLength, email } from 'react-admin';
import PhoneInput from '../Inputs/PhoneInput';
import PasswordInput from '../Inputs/PasswordInput';
import EnumInput from '../Inputs/EnumInput';



// const useStyles = makeStyles({
//     referenceArrayInput:{
//         marginTop: 8,
//         marginBottom: 4,
//     }
// });

const filterRoles = (value) => {
    const conditions = rulesToFields("create", "User")
    const role_in = (conditions.role && conditions.role.$in) || [];
    return role_in.includes(value.name);
}


const logined = (value, values) => {
    if (!values.email && !values.phone && !values.login) {
        return 'ra.action.one_of_fields_is_required';
    }
};
const validateLogin = [logined];
const validateName = [required(), minLength(2)];
const validateRole = [required()];
const validatePassword = [required()];

const UserCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >
            <SimpleForm redirect="list" >
                {ability.can("create", "User", "name") && <TextInput source="name" validate={validateName} fullWidth />}
                {ability.can("create", "User", "description") && <TextInput source="description" fullWidth />}
                {ability.can("create", "User", "position") && <TextInput source="position" fullWidth />}
                {ability.can("create", "User", "role") && <EnumInput source="role" Enum="UserRole" filter={filterRoles} validate={validateRole} />}
                {ability.can("create", "User", "login") && <TextInput source="login" validate={validateLogin} />}
                {ability.can("create", "User", "email") && <TextInput source="email" type="email" validate={validateLogin} inputProps={{ autoComplete: "new-password" }} />}
                {ability.can("create", "User", "phone") && <PhoneInput source="phone" validate={validateLogin} inputProps={{ autoComplete: "new-password" }} />}
                {ability.can("create", "User", "phoneContact") && <PhoneInput source="phoneContact" />}
                {ability.can("create", "User", "password") && <PasswordInput source="password" validate={validatePassword} />}
            </SimpleForm>
        </Create>
    );
};

export default UserCreate
import React, { forwardRef } from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import { useSelector } from 'react-redux';



const useStyles = makeStyles(theme => ({
    chip: (props) => {
        const backgroundColor = props.color ? props.color : theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];

        return {
            color: theme.palette.getContrastText(backgroundColor),
            backgroundColor: backgroundColor,
            '&:hover, &:focus': {
                backgroundColor: darken(backgroundColor, 0.12),
            }
        }
    }
}));

const LinkRef = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const TagChip = forwardRef(({ classes: classesOverride, className, record, ...props }, ref) => {
    const classes = useStyles({ color: record.color, classes: classesOverride });
    const { filter, order, page, perPage, sort } = useSelector(
        (state) => {
            return state.admin.resources[record.resource] ? state.admin.resources[record.resource].list.params : {}
        }
    );

    return (
        <Chip
            className={classes.chip}
            label={record.name}
            size="small"
            component={LinkRef}
            ref={ref}
            // to={{
            //     pathname: `/${record.resource}`,
            //     search: stringify({
            //         filter: JSON.stringify({
            //             ...filter,
            //             tags_some: {
            //                 id: record.id
            //             }
            //         }),
            //         order,
            //         page,
            //         perPage,
            //         sort,
            //     }),
            // }}
            to={`/Tag/${record.id}/show`}
            clickable
        />
    );
});

export default TagChip;
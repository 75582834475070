import React from 'react';
import { TextField, DateField, ReferenceManyField, Pagination, TopToolbar, EditButton } from 'react-admin';
import { Show } from '../Layout/Show';
import CardShowLayout from '../Layout/CardShowLayout';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
import EnumField from '../Fields/EnumField';
import MoreContent from '../Layout/MoreContent';
import Separator from '../Layout/Separator';
import { ReferenceDatagrid as ReferenceHistoryDatagrid } from '../History/HistoryList';
import JsonField from '../Fields/JsonField';
import GasMeterConfigurationBanner from './GasMeterConfigurationBanner';
import CloneButton from '../Buttons/CloneButton';
import { ability } from "../../abilityProvider";



const GasMeterConfigurationActions = ({ basePath, className, data, hasEdit, hasList, ...props }) => (
    <TopToolbar {...props}>
        {ability.can('create', 'GasMeterConfiguration') && <CloneButton basePath={basePath} record={data} />}
        {ability.can('update', 'GasMeterConfiguration') && <EditButton basePath={basePath} record={data} />}
    </TopToolbar>
);

const GasMeterConfigurationShow = props => {
    const showMoreButton = React.useRef(null);
    const showHistoryButton = React.useRef(null);

    return (
        <Show actions={<GasMeterConfigurationActions />} title={<TitleForRecord source="name" {...props} />} {...props} >
            <GasMeterConfigurationBanner />
            <CardShowLayout>
                {ability.can('getOne', 'GasMeterConfiguration', 'type') && <EnumField source="type" option="resources.GasMeterType.enums.type" />}
                {ability.can('getOne', 'GasMeterConfiguration', 'ns') && <TextField source="ns" />}
                {ability.can('getOne', 'GasMeterConfiguration', 'parameterSet') && <JsonField source="parameterSet" fullWidth />}
                {ability.can('getOne', 'GasMeterConfiguration', 'functionalGroupSet') && <JsonField source="functionalGroupSet" fullWidth />}
                {ability.can('getOne', 'GasMeterConfiguration', 'protocolSet') && <JsonField source="protocolSet" fullWidth />}
                {ability.can('getOne', 'GasMeterConfiguration', 'schedulerSet') && <JsonField source="schedulerSet" fullWidth />}
                {ability.can('getOne', 'GasMeterConfiguration', 'converterSet') && <JsonField source="converterSet" fullWidth />}
                {ability.can('getOne', 'GasMeterConfiguration', 'scadaSet') && <JsonField source="scadaSet" fullWidth />}
                {ability.can('getOne', 'GasMeterConfiguration', 'metaTemplate') && <JsonField source="metaTemplate" fullWidth />}
            </CardShowLayout>

            <Separator action={(<div ref={showMoreButton} ></div>)} />

            <MoreContent showMoreButton={showMoreButton}>
                <CardShowLayout>
                    {ability.can('getOne', 'GasMeterConfiguration', 'id') && <TextField source="id" />}
                    {ability.can('getOne', 'GasMeterConfiguration', 'createdAt') && <DateField source="createdAt" />}
                    {ability.can('getOne', 'GasMeterConfiguration', 'createdBy') && <TextField source="createdBy.name" label="resources.GasMeterConfiguration.fields.createdBy" />}
                    {ability.can('getOne', 'GasMeterConfiguration', 'updatedAt') && <DateField source="updatedAt" />}
                </CardShowLayout>
            </MoreContent>

            <Separator action={(<div ref={showHistoryButton} ></div>)} />

            <MoreContent showMoreButton={showHistoryButton} label="ra.action.show_history">
                <CardBlankLayout>
                    <ReferenceManyField reference="History" target="item" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10} pagination={<Pagination />} >
                        <ReferenceHistoryDatagrid source="GasMeterConfiguration" />
                    </ReferenceManyField>
                </CardBlankLayout>
            </MoreContent>
        </Show>
    );
};

export default GasMeterConfigurationShow;
import Chip from '@material-ui/core/Chip';
import React from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import sanitizeRestProps from '../Fields/sanitizeRestProps';
import { useTranslate } from 'react-admin';

const getStatusColor = (status) => {
    switch (status) {
        case 'DRAFT':
            return "#eeeeee"
            break;
        case 'TEST':
            return "#ffcc80"
            break;
        case 'ACTIVE':
            return "#80cbc4"
            break;
        case 'SWAP':
            return "#ce93d8"
            break;
        default:
            return "#eeeeee"
            break;
    }
}


const useChipStyles = makeStyles(theme => ({
    chip: ({ status }) => {
        const backgroundColor = getStatusColor(status);
        return {
            color: theme.palette.getContrastText(backgroundColor),
            backgroundColor: backgroundColor,
        }
    },
}));

const StatusChip = ({ className, classes: classesOverride, record, source, resource, save, saving, ...props }) => {
    const status = get(record, source);
    const classes = useChipStyles({ status, classes: classesOverride });
    const translate = useTranslate();
    if (!status) { return null };
    const label = translate(`resources.${resource}.enums.${source}.${status}`, { _: status })

    return (
        <Chip
            classes={{ root: classes.chip }}
            className={className}
            label={label}
            size="small"
            {...sanitizeRestProps(props)}
        />
    );
};


StatusChip.defaultProps = {
    addLabel: true,
    resource: "Controller",
    source: "status",
};



export default StatusChip;
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import sanitizeRestProps from './sanitizeRestProps';
import CheckboxBlank from '../Icons/CheckboxBlank';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root : {
        display: "flex",
        alignItems: "center"
    }
}));

const ColorField = ({ className, record, source, hex, ...props }) => {
 
    const classes = useStyles();
    const color = get(record, source);
    if (!color) {return null};
    

    return (
        <div className={classes.root}>
            <CheckboxBlank style={{color: color}}/>
            {hex && <Typography variant="body2" className={className} {...sanitizeRestProps(props)} >{color}</Typography>}
        </div>
        
    )
};

ColorField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

ColorField.defaultProps = {
    addLabel: true,
};

export default ColorField;
import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';



const LOGIN_USER_MUTATION = gql`
    mutation loginUser($id: ID!, $name: String!, $description: String, $login: String, $email: String, $phone: String, $role: UserRole!, $banned: Boolean) {
        loginUser(authId: $id, name: $name, description: $description, login: $login, email: $email, phone: $phone, role: $role, banned: $banned) {
            id
            authId
            name
            login
            email
            phone
            role
            banned
        }
    }
`;

export default (args) => {
    return apolloCoreClient.mutate({
        mutation: LOGIN_USER_MUTATION,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12.2 5.9l.8-.8A7.05 7.05 0 008 3c-1.8 0-3.6.7-5 2.1l.8.8C5 4.8 6.5 4.2 8 4.2s3 .6 4.2 1.7zm-.9.8A4.7 4.7 0 008 5.3c-1.2 0-2.4.5-3.3 1.4l.8.8c.7-.7 1.6-1 2.5-1 .9 0 1.8.3 2.5 1l.8-.8zM19 13H9V9H7v4H5a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2zM8 18H6v-2h2v2zm3.5 0h-2v-2h2v2zm3.5 0h-2v-2h2v2z" />
    </SvgIcon>
  );
}

export default Icon;
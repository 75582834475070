import React from "react";
import { useFormState } from 'react-final-form';
import { ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";



const matchTrue = () => true;

const ReferenceArrayGasMeterUnitInput = props => {
    const { values } = useFormState();
    const status = values.status
    if (!["TEST", "ACTIVE"].includes(status)) return null

    return (
        <>
            <ReferenceArrayInput {...props}>
                <AutocompleteArrayInput matchSuggestion={matchTrue}/>
            </ReferenceArrayInput>
        </>
    );
};

export default ReferenceArrayGasMeterUnitInput;

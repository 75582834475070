import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DeviceCreate from "./DeviceCreate";
import DeviceShow from "./DeviceShow";
import DeviceEdit from "./DeviceEdit";
import DeviceList from "./DeviceList";

const DeviceIcon = DeviceHubIcon;

export {
    DeviceIcon,
    DeviceEdit,
    DeviceList,
    DeviceShow,
    DeviceCreate
}
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, useTranslate } from 'react-admin';
import Queue from '@material-ui/icons/Queue';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import moment from 'moment'
import 'moment/locale/ru';
import lodashOmit from 'lodash/omit';



const stopPropagation = e => e.stopPropagation();

const CloneButton = ({ basePath, record, omit, ...props }) => {
    
    const translate = useTranslate();
    useSelector(state => state.admin.loading > 0);
    if (!record) return null;

    const omitRecord = lodashOmit(record, omit)

    return (
        <Button
            component={Link}
            to={
                record
                ? {
                      pathname: `${basePath}/create`,
                      search: stringify({
                          source: JSON.stringify({
                            ...omitRecord,
                            name: `${record.name} - ${translate('ra.message.copy', {from: moment().format('L')})}`
                          }),
                      }),
                  }
                : `${basePath}/create`
            }
            label='ra.action.clone'
            onClick={stopPropagation}
        >
            <Queue />
        </Button>
    );
};

CloneButton.defaultProps = {
    omit: ['id']
}

export default CloneButton


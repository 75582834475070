import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { useInput, FieldTitle, InputHelperText } from 'react-admin';
import sanitizeRestProps from './sanitizeRestProps';


const formatDefault = (value) => {
    const formated = moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL);
    return formated;
};

const parseDefault = (value) => {
    const parsed = moment(value).local().format();
    return parsed;
};


const DateTimeInput = ({
    format = formatDefault,
    label,
    helperText,
    margin = 'dense',
    onBlur,
    onChange,
    onFocus,
    options,
    source,
    resource,
    parse = parseDefault,
    validate,
    variant = 'filled',
    ...rest
}) => {
    const {
        id,
        input,
        isRequired,
        meta: { error, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'datetime-local',
        validate,
        ...rest,
    });

    return (
        <TextField
            id={id}
            {...input}
            variant={variant}
            margin={margin}
            error={!!(touched && error)}
            helperText={
                <InputHelperText
                    touched={touched}
                    error={error}
                    helperText={helperText}
                />
            }
            label={
                <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                />
            }
            InputLabelProps={{
                shrink: true,
            }}
            {...options}
            {...sanitizeRestProps(rest)}
        />
    );
};

DateTimeInput.propTypes = {
    label: PropTypes.string,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};

DateTimeInput.defaultProps = {
    options: {},
};

export default DateTimeInput;

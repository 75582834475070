import React from 'react';
import { ShowButton, TopToolbar } from 'react-admin';
import { ConnectView } from '../Layout/Connect';
import TitleForRecord from '../Layout/TitleForRecord';
import DeviceBanner from './DeviceBanner';
import useConnectController from "../../dataProvider/Core/useConnectController";
import TCPConnectButton from '../Buttons/TCPConnectButton';
import ConnectLayout from "../Layout/ConnectLayout";
import ConnectGroupTree from "../Layout/ConnectGroupTree";

const DeviceConnectActions = ({ basePath, className, data, hasEdit, hasList, ...props }) => (
    <TopToolbar {...props}>
        <TCPConnectButton basePath={basePath} record={data} />
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);


const DeviceConnect = props => {
    const controllerProps = useConnectController(props);
    const configuration = controllerProps && controllerProps.record && controllerProps.record.configuration

    return (
        <ConnectView
            title={<TitleForRecord source="name" {...props} />}
            actions={<DeviceConnectActions />}
            {...controllerProps}
        >
            <DeviceBanner />

            <ConnectLayout >
                <ConnectGroupTree configuration={configuration} />
            </ConnectLayout>
        </ConnectView>


    );
};

export default DeviceConnect;
import gql from 'graphql-tag'
import apolloCoreClient from './apolloCoreClient';

const SWAP_CONTROLLER = gql`
    mutation swapController($where: ControllerWhereUniqueInput!, $swap: ControllerWhereUniqueInput!, $dateFrom: DateTime, $reason: String ) {
    data: swapController(where: $where, swap: $swap, dateFrom: $dateFrom, reason: $reason) {
        swapFrom {id name}
        swapTo {id name}
        reason
    }
}`



export default ({ where, swap, dateFrom, reason }) => {
    return apolloCoreClient.mutate({
        mutation: SWAP_CONTROLLER,
        variables: { where, swap, dateFrom, reason },
        onError: (error) => {
            alert(error)
        }
    });
};

  import React, { cloneElement, Children } from 'react';
  import get from 'lodash/get';
  import { makeStyles } from '@material-ui/core/styles';


  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: "column",
    },
  }));

const JsonVariableFields = ({children, record, source, ...props}) => {
  const classes = useStyles();
  const jsonVariables = get(record, source)

  return jsonVariables && Array.isArray(jsonVariables) && jsonVariables.length > 0 ? (
    <div className={classes.root} >
    {jsonVariables.map((item, index) => {
             
      return cloneElement(Children.only(children), {
        ...props,
        key: `${source}[${index}]`,
        id: `${source}[${index}]`,
        record,
        source: `${source}[${index}]`,
      });
    })}
    </div>
  ) : null;
}
  
  
  export default JsonVariableFields;
  
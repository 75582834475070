import decodeJwt from 'jwt-decode'
import { getCookie, setCookie, deleteCookie } from '../utils';
import { AUTH_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE, DOMAIN_COOKIE } from '../appsettings';
import { tokenExpired } from './getAccessToken';
import login from './login';
import loginCoreUser from '../dataProvider/Core/loginCoreUser';
import banCoreUser from '../dataProvider/Core/banCoreUser';
import { ability, defineAbilityFor } from '../abilityProvider'
import getAbilityRules from '../abilityProvider/getAbilityRules';



export const authProvider = {
    login: async ({ username, password }) => {
        try {
            const { data: { login: { token, refreshToken, user } } } = await login({ login: username, password: password });

            setCookie(AUTH_TOKEN_COOKIE, token, { domain: DOMAIN_COOKIE });
            setCookie(REFRESH_TOKEN_COOKIE, refreshToken, { domain: DOMAIN_COOKIE });

            if (user && user.role === 'SERVICE') {
                throw new Error('role_SERVICE_not_allowed')
            }

            await loginCoreUser(user);

            const { data: { rules } } = await getAbilityRules();
            if (!rules || !rules.length) { return Promise.reject({ message: 'ra.auth.permissions_error' }) };
            ability.update([]);
            ability.update(defineAbilityFor(rules));

            return Promise.resolve();
        } catch (error) {
            deleteCookie(AUTH_TOKEN_COOKIE, { domain: DOMAIN_COOKIE });
            deleteCookie(REFRESH_TOKEN_COOKIE, { domain: DOMAIN_COOKIE });

            const extensions = error && error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].extensions
            const code = extensions && extensions.code
            const authId = extensions && extensions.id

            if (authId && code === "BANNED_USER") await banCoreUser({ where: { authId }, banned: true });

            throw error
        }
    },
    logout: (params) => {
        deleteCookie(AUTH_TOKEN_COOKIE, { domain: DOMAIN_COOKIE });
        deleteCookie(REFRESH_TOKEN_COOKIE, { domain: DOMAIN_COOKIE });
        return Promise.resolve();
    },
    checkError: (params) => {
        const status = params.status;
        if (status === 401 || status === 403) {
            deleteCookie(AUTH_TOKEN_COOKIE, { domain: DOMAIN_COOKIE });
            deleteCookie(REFRESH_TOKEN_COOKIE, { domain: DOMAIN_COOKIE });
            return Promise.reject()
        }
        return Promise.resolve();
    },
    checkAuth: (params) => {
        return ((getCookie(AUTH_TOKEN_COOKIE) && !tokenExpired(getCookie(REFRESH_TOKEN_COOKIE))))
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: async (params) => {

        const token = getCookie(AUTH_TOKEN_COOKIE);
        const decodedToken = decodeJwt(token);
        const role = decodedToken.role;

        try {
            const { data: { rules } } = await getAbilityRules();

            if (!rules || !rules.length) { return Promise.reject({ message: 'ra.auth.permissions_error' }) };

            ability.update([]);
            ability.update(defineAbilityFor(rules));

            return Promise.resolve({ ability, role });

        } catch (error) {

            return Promise.reject(error);
        }
    },
};
import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import UserMenu from './UserMenu';
import UserApps from './UserApps';



const useStyles = makeStyles(theme => ({
    appbar:{
        boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.06), 0px 6px 10px 0px rgba(0, 0, 0, 0.042), 0px 1px 18px 0px rgba(0, 0, 0, 0.036)",
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
}));


const MyAppBar = ({ ...props }) => {
    const classes = useStyles();
    
    return (
        <AppBar color="primary" {...props} userMenu={<UserMenu />} className={classes.appbar}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <UserApps />
        </AppBar>
    );

}

export default MyAppBar;
import React, { useState, useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import {
    useNotify,
    Button,
    fetchStart,
    fetchEnd,
    useRefresh,
} from 'react-admin';
import writeTag from "../../dataProvider/Databus/writeTag";









const TCPConnectButton = ({
    basePath,
    classes: classesOverride,
    className,
    record,
    resource,
    ...rest }) => {

    const dispatch = useDispatch();
    const refresh = useRefresh();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    const handleRunNow = useCallback(() => {

        setLoading(true);
        dispatch(fetchStart());

        writeTag({
            writeTag: {
                id: { id: 7005, ns: "t", serial: record.dbId },
                value: "5005",
            }
        })
            .then((result) => {

                notify('ra.notification.value_is_written', 'info', { smart_count: 1 });
                refresh();
            })
            .catch((error) => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd());
            });

    }, [setLoading, notify, refresh, fetchStart, fetchEnd, record])


    return (
        <Button
            onClick={handleRunNow}
            label='ra.action.tcp_connect'
            disabled={loading}
            key="button"
            className={className}
        >
            <SettingsInputAntennaIcon />
        </Button>
    );
};

export default TCPConnectButton;
import { ListItemText, MenuItem, makeStyles } from '@material-ui/core';

import React from 'react';
import StatusChip from "./GasMeterStatusChip";
import classnames from 'classnames';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

const useStyles = makeStyles(theme => ({
    root: {},
    selected: { fontWeight: 500 },
    highlight: {
        fontWeight: 500,
        color: "#000"
    },
    title: {},
    ml1: { marginLeft: 8 },
}),
    { name: 'RaAutocompleteSuggestionItem' }
);

const Suggestion = ({
    suggestion,
    index,
    highlightedIndex,
    isSelected,
    filterValue,
    classes: classesOverride,
    getSuggestionText,
    ...props
}) => {
    const classes = useStyles({ classes: classesOverride });
    const suggestionText = getSuggestionText(suggestion);
    if (!suggestion.id) return <MenuItem key={suggestion.id} {...props}>{suggestionText}</MenuItem>;
    const isHighlighted = highlightedIndex === index;
    const matches = match(suggestionText, filterValue);
    const parts = parse(suggestionText, matches);


    return (
        <MenuItem
            key={suggestion.id}
            selected={isHighlighted}
            className={classnames(classes.root, {
                [classes.selected]: isSelected,
            })}
            {...props}
        >
            <ListItemText>
                {parts.map((part, index) => (<span key={index} className={(part.highlight) ? classes.highlight : ""}>{part.text}</span>))}
            </ListItemText>
            <StatusChip {...props} record={suggestion} className={classes.ml1} />
        </MenuItem>
    );
};

Suggestion.defaultProps = {
    resource: "GasMeter"
}

export default Suggestion;

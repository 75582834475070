import cronstrue from 'cronstrue/i18n';
import moment from 'moment';



export const formatBoolean = (value) => {
    if (value === 'true') return 'true';
    if (value === 'false') return 'false';
    return 'null';
}

export const formatNumber = (value, format) => {
    const defaultFormat = { maximumFractionDigits: 4 }
    var formatter = new Intl.NumberFormat("ru", format || defaultFormat);
    return formatter.format(Number(value));
}

export const formatDateTime = (value, format) => {
    const defaultFormat = 'L LT';
    const valueFormated = moment(value).format(format || defaultFormat);
    return valueFormated || "";
}

export const formatCron = (value) => {
    const cronHumanize = cronstrue.toString(value, {
        use24HourTimeFormat: true,
        verbose: true,
        locale: "ru",
    });
    return cronHumanize
}

export const formatEnum = (value, enums) => {
    if (enums) {
        const valueFormated = enums[value];
        return valueFormated ? valueFormated : value;
    }
    return value;
}

export const getFinalType = (type, format) => {
    const finalType = (format === "CRON" || format === "ENUM") ? format : type
    return finalType
}

export const formatValue = (value, type, format, enums, translate) => {

    if (!value) return value;

    switch (type) {
        case "BOOL":
            return translate(`ra.boolean.${formatBoolean(value)}`);
        case "FLOAT":
        case "DOUBLE":
        case "BYTE":
        case "INT":
        case "INT32":
        case "INT64":
        case "UINT":
        case "UINT32":
        case "UINT64":
            return formatNumber(value, format);
        case "DATETIME":
            return formatDateTime(value, format);
        case "STRING":
            return value
        case "CRON":
            return formatCron(value);
        case "ENUM":
            return formatEnum(value, enums)
        default:
            return value;
    }
}




export const parseBoolean = () => (value) => {
    if (value === 'true') return 'true';
    if (value === 'false') return 'false';
    return 'null';
}

export const parseDateTime = (parse) => (value) => {
    return moment(value).utc().format(parse)
}

export const getChoices = (enums) => {
    const choices = Object.keys(enums).map(key => {
        const value = enums[key];
        return { id: key, name: value }
    });
    return choices
}

export const getInputProps = (type, format, parse, enums) => {

    switch (type) {
        case "BOOL":
            return { format: formatBoolean, parse: parseBoolean() }
        case "FLOAT":
        case "DOUBLE":
        case "BYTE":
        case "INT":
        case "INT32":
        case "INT64":
        case "UINT":
        case "UINT32":
        case "UINT64":
            return {}
        case "DATETIME":
            return { parse: parseDateTime(parse) }
        case "STRING":
            return {}
        case "CRON":
            return {}
        case "ENUM":
            return { choices: getChoices(enums) }
        default:
            return {}
    }
}
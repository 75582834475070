import { buildQuery } from 'ra-data-graphql-simple';
import { GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, DELETE, DELETE_MANY, UPDATE, UPDATE_MANY } from 'ra-core';
import { GET_LIST_GAS_METER, GET_MANY_GAS_METERS, GET_ONE_GAS_METER, UPDATE_GAS_METER, DELETE_GAS_METER } from './typeDefs';
import { GET_ONE_GAS_METER_CONFIGURATION, GET_LIST_GAS_METER_CONFIGURATION, UPDATE_GAS_METER_CONFIGURATION, DELETE_GAS_METER_CONFIGURATION } from './typeDefs';
import { GET_LIST_CONTROLLER, GET_MANY_CONTROLLERS, GET_ONE_CONTROLLER, UPDATE_CONTROLLER, DELETE_CONTROLLER } from './typeDefs';
import { GET_ONE_CONTROLLER_CONFIGURATION, GET_LIST_CONTROLLER_CONFIGURATION, UPDATE_CONTROLLER_CONFIGURATION, DELETE_CONTROLLER_CONFIGURATION } from './typeDefs';
import { GET_LIST_GAS_METER_UNIT, GET_MANY_GAS_METER_UNITS } from './typeDefs';
import { GET_LIST_USER, GET_MANY_USERS, GET_ONE_USER, UPDATE_USER, DELETE_USER } from './typeDefs';
import { UPDATE_PROFILE } from './typeDefs';
import { GET_LIST_HISTORY, GET_MANY_HISTORIES, GET_ONE_HISTORY } from './typeDefs';
import { GET_LIST_TRASHITEM, GET_MANY_TRASHITEMS, GET_ONE_TRASHITEM } from './typeDefs';
import { GET_LIST_DEVICES, GET_ONE_DEVICE, GET_MANY_DEVICES, UPDATE_DEVICE } from "./typeDefs";
import { GET_PREFERENCES, SET_PREFERENCES } from './typeDefs';




const customBuildQuery = introspection => (fetchType, resource, params) => {



    const { id, data, previousData } = params;

    if (resource === 'GasMeter') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_METER,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_METER,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_METERS,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_METER,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_METER,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'Device') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_DEVICE,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_DEVICES,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_DEVICES,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_DEVICE,
                    variables: { ...data },
                };
                break;
            }
            // case DELETE:
            //     return {
            //         ...builtQuery,
            //         query: DELETE_GAS_METER,
            //         variables: { where: { id }},
            //     };
            //     break;
            // default: break;
        }
    };

    if (resource === 'GasMeterConfiguration') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_GAS_METER_CONFIGURATION,
                    variables: { where: { id } },
                };
                break;
            }
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_METER_CONFIGURATION,
                };
                break;
            }
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_GAS_METER_CONFIGURATION,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_GAS_METER_CONFIGURATION,
                    variables: { where: { id } },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'Controller') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_CONTROLLER,
                    variables: { where: { id } },
                };
                break;
            }

            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_CONTROLLER,
                };
                break;
            }
            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_CONTROLLERS,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }

            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_CONTROLLER,
                    variables: { where: { id }, ...data },
                };
                break;
            }

            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_CONTROLLER,
                    variables: { where: { id } },
                };
                break;
            }

            default: break;
        }
    };

    if (resource === 'ControllerConfiguration') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_CONTROLLER_CONFIGURATION,
                    variables: { where: { id } },
                };
                break;
            }

            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_CONTROLLER_CONFIGURATION,
                };
                break;
            }

            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_CONTROLLER_CONFIGURATION,
                    variables: { where: { id }, ...data },
                };
                break;
            }

            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_CONTROLLER_CONFIGURATION,
                    variables: { where: { id } },
                };
                break;
            }

            default: break;
        }
    };

    if (resource === 'GasMeterUnit') {
        switch (fetchType) {
            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_GAS_METER_UNIT,
                };
                break;
            }

            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_GAS_METER_UNITS,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }

            default: break;
        }
    };

    if (resource === 'Preference') {
        switch (fetchType) {
            case "getPreferences": {

                return {
                    query: GET_PREFERENCES,
                    variables: { ...params },
                    parseResponse: (({ data }) => {

                        return { data }
                    }),
                };
                break;
            }
            case "setPreferences": {
                return {
                    query: SET_PREFERENCES,
                    variables: { ...params },
                    parseResponse: (({ data }) => {
                        return { data }
                    }),
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'User') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_USER,
                    variables: { where: { id } },
                };
                break;
            }

            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_USER,
                };
                break;
            }

            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_USERS,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }

            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_USER,
                    variables: { where: { id }, ...data },
                };
                break;
            }

            case DELETE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: DELETE_USER,
                    variables: { where: { id } },
                };
                break;
            }

            default: break;
        }
    };

    if (resource === 'Profile') {
        switch (fetchType) {
            case UPDATE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: UPDATE_PROFILE,
                    variables: { where: { id }, ...data },
                };
                break;
            }
            default: break;
        }
    };

    if (resource === 'History') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_HISTORY,
                };
                break;
            }

            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_HISTORY,
                };
                break;
            }

            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_HISTORIES,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }

            default: break;
        }
    };

    if (resource === 'TrashItem') {
        switch (fetchType) {
            case GET_ONE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_ONE_TRASHITEM,
                };
                break;
            }

            case GET_LIST:
            case GET_MANY_REFERENCE: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_LIST_TRASHITEM,
                };
                break;
            }

            case GET_MANY: {
                const builtQuery = buildQuery(introspection)(fetchType, resource, params);
                return {
                    ...builtQuery,
                    query: GET_MANY_TRASHITEMS,
                    variables: {
                        filter: {
                            ids: params.ids.map(item => {
                                if (typeof item === 'string') return item;
                                return item.id
                            })
                        },
                    },
                };
                break;
            }

            default: break;
        }
    };

    const builtQuery = buildQuery(introspection)(fetchType, resource, params);
    return builtQuery;
};





export default customBuildQuery;

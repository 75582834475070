import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import sanitizeRestProps from '../Fields/sanitizeRestProps';


const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.link.main,
    },
}));

const stopPropagation = e => e.stopPropagation();

const NestedField = ({
    className,
    classes: classesOverride,
    basePath,
    record,
    resource,
    source,
    link,
    ...props 
}) =>{
    const classes = useStyles({ classes: classesOverride });

    const reference = get(record, "resource");
    const referenceId = get(record, "item");
    const title = get(record, source);
    const rootPath = basePath.replace(resource, reference);
    const resourceLinkPath = `${rootPath}/${encodeURIComponent(referenceId)}/${link}`;

    return (
        <Link to={resourceLinkPath} onClick={stopPropagation} >
            <Typography component="span" variant="body2" className={`${className} ${classes.link}`} {...sanitizeRestProps(props)}>{title}</Typography>
        </Link>
    );
}

NestedField.defaultProps = {
    classes: {},
    link: 'show',
    addLabel: true,
};


export default NestedField;

import { SaveButton, SimpleForm, TextInput, Toolbar, maxLength, required } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import EnumInput from '../Inputs/EnumInput';
import JsonInput from '../Inputs/JsonInput';
import React from 'react';
import RemoveButton from '../Buttons/RemoveButton';
import TitleForRecord from '../Layout/TitleForRecord';
import { ability } from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
}));

const GasMeterConfigurationToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {ability.can("update", "GasMeterConfiguration") && <SaveButton />}
            {ability.can("update", "GasMeterConfiguration") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {ability.can("delete", "GasMeterConfiguration") && <RemoveButton redirect="list" />}
        </Toolbar>
    );
};

const GasMeterConfigurationEdit = props => {

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <SimpleForm toolbar={<GasMeterConfigurationToolbar />} >
                {ability.can('update', 'GasMeterConfiguration', 'status') && <EnumInput source="status" Enum="ConfigurationStatus" />}
                {ability.can('update', 'GasMeterConfiguration', 'name') && <TextInput source="name" fullWidth validate={required()} />}
                {ability.can('update', 'GasMeterConfiguration', 'description') && <TextInput source="description" fullWidth />}
                {ability.can('update', 'GasMeterConfiguration', 'type') && <EnumInput source="type" Enum="GasMeterType" option="resources.GasMeterType.enums.type" validate={required()} />}
                {ability.can('update', 'GasMeterConfiguration', 'ns') && <TextInput source="ns" validate={maxLength(1)} />}
                {ability.can('update', 'GasMeterConfiguration', 'parameterSet') && <JsonInput source="parameterSet" fullWidth />}
                {ability.can('update', 'GasMeterConfiguration', 'functionalGroupSet') && <JsonInput source="functionalGroupSet" fullWidth />}
                {ability.can('update', 'GasMeterConfiguration', 'protocolSet') && <JsonInput source="protocolSet" fullWidth />}
                {ability.can('update', 'GasMeterConfiguration', 'schedulerSet') && <JsonInput source="schedulerSet" fullWidth />}
                {ability.can('update', 'GasMeterConfiguration', 'converterSet') && <JsonInput source="converterSet" fullWidth />}
                {ability.can('update', 'GasMeterConfiguration', 'scadaSet') && <JsonInput source="scadaSet" fullWidth />}
                {ability.can('update', 'GasMeterConfiguration', 'metaTemplate') && <JsonInput source="metaTemplate" fullWidth />}
            </SimpleForm>
        </Edit>
    );
};

export default GasMeterConfigurationEdit
import React from 'react';
import { useForm } from 'react-final-form';
import EnumInput from '../Inputs/EnumInput'


const TypeInput = (props) => {
    const form = useForm();

    const handleChangeType = (value) => {
        form.change('configuration', null)
    }

    return (
        <EnumInput {...props} onChange={handleChangeType} />
    );
};

export default TypeInput;
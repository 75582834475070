import React, { useCallback } from 'react';
import { fetchEnd, fetchStart, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ConnectTagInput from "./ConnectTagInput";
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import arrayMutators from 'final-form-arrays';
import { useDispatch } from 'react-redux';
import writeTag from "../../dataProvider/Databus/writeTag";


const ConnectDialogWriteTag = ({ node, handleClose, ...props }) => {

    const dispatch = useDispatch();
    const notify = useNotify();

    const handleSubmit = useCallback((values) => {

        dispatch(fetchStart());
        const { id, ns, serial } = node;
        const { value } = values;

        const writeTagInput = {
            id: { id, ns, serial },
            value: value.toString(),
        }

        writeTag({ writeTag: writeTagInput })
            .then((result) => {

                notify('ra.notification.value_is_written', 'info', { smart_count: 1 });
            })
            .catch((error) => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                dispatch(fetchEnd());
            });
        handleClose();
    }, [dispatch, handleClose, node, notify]);

    if (!node) return null;


    const { value, name, unit, ns, serial, id, alias } = node;


    const defaultSubscription = {
        submitting: true,
        pristine: true,
        valid: true,
        invalid: true,
    };

    return (

        <>
            <DialogTitle id="form-dialog-title">Запись значения</DialogTitle>
            <Box padding="0 24px">
                <Typography variant="body1" >{`${name}${unit ? ` ( ${unit} )` : ""}`}</Typography>
                <Typography variant="caption" color="textSecondary" gutterBottom>{`${alias}: ${JSON.stringify({ ns, serial, id })}`}</Typography>
            </Box>
            <Form
                initialValues={{ value }}
                onSubmit={handleSubmit}
                mutators={{ ...arrayMutators }}
                subscription={defaultSubscription}
                // key={version} // support for refresh button
                keepDirtyOnReinitialize
                render={formProps => (
                    <form>
                        <Box padding="0 24px">
                            <ConnectTagInput node={node} resource="Device" source="value" fullWidth />
                        </Box>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                Отмена
                    </Button>
                            <Button onClick={formProps.handleSubmit} color="primary">
                                Записать
                    </Button>
                        </DialogActions>
                    </form>
                )}
            />
        </>
    )
};


ConnectDialogWriteTag.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        ns: PropTypes.string.isRequired,
        serial: PropTypes.number.isRequired,
        access: PropTypes.string,
        unit: PropTypes.string,
        type: PropTypes.string,
        isRead: PropTypes.bool,
        isWrite: PropTypes.bool,
        isHistory: PropTypes.bool,
    }),
};

export default React.memo(ConnectDialogWriteTag);
import React, { cloneElement } from 'react';
import { TextInput, NumberInput, /*DateTimeInput,*/ SelectInput } from 'react-admin';
import DateTimeInput from '../Inputs/DateTimeInput';
import BooleanInput from '../Inputs/BooleanInput';
import CronInput from '../Inputs/CronInput';
import { getFinalType, getInputProps } from './connectUtils';


const getInput = (node) => {

    const { type, format, parse, enums } = node
    const finalType = getFinalType(type, format)
    const inputProps = getInputProps(finalType, format, parse, enums)

    switch (finalType) {
        case "BOOL":
            return <BooleanInput {...inputProps} />
        case "FLOAT":
        case "DOUBLE":
        case "BYTE":
        case "INT":
        case "INT32":
        case "INT64":
        case "UINT":
        case "UINT32":
        case "UINT64":
            return <NumberInput {...inputProps} />
        case "DATETIME":
            return <DateTimeInput {...inputProps} />
        case "STRING":
            return <TextInput  {...inputProps} />
        case "CRON":
            return <CronInput {...inputProps} />
        case "ENUM":
            return <SelectInput {...inputProps} />
        default:
            return <TextInput />
    }
}


const ConnectTagInput = ({ node, ...props }) => {
    const Input = getInput(node)

    return (
        cloneElement(Input, {
            ...props
        })
    )
};




export default ConnectTagInput;
import { CREATE, GET_ONE, GET_LIST, GET_MANY, DELETE, DELETE_MANY, UPDATE, UPDATE_MANY } from 'ra-core';
import createClient from '../../authPrivider/createClient';
import updateClient from '../../authPrivider/updateClient';



// eslint-disable-next-line import/no-anonymous-default-export
export default async (type, resource, params) => {

    const { data, previousData } = params;

    if (resource === 'GasMeter') {
        switch (type) {
            case CREATE: {

                if (data.gasMeterUnit) { data.gasMeterUnit = (data.gasMeterUnit.id) ? { id: data.gasMeterUnit.id } : null }
                if (data.configuration) { data.configuration = (data.configuration.id) ? { id: data.configuration.id } : null }
                if (data.controller) { data.controller = (data.controller.id) ? { id: data.controller.id } : null }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasMeterUnit) { data.gasMeterUnit = (data.gasMeterUnit.id || previousData.gasMeterUnit) ? { id: data.gasMeterUnit.id } : null }
                if (data.configuration) { data.configuration = (data.configuration.id || previousData.configuration) ? { id: data.configuration.id } : null }
                if (data.controller) { data.controller = (data.controller.id || previousData.controller) ? { id: data.controller.id } : null }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'Controller') {
        switch (type) {
            case CREATE: {
                if (data.gasMeterUnitsIds) { data.gasMeterUnits = data.gasMeterUnitsIds.map(id => ({ id })) }
                if (data.configuration) { data.configuration = (data.configuration.id) ? { id: data.configuration.id } : null }
                if (data.gasMetersIds) { data.gasMeters = data.gasMetersIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            case UPDATE: {
                if (data.gasMeterUnitsIds) { data.gasMeterUnits = data.gasMeterUnitsIds.map(id => ({ id })) }
                if (data.configuration) { data.configuration = (data.configuration.id || previousData.configuration) ? { id: data.configuration.id } : null }
                if (data.gasMetersIds) { data.gasMeters = data.gasMetersIds.map(id => ({ id })) }
                if (data.tagsIds) { data.tags = data.tagsIds.map(id => ({ id })) }
                break;
            }
            default: break;
        }
    }

    if (resource === 'User') {
        switch (type) {
            case CREATE: {

                if (data.counterparty) { data.counterparty = (data.counterparty.id) ? { id: data.counterparty.id } : null }
                if (data.accessIds) { data.access = data.accessResource ? data.accessIds.map(id => ({ id, resource: data.accessResource })) : [] }
                if (!data.password) { break }; //если нет password, то создаем пользователя только в core

                const { data: { createClient: auth } } = await createClient({
                    login: data.login ? data.login : null,
                    email: data.email ? data.email : null,
                    phone: data.phone ? data.phone : null,
                    name: data.name,
                    password: data.password,
                    role: data.role,
                });

                params.data = {
                    ...data,
                    authId: auth.id,
                    login: auth.login ? auth.login : null,
                    email: auth.email ? auth.email : null,
                    phone: auth.phone ? auth.phone : null,
                    name: auth.name,
                    role: auth.role,
                }

                break;
            }
            case UPDATE: {

                if (data.counterparty) { data.counterparty = (data.counterparty.id || previousData.counterparty) ? { id: data.counterparty.id } : null }
                if (data.accessIds) { data.access = data.accessResource ? data.accessIds.map(id => ({ id, resource: data.accessResource })) : [] }
                const authId = data.authId;
                const password = data.password;

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createClient({
                        login: data.login ? data.login : null,
                        email: data.email ? data.email : null,
                        phone: data.phone ? data.phone : null,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                    })

                    params.data = {
                        ...data,
                        authId: auth.id,
                        login: auth.login ? auth.login : null,
                        email: auth.email ? auth.email : null,
                        phone: auth.phone ? auth.phone : null,
                        name: auth.name,
                        role: auth.role,
                    }
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateClient({
                        id: data.authId,
                        login: data.login ? data.login : null,
                        email: data.email ? data.email : null,
                        phone: data.phone ? data.phone : null,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                        banned: data.banned,
                    })

                    params.data = {
                        ...data,
                        authId: auth.id,
                        login: auth.login ? auth.login : null,
                        email: auth.email ? auth.email : null,
                        phone: auth.phone ? auth.phone : null,
                        name: auth.name,
                        role: auth.role,
                    }
                };



                break;
            }
            default: break;
        }
    }

    if (resource === 'Profile') {
        switch (type) {
            case UPDATE: {
                const authId = data.authId;
                const password = data.password;
                let authUser = {};

                //если нет authId и пароля, то обновляем пользователя только в core
                if (!authId && !password) { break };

                if (!authId && password) {
                    const { data: { createClient: auth } } = await createClient({
                        login: data.login ? data.login : null,
                        email: data.email ? data.email : null,
                        phone: data.phone ? data.phone : null,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                    })

                    authUser = auth;
                };

                if (authId) {
                    const { data: { updateClient: auth } } = await updateClient({
                        id: data.authId,
                        login: data.login ? data.login : null,
                        email: data.email ? data.email : null,
                        phone: data.phone ? data.phone : null,
                        name: data.name,
                        password: data.password,
                        role: data.role,
                    })

                    authUser = auth;
                };

                params.data = {
                    ...data,
                    authId: authUser.id,
                    login: authUser.login ? authUser.login : null,
                    email: authUser.email ? authUser.email : null,
                    phone: authUser.phone ? authUser.phone : null,
                    name: authUser.name,
                    role: authUser.role,
                }

                break;
            }
            default: break;
        }
    }

    return { type, resource, params };
};
import React from 'react';
import { useFormState } from 'react-final-form';
import { AutocompleteInput, ReferenceInput } from 'react-admin';



const matchTrue = () => true;

const ReferenceGasMeterUnitInput = (props) => {
    const { values } = useFormState();
    const status = values.status
    if (!["TEST", "ACTIVE"].includes(status)) return null

    return (
        <ReferenceInput {...props} >
            <AutocompleteInput optionText="name" matchSuggestion={matchTrue}/>
        </ReferenceInput>
    );
};

export default ReferenceGasMeterUnitInput;
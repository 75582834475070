import gql from 'graphql-tag';

const EMBEDDED_SIMPLE_TYPE = `{
    id
    name
    description
}`

const EMBEDDED_CONFIGURATION_TYPE = `{
    id
    name
    description
    status
}`

const EMBEDDED_USER_TYPE = `{
    id
    name
    email
    phone
}`

const EMBEDDED_TAG_TYPE = `{
    id
    name
    description
    color
    resource
}`


const GAS_METER_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    tags ${EMBEDDED_TAG_TYPE}
    dbId
    serialNumber
    port
    meta
    type
    status
    configuration ${EMBEDDED_CONFIGURATION_TYPE}
    gasMeterUnit ${EMBEDDED_SIMPLE_TYPE}
    controller ${EMBEDDED_SIMPLE_TYPE}
    timeZone
    commercialHour
    dst
    xmlArchiveLink
}`

export const GET_LIST_GAS_METER = gql`
    query allGasMeters($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasMeterFilter) {
    items: allGasMeters(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_METER_TEMPLATE}
    total: _allGasMetersMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_METERS = gql`
    query allGasMeters($filter: GasMeterFilter) {
    items: allGasMeters(filter: $filter) ${GAS_METER_TEMPLATE}
    total: _allGasMetersMeta(filter: $filter) { count }
  }
`

export const GET_ONE_GAS_METER = gql`
    query GasMeter( $where: GasMeterWhereUniqueInput! ) {
        data: GasMeter( where: $where ) ${GAS_METER_TEMPLATE}
    }
`

export const UPDATE_GAS_METER = gql`
    mutation updateGasMeter( $where: GasMeterWhereUniqueInput!, $name: String, $description: String, $tags: [TagWhereUniqueInput], $dbId: Int, $serialNumber: String, $port: Int, $meta: Json, $type: GasMeterType, $status: GasMeterStatus, $configuration: GasMeterConfigurationWhereUniqueInput, $gasMeterUnit: GasMeterUnitWhereUniqueInput, $controller: ControllerWhereUniqueInput, $timeZone: Int, $commercialHour: Int, $dst: Boolean! ) {
        data: updateGasMeter( where: $where, name: $name, description: $description, tags: $tags, dbId: $dbId, serialNumber:  $serialNumber, port: $port, meta: $meta, type: $type, status: $status, configuration: $configuration, gasMeterUnit: $gasMeterUnit, controller: $controller, timeZone: $timeZone, commercialHour:  $commercialHour, dst: $dst ) ${GAS_METER_TEMPLATE}
    }
`

export const DELETE_GAS_METER = gql`
    mutation deleteGasMeter( $where: GasMeterWhereUniqueInput! ) {
        data: deleteGasMeter( where: $where ) { id }
    }
`

const GAS_METER_CONFIGURATION_TEMPLATE = `{
    id
    name
    description
    type
    status
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    ns
    parameterSet
    functionalGroupSet
    protocolSet
    schedulerSet
    converterSet
    scadaSet
    metaTemplate
}`

export const GET_ONE_GAS_METER_CONFIGURATION = gql`
    query GasMeterConfiguration( $where: GasMeterConfigurationWhereUniqueInput! ) {
        data: GasMeterConfiguration( where: $where ) ${GAS_METER_CONFIGURATION_TEMPLATE}
    }
`

export const GET_LIST_GAS_METER_CONFIGURATION = gql`
    query allGasMeterConfigurations($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasMeterConfigurationFilter) {
    items: allGasMeterConfigurations(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${GAS_METER_CONFIGURATION_TEMPLATE}
    total: _allGasMeterConfigurationsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const UPDATE_GAS_METER_CONFIGURATION = gql`
    mutation updateGasMeterConfiguration( $where: GasMeterConfigurationWhereUniqueInput!, $name: String, $description: String, $status: String, $type: GasMeterType, $ns: String, $parameterSet: Json, $functionalGroupSet: Json, $protocolSet: Json, $schedulerSet: Json, $converterSet: Json, $scadaSet: Json, $metaTemplate: Json ) {
        data: updateGasMeterConfiguration(where: $where, name: $name, description: $description, status: $status, type: $type, ns: $ns, parameterSet: $parameterSet, functionalGroupSet: $functionalGroupSet, protocolSet: $protocolSet, schedulerSet: $schedulerSet, converterSet: $converterSet, scadaSet: $scadaSet, metaTemplate: $metaTemplate) ${GAS_METER_CONFIGURATION_TEMPLATE}
    }
`

export const DELETE_GAS_METER_CONFIGURATION = gql`
    mutation deleteGasMeterConfiguration( $where: GasMeterConfigurationWhereUniqueInput! ) {
        data: deleteGasMeterConfiguration( where: $where ) { id }
    }
`

const CONTROLLER_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    tags ${EMBEDDED_TAG_TYPE}
    serialNumber
    phoneNumber
    url
    type
    status
    configuration {
        id
        name
        description
        status
        mode
    }
    gasMeters ${EMBEDDED_SIMPLE_TYPE}
    gasMeterUnits ${EMBEDDED_SIMPLE_TYPE}
    meta
}`

export const GET_LIST_CONTROLLER = gql`
    query allControllers($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: ControllerFilter) {
    items: allControllers(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${CONTROLLER_TEMPLATE}
    total: _allControllersMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_CONTROLLERS = gql`
    query allControllers($filter: ControllerFilter) {
    items: allControllers(filter: $filter) ${CONTROLLER_TEMPLATE}
    total: _allControllersMeta(filter: $filter) { count }
  }
`

export const GET_ONE_CONTROLLER = gql`
    query Controller( $where: ControllerWhereUniqueInput! ) {
        data: Controller( where: $where ) ${CONTROLLER_TEMPLATE}
    }
`

export const UPDATE_CONTROLLER = gql`
    mutation updateController( $where: ControllerWhereUniqueInput!, $name: String, $description: String, $status: ControllerStatus, $type: ControllerType, $serialNumber: String, $phoneNumber: String, $url: String, $configuration: ControllerConfigurationWhereUniqueInput, $gasMeterUnits: [GasMeterUnitWhereUniqueInput!], $gasMeters: [GasMeterWhereUniqueInput!], $tags: [TagWhereUniqueInput!], $meta: Json ) {
        data: updateController( where: $where, name: $name, description: $description, status: $status, type: $type, serialNumber: $serialNumber, phoneNumber: $phoneNumber, url: $url, configuration: $configuration, gasMeterUnits: $gasMeterUnits, gasMeters: $gasMeters, tags: $tags, meta: $meta ) ${CONTROLLER_TEMPLATE}
    }
`

export const DELETE_CONTROLLER = gql`
    mutation deleteController( $where: ControllerWhereUniqueInput! ) {
        data: deleteController( where: $where ) { id }
    }
`

const CONTROLLER_CONFIGURATION_TEMPLATE = `{
    id
    name
    description
    type
    mode
    status
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    ns
    parameterSet
    functionalGroupSet
    protocolSet
    schedulerSet
    converterSet
    scadaSet
    metaTemplate
}`

export const GET_ONE_CONTROLLER_CONFIGURATION = gql`
    query ControllerConfiguration( $where: ControllerConfigurationWhereUniqueInput! ) {
        data: ControllerConfiguration( where: $where ) ${CONTROLLER_CONFIGURATION_TEMPLATE}
    }
`

export const GET_LIST_CONTROLLER_CONFIGURATION = gql`
    query allControllerConfigurations($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: ControllerConfigurationFilter) {
    items: allControllerConfigurations(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${CONTROLLER_CONFIGURATION_TEMPLATE}
    total: _allControllerConfigurationsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const UPDATE_CONTROLLER_CONFIGURATION = gql`
    mutation updateControllerConfiguration( $where: ControllerConfigurationWhereUniqueInput!, $name: String, $description: String, $status: String, $mode: ControllerMode $type: ControllerType, $ns: String, $parameterSet: Json, $functionalGroupSet: Json, $protocolSet: Json, $schedulerSet: Json, $converterSet: Json, $scadaSet: Json, $metaTemplate: Json ) {
        data: updateControllerConfiguration(where: $where, name: $name, description: $description, status: $status, mode: $mode, type: $type, ns: $ns, parameterSet: $parameterSet, functionalGroupSet: $functionalGroupSet, protocolSet: $protocolSet, schedulerSet: $schedulerSet, converterSet: $converterSet, scadaSet: $scadaSet, metaTemplate: $metaTemplate) ${CONTROLLER_CONFIGURATION_TEMPLATE}
    }
`

export const DELETE_CONTROLLER_CONFIGURATION = gql`
    mutation deleteControllerConfiguration( $where: ControllerConfigurationWhereUniqueInput! ) {
        data: deleteControllerConfiguration( where: $where ) { id }
    }
`

export const GET_LIST_GAS_METER_UNIT = gql`
    query allGasMeterUnits($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: GasMeterUnitFilter) {
    items: allGasMeterUnits(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${EMBEDDED_SIMPLE_TYPE}
    total: _allGasMeterUnitsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_GAS_METER_UNITS = gql`
    query allGasMeterUnits($filter: GasMeterUnitFilter) {
    items: allGasMeterUnits(filter: $filter) ${EMBEDDED_SIMPLE_TYPE}
    total: _allGasMeterUnitsMeta(filter: $filter) { count }
  }
`

const DEVICE_TEMPLATE = `{
    id
    name
    description
    updatedAt
    aisId
    dbId
    url
    serialNumber
    type
    mode
    status
    meta
    gasMeterId
    controllerId
    xmlArchiveLink
}`

export const GET_ONE_DEVICE = gql`
    query Device( $where: DeviceWhereUniqueInput! ) {
        data: Device( where: $where ) ${DEVICE_TEMPLATE}
    }
`

export const GET_LIST_DEVICES = gql`
    query allDevices($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: DeviceFilter) {
        items: allDevices(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${DEVICE_TEMPLATE}
        total: _allDevicesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
    }
`

export const GET_MANY_DEVICES = gql`
    query allDevices($filter: DeviceFilter) {
        items: allDevices(filter: $filter) ${DEVICE_TEMPLATE}
        total: _allDevicesMeta(filter: $filter) { count }
    }
`

export const UPDATE_DEVICE = gql`
    mutation updateDevice( $id: ID!, $gasMeterId: ID!, $controllerId: ID! ) {
        data: updateDevice(id: $id, gasMeterId: $gasMeterId, controllerId: $controllerId) { id name description gasMeterId controllerId }
    }
`
//
// export const DELETE_DEVICE = gql`
//     mutation deleteControllerConfiguration( $where: ControllerConfigurationWhereUniqueInput! ) {
//         data: deleteControllerConfiguration( where: $where ) { id }
//     }
// `



const USER_TEMPLATE = `{
    id
    name
    description
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
    updatedAt
    deleted
    authId
    banned
    login
    email
    phone
    phoneContact
    role
    online
    counterparty ${EMBEDDED_SIMPLE_TYPE}
    position
}`

export const GET_LIST_USER = gql`
    query allUsers($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: UserFilter) {
    items: allUsers(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${USER_TEMPLATE}
    total: _allUsersMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_USERS = gql`
    query allUsers($filter: UserFilter) {
    items: allUsers(filter: $filter) ${USER_TEMPLATE}
    total: _allUsersMeta(filter: $filter) { count }
  }
`

export const GET_ONE_USER = gql`
    query User( $where: UserWhereUniqueInput! ) {
        data: User( where: $where ) ${USER_TEMPLATE}
    }
`

export const UPDATE_USER = gql`
    mutation updateUser( $where: UserWhereUniqueInput!, $name: String, $description: String, $authId: ID, $banned: Boolean, $login: String, $email: String, $phone: String, $phoneContact: String, $role: UserRole, $position: String ) {
        data: updateUser( where: $where, name: $name, description: $description, authId: $authId, banned: $banned, login: $login, email: $email, phone: $phone, phoneContact: $phoneContact, role: $role, position: $position ) ${USER_TEMPLATE}
    }
`

export const DELETE_USER = gql`
    mutation deleteUser( $where: UserWhereUniqueInput! ) {
        data: deleteUser( where: $where ) { id }
    }
`

export const UPDATE_PROFILE = gql`
    mutation updateProfile( $where: UserWhereUniqueInput!, $name: String, $description: String, $authId: ID, $login: String, $email: String, $phone: String, $phoneContact: String, $role: UserRole, $position: String) {
        data: updateProfile( where: $where, name: $name, description: $description, authId: $authId, login: $login, email: $email, phone: $phone, phoneContact: $phoneContact, role: $role, position: $position) ${USER_TEMPLATE}
    }
`

const HISTORY_TEMPLATE = `{
    id
    resource
    item
    name
    description
    action
    data
    createdAt
    createdBy ${EMBEDDED_USER_TYPE}
}`

export const GET_LIST_HISTORY = gql`
    query allHistories($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: HistoryFilter) {
    items: allHistories(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${HISTORY_TEMPLATE}
    total: _allHistoriesMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_HISTORIES = gql`
    query allHistories($filter: HistoryFilter) {
    items: allHistories(filter: $filter) ${HISTORY_TEMPLATE}
    total: _allHistoriesMeta(filter: $filter) { count }
  }
`

export const GET_ONE_HISTORY = gql`
    query History( $id: ID! ) {
        data: History( id: $id ) ${HISTORY_TEMPLATE}
    }
`



const TRASHITEM_TEMPLATE = `{
    id
    resource
    item
    name
    description
    deletedAt
    deletedBy ${EMBEDDED_USER_TYPE}
}`

export const GET_LIST_TRASHITEM = gql`
    query allTrashItems($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: TrashItemFilter) {
    items: allTrashItems(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) ${TRASHITEM_TEMPLATE}
    total: _allTrashItemsMeta(page: $page, perPage: $perPage, filter: $filter) { count }
  }
`

export const GET_MANY_TRASHITEMS = gql`
    query allTrashItems($filter: TrashItemFilter) {
    items: allTrashItems(filter: $filter) ${TRASHITEM_TEMPLATE}
    total: _allTrashItemsMeta(filter: $filter) { count }
  }
`

export const GET_ONE_TRASHITEM = gql`
    query TrashItem( $id: ID! ) {
        data: TrashItem( id: $id ) ${TRASHITEM_TEMPLATE}
    }
`

export const GET_PREFERENCES = gql`
    query getPreferences($key: String!) {
        preferences: getPreferences(key: $key) {
            id
            key
            value
        }
    }
`

export const SET_PREFERENCES = gql`
    mutation setPreferences($key: String!, $value: Json) {
        preferences: setPreferences(key: $key, value: $value) {
            id
            key
            value
        }
    }
`
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/ru';
import sanitizeRestProps from './sanitizeRestProps';

const githubTheme = {
    default: 'rgba(0, 0, 0, 0.87)',
    background: 'rgba(0, 0, 0, 0.04)',
    background_warning: '#fdd',
    string: '#d14',
    number: '#008080',
    colon: '#49B8F7',
    keys: 'rgba(0, 0, 0, 0.87)',
    keys_whiteSpace: '#835FB6',
    primitive: '#386FA4',
    error: "#e45649"
};


const JsonField = ({ record, source, theme, ...props }) => {
  const json = get(record, source);
  if (!json) return null;
  const jsObject = (typeof json === 'string') ? JSON.parse(json) : json;

  return (
    <JSONInput
      placeholder= {jsObject}
      viewOnly
      confirmGood={false}
      colors={{...theme, background: "none"}}
      locale={locale}
      style={{
        body: {
            fontSize: '1rem',
        },
        contentBox: {
            color: 'rgba(0, 0, 0, 0.87)',
        },
      }}
      height="360px"
      width= "100%"
      {...sanitizeRestProps(props)}
    />     
  )
}

JsonField.defaultProps = {
    theme: githubTheme,
    addLabel: true,
};

export default JsonField;

import React, { useState, useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import BuildIcon from '@material-ui/icons/Build';
import {
    useNotify,
    Button,
    fetchStart,
    fetchEnd,
} from 'react-admin';
import UninstallGasMeterUnitButtonDialog from "./UninstallGasMeterUnitButtonDialog";
import uninstallGasMeterUnit from "../../dataProvider/Core/uninstallGasMeterUnit";



const UninstallGasMeterUnitButton = ({ basePath, record, resource, className, redirect: redirectTo, ...props }) => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleUpload = useCallback(({ dateTo, reason }) => {
        setOpen(true);
        dispatch(fetchStart());
        uninstallGasMeterUnit({
            where: { id: record.id },
            input: { dateTo, reason }
        })
            .then(result => {
                setOpen(false);
                notify('ra.uninstallGasMeterUnit.uninstalled', 'info', { smart_count: 1 });
            })
            .catch((error) => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                dispatch(fetchEnd());
            });
    }, [record, dispatch]);


    return (
        <Fragment>
            <Button
                onClick={handleOpen}
                label='ra.uninstallGasMeterUnit.uninstall'
                className={className}
            >
                <BuildIcon />
            </Button>
            <UninstallGasMeterUnitButtonDialog
                open={open}
                onClose={handleClose}
                onUpload={handleUpload}
            />
        </Fragment>
    );
};

export default UninstallGasMeterUnitButton;
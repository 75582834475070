import React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import PreferenceContextProvider from '../../controller/preferences/PreferenceContextProvider';


export const MyLayout = props => {
    return (
        <PreferenceContextProvider>
            <Layout {...props} appBar={AppBar} menu={Menu} />
        </PreferenceContextProvider>
    );
};


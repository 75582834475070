import React, { Children, cloneElement } from 'react';
import ConnectContext from './ConnectContext';
import useTagController from "../../dataProvider/Databus/useTagController";

const ConnectLayout = ({ children, resource, basePath, record, version, ...props }) => {

    const tagControllerProps = useTagController({
        resource,
        basePath,
        record,
        version,
    });

    return (
        <ConnectContext.Provider value={tagControllerProps}>
             {cloneElement(Children.only(children), {
                resource,
                basePath,
                record,
                version,
            })}
        </ConnectContext.Provider>
    )
};

export default ConnectLayout;
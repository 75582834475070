import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { SimpleForm, SelectInput, TextInput, Toolbar, SaveButton, DeleteButton, required } from 'react-admin';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import CardBlankLayout from '../Layout/CardBlankLayout';
import TitleForRecord from '../Layout/TitleForRecord';
// import EnumInput from '../Inputs/EnumInput';
import ColorInput from '../Inputs/ColorInput';
import { ability } from "../../abilityProvider";



const resources = [
    { id: 'Controller', name: 'resources.Tag.enums.resource.Controller' },
    { id: 'GasMeter', name: 'resources.Tag.enums.resource.GasMeter' },
]

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
}));

const TagToolbar = props => {
    const classes = useStyles();
    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {ability.can('update', 'Tag',) && <SaveButton />}
            <ToolbarSpacer />
            {ability.can('delete', 'Tag',) && <DeleteButton undoable icon={<DeleteForeverIcon />} />}
        </Toolbar>
    );
}

const TagEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <CardBlankLayout >
                <SimpleForm toolbar={<TagToolbar />}>
                    {ability.can('update', 'Tag', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                    {ability.can('update', 'Tag', 'description') && <TextInput source="description" multiline fullWidth />}
                    {/* {ability.can('create', 'Tag', 'type') && <EnumInput source="type" Enum="TagType" validate={required()}/>} */}
                    {ability.can('update', 'Tag', 'resource') && <SelectInput source="resource" choices={resources} validate={required()} />}
                    {ability.can('update', 'Tag', 'color') && <ColorInput source="color" />}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default TagEdit
import React from 'react';
import { BooleanInput, Create, NumberInput, ReferenceArrayInput, SimpleForm, TextInput, required } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
// import EnumInput from '../Inputs/EnumInput';
import JsonInput from '../Inputs/JsonInput';
import JsonVariableInput from '../Inputs/JsonVariableInput';
import JsonVariableInputs from '../Inputs/JsonVariableInputs';
import ReferenceConfigurationInput from './ReferenceConfigurationInput';
// import ReferenceControllerInput from '../Controller/ReferenceControllerInput';
import TypeInput from './TypeInput';
import { ability } from "../../abilityProvider";
// import { makeStyles } from '@material-ui/core/styles';



const matchTrue = () => true;

// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
// }));

export const GasMeterQuickCreateForm = props => {
    return (
        <SimpleForm {...props}>
            {ability.can('create', 'GasMeter', 'name') && <TextInput source="name" validate={required()} fullWidth />}
            {ability.can('create', 'GasMeter', 'description') && <TextInput source="description" multiline fullWidth />}
            {ability.can('create', 'GasMeter', 'type') && <TypeInput source="type" Enum="GasMeterType" option="resources.GasMeterType.enums.type" validate={required()} />}
            {ability.can('create', 'GasMeter', 'configuration') && <ReferenceConfigurationInput source="configuration.id" reference="GasMeterConfiguration" label="resources.GasMeter.fields.configuration" allowEmpty emptyText="ra.message.no" />}
            {ability.can('update', 'GasMeter', 'dbId') && <NumberInput source="dbId" helperText="Если не указан, будет сгенерирован автоматически" />}
            {ability.can('create', 'GasMeter', 'serialNumber') && <TextInput source="serialNumber" />}
            {ability.can('create', 'GasMeter', 'port') && <NumberInput source="port" />}
            {ability.can('create', 'GasMeter', 'timeZone') && <NumberInput source="timeZone" step={1} />}
            {ability.can('create', 'GasMeter', 'commercialHour') && <NumberInput source="commercialHour" step={1} />}
            {ability.can('create', 'GasMeter', 'dst') && <BooleanInput source="dst" fullWidth />}
            {ability.can('create', 'GasMeter', 'metaVariable') && <JsonVariableInputs source="meta.metaVariable">
                <JsonVariableInput />
            </JsonVariableInputs>}
            {ability.can('create', 'GasMeter', 'meta') && <JsonInput source="meta" fullWidth />}
            {ability.can('create', 'GasMeter', 'tags') && <ReferenceArrayInput source="tagsIds" filter={{ resource: "GasMeter" }} reference="Tag" label="resources.GasMeter.fields.tags" fullWidth>
                <AutocompleteArrayInput />
            </ReferenceArrayInput>}
        </SimpleForm>
    )
};

const GasMeterCreate = props => {
    // const classes = useStyles();

    const gasMeterDefaultValue = {
        timeZone: 3,
        commercialHour: 10,
    }

    return (
        <Create {...props} >
            <SimpleForm redirect="show" initialValues={gasMeterDefaultValue} >
                {/*{ability.can('create', 'GasMeter', 'status') && <EnumInput source="status" Enum="GasMeterStatus" />}*/}
                {ability.can('create', 'GasMeter', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {ability.can('create', 'GasMeter', 'description') && <TextInput source="description" multiline fullWidth />}
                {/* {ability.can('create', 'GasMeter', 'gasMeterUnit') && <ReferenceInput source="gasMeterUnit.id" reference="GasMeterUnit" filter={{gasMeter: null}} label="resources.GasMeter.fields.gasMeterUnit" allowEmpty emptyText="ra.message.no" >
                    <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
                </ReferenceInput>} */}
                {ability.can('create', 'GasMeter', 'type') && <TypeInput source="type" Enum="GasMeterType" option="resources.GasMeterType.enums.type" validate={required()} />}
                {ability.can('create', 'GasMeter', 'configuration') && <ReferenceConfigurationInput source="configuration.id" reference="GasMeterConfiguration" label="resources.GasMeter.fields.configuration" allowEmpty emptyText="ra.message.no" />}
                {/* Убрал из-за путаницы с созданием Устройства. Теперь связь назначается только в Устройстве */}
                {/* {ability.can('create', 'GasMeter', 'controller') && <ReferenceControllerInput source="controller.id" reference="Controller" label="resources.GasMeter.fields.controller" allowEmpty emptyText="ra.message.no"/>} */}
                {ability.can('update', 'GasMeter', 'dbId') && <NumberInput source="dbId" helperText="Если не указан, будет сгенерирован автоматически" />}
                {ability.can('create', 'GasMeter', 'serialNumber') && <TextInput source="serialNumber" />}
                {ability.can('create', 'GasMeter', 'port') && <NumberInput source="port" />}
                {ability.can('create', 'GasMeter', 'timeZone') && <NumberInput source="timeZone" step={1} />}
                {ability.can('create', 'GasMeter', 'commercialHour') && <NumberInput source="commercialHour" step={1} />}
                {ability.can('create', 'GasMeter', 'dst') && <BooleanInput source="dst" fullWidth />}
                {ability.can('create', 'GasMeter', 'metaVariable') && <JsonVariableInputs source="meta.metaVariable">
                    <JsonVariableInput />
                </JsonVariableInputs>}
                {ability.can('create', 'GasMeter', 'meta') && <JsonInput source="meta" fullWidth />}
                {ability.can('create', 'GasMeter', 'tags') && <ReferenceArrayInput source="tagsIds" filter={{ resource: "GasMeter" }} reference="Tag" label="resources.GasMeter.fields.tags" fullWidth>
                    <AutocompleteArrayInput />
                </ReferenceArrayInput>}
            </SimpleForm>
        </Create>
    );
};

export default GasMeterCreate
import { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import apolloCoreClient from './apolloCoreClient';



const GET_ONE_GAS_METER = gql`
  query GasMeter( $where: GasMeterWhereUniqueInput! ) {
    data: GasMeter( where: $where ) {
      id
      name
      description
      dbId
      serialNumber
      type
      status
      configuration {
        id
        ns
        parameterSet
        functionalGroupSet
      }

    }
  }
`;

export default function(resource, id, version) {

  const [state, setState] = useState({
    data: undefined,
    error: undefined,
    loading: true,
  })

  useEffect(() => {

    if (!id) return;

    apolloCoreClient.query({
      query: GET_ONE_GAS_METER,
      variables: { where: {id} },
      fetchPolicy: 'network-only',
    })
    .then(({ data: {data} }) => {
      setState({
          data,
          loading: false,
          error: undefined,
      });
    })
    .catch(error => {
      setState({
          error,
          loading: false,
      });
    });
  }, [resource, id, version]);


  return state;
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, AutocompleteInput, BulkExportButton, Datagrid, DateField, Filter, ListView, ReferenceInput, TextField, useListController, ListContext, TextInput } from 'react-admin';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import SearchInput from '../Inputs/SearchInput';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import customExporter, { dateFormatter, enumFormatter } from '../../exporter';
import EnumField from '../Fields/EnumField';
import { ability } from "../../abilityProvider";
import TagArrayField from "../Tag/TagArrayField";
import TagChip from "../Tag/TagChip";
import MoreButton from "../Buttons/MoreButton";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";
import StatusChip from "./ControllerStatusChip";
import EnumInput from "../Inputs/EnumInput";
import IPInput from '../Inputs/IPInput';
import { PhoneFilterInput } from '../Inputs/PhoneInput';


const matchTrue = () => true;

const useFilterStyles = makeStyles(theme => ({
    mediumWidth: { width: theme.spacing(19) },
}));

const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const ControllerBulkActionButtons = props => (
    <>
        {ability.can('remove', 'Controller') && <BulkRemoveButton {...props} />}
        {ability.can('getList', 'Controller') && <BulkExportButton {...props} />}
    </>
);

const ControllerFilter = (props) => {
    const classes = useFilterStyles();
    return (
        <ManualSubmitFilter {...props}>
            <SearchInput source="q" alwaysOn resettable />
            {ability.can('getList', 'Controller', 'serialNumber') && <IPInput source="ip" alwaysOn resettable className={classes.mediumWidth} />}
            {ability.can('getList', 'Controller', 'device') && <ReferenceInput source="device_some.id" reference="Device" label="resources.GasMeter.fields.device" allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {ability.can('getList', 'Controller', 'gasMeterUnit') && <ReferenceInput source="gasMeterUnits_some.id" reference="GasMeterUnit" label="resources.Controller.fields.gasMeterUnits" allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {ability.can('getList', 'Controller', 'configuration') && <ReferenceInput source="configuration.id" reference="ControllerConfiguration" label="resources.Controller.fields.configuration" allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>}
            {ability.can('getList', 'Controller', 'serialNumber') && <PhoneFilterInput source="phoneNumber_contains" label="resources.Controller.fields.phoneNumber" />}
            {ability.can('getList', 'Controller', 'serialNumber') && <TextInput source="serialNumber" />}
            {ability.can('getList', 'Controller', 'type') && <EnumInput source="type" Enum="ControllerType" option="resources.ControllerType.enums.type" />}
            {ability.can('getList', 'ControllerConfiguration', 'mode') && <EnumInput source="configuration.mode" Enum="ControllerMode" label="resources.ControllerConfiguration.fields.mode" option="resources.ControllerConfiguration.enums.mode" />}
            <ReferenceInput source="tags_some.id" label="resources.Tag.fields.tags_some" reference="Tag" filter={{ resource: "Controller" }} allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>
        </ManualSubmitFilter>
    )
};

const enumTypeFormatter = (key, value, model, record, translate) => translate(`resources.ControllerType.enums.${key}.${value}`, { _: value });

const modelExport = {
    id: true,
    name: true,
    description: true,
    createdBy: {
        id: true,
        name: true,
        __resource: "User",
    },
    createdAt: dateFormatter,
    updatedAt: dateFormatter,
    phoneNumber: true,
    serialNumber: true,
    status: enumFormatter,
    type: enumTypeFormatter,
    url: true,
    gasMeterUnits: {
        id: true,
        name: true,
        __resource: "GasMeterUnit",
    },
    gasMeters: {
        id: true,
        name: true,
        __resource: "GasMeter",
    },
    configuration: {
        id: true,
        name: true,
        __resource: "ControllerConfiguration",
    },
    tags: {
        id: true,
        name: true,
        __resource: "Tag",
    },
    __resource: "Controller",
};

const ControllerList = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const controllerProps = useListController(props);
    const exporter = customExporter(controllerProps.defaultTitle, modelExport, { translate })
    const manualSubmitForm = React.useRef(null);

    return (
        <ListContext.Provider value={{ ...controllerProps, exporter }}>
            <ListView
                {...props}
                {...controllerProps}
                bulkActionButtons={<ControllerBulkActionButtons />}
                filters={<ControllerFilter formRef={manualSubmitForm} />}
            >
                <Datagrid rowClick="show" classes={{ row: classes.row }}>
                    {ability.can('getList', 'Controller', 'status') && <StatusChip cellClassName={classes.cellMinWidth} />}
                    {ability.can('getList', 'Controller', 'name') && <TextField source="name" />}
                    {ability.can('getList', 'Controller', 'description') && <TextField source="description" />}
                    {ability.can('getList', 'Controller', 'type') && <EnumField source="type" option="resources.ControllerType.enums.type" />}
                    {ability.can('getList', 'Controller', 'serialNumber') && <TextField source="serialNumber" label="resources.Controller.fields.sn" sortable={false} />}
                    {/* {ability.can('getList', 'Controller', 'configuration') && <TextField source="configuration.name" label="resources.Controller.fields.configuration" sortable={false}/>} */}
                    {ability.can('getList', 'Controller', 'tags') && <TagArrayField source="tags" sortable={false}>
                        <TagChip />
                    </TagArrayField>}
                    <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                        {ability.can('getOne', 'Controller') && <ShowBlankItem />}
                        {ability.can('update', 'Controller') && <EditMenuItem />}
                        {ability.can('remove', 'Controller') && <RemoveMenuItem />}
                    </MoreButton>
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

ControllerList.defaultProps = {
    filter: {},
    perPage: 25,
    sort: { field: 'name', order: 'ASC' }
};



export default ControllerList;
import React from 'react';
import BanIcon from '@material-ui/icons/Report';
import UnbanIcon from '@material-ui/icons/ReportOff';
import { useNotify, useRedirect, useUpdate, Button } from 'react-admin';
import { makeStyles, alpha } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
    banButton: {
        color: theme.palette.error.main,
        borderColor: alpha(theme.palette.error.main, 0.5),
        '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.12),
            borderColor: theme.palette.error.main,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },

    },
    unbanButton: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.light,
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.12),
            borderColor: theme.palette.primary.main,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },

    },
}));

const BanButton = ({ basePath, record, redirect: redirectTo, className }) => {
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();

    const [handleBan, { loading: banLoading }] = useUpdate(
        "User",
        record.id,
        { ...record, banned: true },
        record,
        {
            undoable: true,
            onSuccess: ({ data }) => {
                // redirect(`${basePath}/${record.id}/show`);
                notify('ra.notification.user_banned', 'info', {}, true);
            },
            onFailure: (error) => notify(`Error: ${error.message}`, "warning")
        }
    );
    const [handleUnban, { loading: unbanLoading }] = useUpdate(
        'User',
        record.id,
        { ...record, banned: false },
        record,
        {
            undoable: true,
            onSuccess: ({ data }) => {
                // redirect(`${basePath}/${record.id}/show`);
                notify('ra.notification.user_unbanned', 'info', {}, true);
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );

    return record.banned
        ? <Button
            label="ra.action.unban"
            onClick={handleUnban}
            disabled={unbanLoading}
            className={`${classes.unbanButton} ${className}`}
        >
            <UnbanIcon />
        </Button>
        : <Button
            label="ra.action.ban"
            onClick={handleBan}
            disabled={banLoading}
            className={`${classes.banButton} ${className}`}
        >
            <BanIcon />
        </Button>;
};

export default BanButton;
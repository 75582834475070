import { Create, ReferenceArrayInput, SimpleForm, TextInput, required } from 'react-admin';
import AutocompleteArrayInput from '../Inputs/AutocompleteArrayInput';
// import IPInput from '../Inputs/IPInput';
import JsonInput from '../Inputs/JsonInput';
import JsonVariableInput from '../Inputs/JsonVariableInput';
import JsonVariableInputs from '../Inputs/JsonVariableInputs';
import PhoneInput from '../Inputs/PhoneInput';
import React from 'react';
// import ReferenceArrayGasMeterInput from './ReferenceArrayGasMeterInput';
import ReferenceConfigurationInput from './ReferenceConfigurationInput';
import TypeInput from './TypeInput';
import { ability } from "../../abilityProvider";
// import { makeStyles } from '@material-ui/core/styles';



// const useStyles = makeStyles(theme => ({
//     referenceArrayInput: {
//         marginTop: 8,
//         marginBottom: 4,
//     },
// }));

export const ControllerQuickCreateForm = props => {
    return (
        <SimpleForm {...props}>
            {ability.can('create', 'Controller', 'name') && <TextInput source="name" validate={required()} fullWidth />}
            {ability.can('create', 'Controller', 'description') && <TextInput source="description" fullWidth />}
            {ability.can('create', 'Controller', 'type') && <TypeInput source="type" Enum="ControllerType" option="resources.ControllerType.enums.type" validate={required()} />}
            {ability.can('create', 'Controller', 'configuration') && <ReferenceConfigurationInput source="configuration.id" reference="ControllerConfiguration" label="resources.Controller.fields.configuration" allowEmpty emptyText="ra.message.no" />}
            {ability.can('create', 'Controller', 'serialNumber') && <TextInput source="serialNumber" />}
            {ability.can('create', 'Controller', 'url') && <TextInput source="url" />}
            {ability.can('create', 'Controller', 'phoneNumber') && <PhoneInput source="phoneNumber" />}
            {ability.can('create', 'Controller', 'metaVariable') && <JsonVariableInputs source="meta.metaVariable">
                <JsonVariableInput />
            </JsonVariableInputs>}
            {ability.can('create', 'Controller', 'meta') && <JsonInput source="meta" fullWidth />}
            {ability.can('create', 'Controller', 'tags') && <ReferenceArrayInput source="tagsIds" filter={{ resource: "Controller" }} reference="Tag" label="resources.Controller.fields.tags" fullWidth>
                <AutocompleteArrayInput />
            </ReferenceArrayInput>}
        </SimpleForm>
    )
};

const ControllerCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >
            <SimpleForm redirect="show" >
                {ability.can('create', 'Controller', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {ability.can('create', 'Controller', 'description') && <TextInput source="description" fullWidth />}
                {ability.can('create', 'Controller', 'type') && <TypeInput source="type" Enum="ControllerType" option="resources.ControllerType.enums.type" validate={required()} />}
                {ability.can('create', 'Controller', 'configuration') && <ReferenceConfigurationInput source="configuration.id" reference="ControllerConfiguration" label="resources.Controller.fields.configuration" allowEmpty emptyText="ra.message.no" />}
                {ability.can('create', 'Controller', 'serialNumber') && <TextInput source="serialNumber" />}
                {ability.can('create', 'Controller', 'url') && <TextInput source="url" />}
                {ability.can('create', 'Controller', 'phoneNumber') && <PhoneInput source="phoneNumber" />}
                {ability.can('create', 'Controller', 'metaVariable') && <JsonVariableInputs source="meta.metaVariable">
                    <JsonVariableInput />
                </JsonVariableInputs>}
                {ability.can('create', 'Controller', 'meta') && <JsonInput source="meta" fullWidth />}
                {/* Убрал из-за путаницы с созданием Устройства. Теперь связь назначается только в Устройстве */}
                {/* {ability.can('create', 'Controller', 'gasMetersIds') && <ReferenceArrayGasMeterInput source="gasMetersIds" reference="GasMeter" label="resources.Controller.fields.gasMeters" filter={{ controller: null }} classes={{container: classes.referenceArrayInput}} formClassName={classes.flexReferenceArrayInput} fullWidth />} */}
                {ability.can('create', 'Controller', 'tags') && <ReferenceArrayInput source="tagsIds" filter={{ resource: "Controller" }} reference="Tag" label="resources.Controller.fields.tags" fullWidth>
                    <AutocompleteArrayInput />
                </ReferenceArrayInput>}
            </SimpleForm>
        </Create>
    );
};

export default ControllerCreate;
import React, { useCallback } from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    useTranslate,
    DateInput,
    TextInput,
    required
} from 'react-admin';







const useStyles = makeStyles(theme => ({
    formGrid: {
        display: "grid",
        gridTemplateColumns: "2fr 4fr",
        gridGap: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    }
}));

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

const validateDate = [required()];

const UninstallGasMeterUnitButtonDialog = ({
    open,
    onClose,
    onUpload
}) => {
    const classes = useStyles()
    const translate = useTranslate();

    const handleClose = useCallback(() => {
        onClose()
    }, [onClose])

    const handleUpload = useCallback((values) => {
        onUpload(values)
    }, [onUpload])

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">{translate('ra.uninstallGasMeterUnit.title', { smart_count: 1 })}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{translate('ra.uninstallGasMeterUnit.content', { smart_count: 1 })}</DialogContentText>
                <DialogContentText id="alert-dialog-description">{translate('ra.uninstallGasMeterUnit.question', { smart_count: 1 })}</DialogContentText>
            </DialogContent>
            <Form
                initialValues={{
                    // dateFrom: moment().subtract(1, 'day').format(moment.HTML5_FMT.DATETIME_LOCAL),
                    dateTo: moment().format(moment.HTML5_FMT.DATETIME_LOCAL)
                }}
                onSubmit={handleUpload}
                subscription={defaultSubscription}
                keepDirtyOnReinitialize
                render={({ handleSubmit, invalid }) => (
                    <form>
                        <div className={classes.formGrid}>
                            {/* <DateInput source="dateFrom" resource="Device" validate={validateDate} /> */}
                            <DateInput source="dateTo" label="Дата с" validate={validateDate} />
                            <TextInput source="reason" label="Причина" validate={validateDate} />
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose} >{translate(`ra.action.cancel`)}</Button>
                            <Button
                                disabled={invalid}
                                onClick={handleSubmit}
                                color="primary"
                            >{translate(`ra.uninstallGasMeterUnit.uninstall`)}</Button>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    );
};

export default UninstallGasMeterUnitButtonDialog;
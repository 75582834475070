import React, { useCallback } from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    useTranslate,
    CheckboxGroupInput,
    DateTimeInput,
    required
} from 'react-admin';
import { usePreferences } from "@react-admin/ra-preferences";






const useStyles = makeStyles(theme => ({
    formGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(208px, 1fr))",
        gridGap: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    }
}));

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

const validateDate = [required()];

const BulkUploadXMLButtonDialog = ({
    open,
    onClose,
    onUpload
}) => {
    const classes = useStyles()
    const translate = useTranslate();
    const [initialValues, setInitialValues] = usePreferences("uploadXML.initialValues", {
        dateFrom: moment().subtract(1, 'day').format(moment.HTML5_FMT.DATETIME_LOCAL),
        dateTo: moment().format(moment.HTML5_FMT.DATETIME_LOCAL),
        uploadMods: ['HOURS']
    });

    const handleClose = useCallback(() => {
        onClose()
    }, [onClose])

    const handleUpload = useCallback((values) => {
        onUpload(values)
        setInitialValues(values)
    }, [onUpload])

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">{translate('ra.uploadXML.title', { smart_count: 1 })}</DialogTitle>
            <DialogContent>
                <DialogContentText >{translate('ra.uploadXML.content', { smart_count: 1 })}</DialogContentText>
            </DialogContent>
            <Form
                initialValues={initialValues}
                onSubmit={handleUpload}
                subscription={defaultSubscription}
                keepDirtyOnReinitialize
                render={({ handleSubmit, invalid }) => (
                    <form>
                        <div className={classes.formGrid}>
                            <DateTimeInput source="dateFrom" resource="Device" validate={validateDate} />
                            <DateTimeInput source="dateTo" resource="Device" validate={validateDate} />
                            <CheckboxGroupInput source="uploadMods" resource="Device" choices={[
                                { id: 'HOURS', name: 'Часовые' },
                                { id: 'DAYS', name: 'Суточные' }
                            ]} />
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose} >{translate(`ra.action.cancel`)}</Button>
                            <Button
                                disabled={invalid}
                                onClick={handleSubmit}
                                color="primary"
                            >{translate(`ra.uploadXML.upload`)}</Button>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    );
};

export default BulkUploadXMLButtonDialog;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, BulkExportButton, Datagrid, DateField, Filter, ListContext, ListView, TextField, useListController } from 'react-admin';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import SearchInput from '../Inputs/SearchInput';
import BulkRemoveButton from '../Buttons/BulkRemoveButton';
import customExporter, { dateFormatter, enumFormatter } from '../../exporter';
import EnumField from '../Fields/EnumField';
import { ability } from "../../abilityProvider";
import MoreButton from "../Buttons/MoreButton";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import RemoveMenuItem from "../Buttons/RemoveButtonAsMenuItem";
import StatusChip from "./ControllerConfigurationStatusChip";
import EnumInput from "../Inputs/EnumInput";



const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const ControllerConfigurationBulkActionButtons = props => (
    <>
        {ability.can('remove', 'ControllerConfiguration') && <BulkRemoveButton {...props} />}
        {ability.can('getList', 'ControllerConfiguration') && <BulkExportButton {...props} />}
    </>
);

const ControllerConfigurationFilter = (props) => (
    <ManualSubmitFilter {...props}>
        <SearchInput source="q" alwaysOn resettable />
        {ability.can('getList', 'ControllerConfiguration', 'type') && <EnumInput source="type" Enum="ControllerType" option="resources.ControllerType.enums.type" />}
        {ability.can('getList', 'ControllerConfiguration', 'mode') && <EnumInput source="mode" Enum="ControllerMode" />}
    </ManualSubmitFilter>
);

const enumTypeFormatter = (key, value, model, record, translate) => translate(`resources.ControllerType.enums.${key}.${value}`, { _: value });

const modelExport = {
    id: true,
    name: true,
    description: true,
    createdBy: {
        id: true,
        name: true,
        __resource: "User",
    },
    createdAt: dateFormatter,
    updatedAt: dateFormatter,
    status: enumFormatter,
    type: enumTypeFormatter,
    ns: true,
    converterSet: false,
    functionalGroupSet: false,
    parameterSet: false,
    protocolSet: false,
    scadaSet: false,
    schedulerSet: false,
    metaTemplate: false,
    __resource: "ControllerConfiguration",
};

const ControllerConfigurationList = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const controllerProps = useListController(props);
    const exporter = customExporter(controllerProps.defaultTitle, modelExport, { translate })
    const manualSubmitForm = React.useRef(null);

    return (
        <ListContext.Provider value={{ ...controllerProps, exporter }}>
            <ListView
                {...props}
                {...controllerProps}
                bulkActionButtons={<ControllerConfigurationBulkActionButtons />}
                filters={<ControllerConfigurationFilter formRef={manualSubmitForm} />}
            >
                <Datagrid rowClick="show" classes={{ row: classes.row }}>
                    {ability.can('getList', 'ControllerConfiguration', 'id') && <StatusChip cellClassName={classes.cellMinWidth} />}
                    {ability.can('getList', 'ControllerConfiguration', 'name') && <TextField source="name" />}
                    {ability.can('getList', 'ControllerConfiguration', 'description') && <TextField source="description" />}
                    {ability.can('getList', 'ControllerConfiguration', 'type') && <EnumField source="type" option="resources.ControllerType.enums.type" />}
                    <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                        {ability.can('getOne', 'ControllerConfiguration') && <ShowBlankItem />}
                        {ability.can('update', 'ControllerConfiguration') && <EditMenuItem />}
                        {ability.can('remove', 'ControllerConfiguration') && <RemoveMenuItem />}
                    </MoreButton>
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

ControllerConfigurationList.defaultProps = {
    filter: {},
    perPage: 25,
    sort: { field: 'name', order: 'ASC' }
};


export default ControllerConfigurationList;

import { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import apolloCoreClient from './apolloCoreClient';



const GET_ONE_DEVICE = gql`
    query Device( $where: DeviceWhereUniqueInput! ) {
        data: Device( where: $where ) {
            id
            name
            description
            updatedAt
            configuration{
                id
                ns
                parameterSet
                functionalGroupSet
            }
            aisId
            url
            dbId
            serialNumber
            type
            status
            meta
            gasMeterId
            controllerId
        }
    }
`;

export default function(resource, id, version) {

    const [state, setState] = useState({
        data: undefined,
        error: undefined,
        loading: true,
    })

    useEffect(() => {

        if (!id) return;

        apolloCoreClient.query({
            query: GET_ONE_DEVICE,
            variables: { where: {id} },
            fetchPolicy: 'network-only',
        })
            .then(({ data: {data} }) => {
                setState({
                    data,
                    loading: false,
                    error: undefined,
                });
            })
            .catch(error => {
                setState({
                    error,
                    loading: false,
                });
            });
    }, [resource, id, version]);


    return state;
}

import { Create, SimpleForm, TextInput, maxLength, required } from 'react-admin';
import EnumInput from '../Inputs/EnumInput';
import JsonInput from '../Inputs/JsonInput';
import React from 'react';
import { ability } from "../../abilityProvider";
// import { makeStyles } from '@material-ui/core/styles';



const GasMeterConfigurationCreate = props => {

    return (
        <Create {...props} >
            <SimpleForm redirect="show" >
                {ability.can('create', 'GasMeterConfiguration', 'name') && <TextInput source="name" fullWidth validate={required()} />}
                {ability.can('create', 'GasMeterConfiguration', 'description') && <TextInput source="description" fullWidth />}
                {ability.can('create', 'GasMeterConfiguration', 'type') && <EnumInput source="type" Enum="GasMeterType" option="resources.GasMeterType.enums.type" validate={required()} />}
                {ability.can('update', 'GasMeterConfiguration', 'ns') && <TextInput source="ns" validate={maxLength(1)} />}
                {ability.can('create', 'GasMeterConfiguration', 'parameterSet') && <JsonInput source="parameterSet" fullWidth />}
                {ability.can('create', 'GasMeterConfiguration', 'functionalGroupSet') && <JsonInput source="functionalGroupSet" fullWidth />}
                {ability.can('create', 'GasMeterConfiguration', 'protocolSet') && <JsonInput source="protocolSet" fullWidth />}
                {ability.can('create', 'GasMeterConfiguration', 'schedulerSet') && <JsonInput source="schedulerSet" fullWidth />}
                {ability.can('create', 'GasMeterConfiguration', 'converterSet') && <JsonInput source="converterSet" fullWidth />}
                {ability.can('create', 'GasMeterConfiguration', 'scadaSet') && <JsonInput source="scadaSet" fullWidth />}
                {ability.can('create', 'GasMeterConfiguration', 'metaTemplate') && <JsonInput source="metaTemplate" fullWidth />}
            </SimpleForm>
        </Create>
    );
};

export default GasMeterConfigurationCreate;
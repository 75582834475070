import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3 5c0-1.1.9-2 2-2h14a2 2 0 012 2v7.29A7 7 0 0012 19h-1v2H9v-2H7v2H5v-2a2 2 0 01-2-2V5zm2 1a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-1 1H6a1 1 0 01-1-1V6zm12.74 8.21A.26.26 0 0118 14h2c.11 0 .22.09.23.21l.19 1.32c.31.12.58.29.85.47l1.23-.5c.13 0 .26 0 .32.13l1 1.73c.06.11.03.24-.06.32l-1.06.82a2.81 2.81 0 010 .97v.03l1.07.82c.09.08.12.21.06.32l-1 1.73c-.07.13-.2.13-.33.13l-1.23-.5c-.27.18-.54.34-.84.47l-.19 1.32A.25.25 0 0120 24h-2a.25.25 0 01-.26-.21l-.19-1.32a4.11 4.11 0 01-.85-.47l-1.24.5c-.12 0-.25 0-.31-.13l-1-1.73a.26.26 0 01.06-.32l1.06-.82a4.2 4.2 0 010-1l-1.06-.82a.25.25 0 01-.06-.32l1-1.73c.07-.13.2-.13.31-.13l1.24.5a5 5 0 01.85-.47l.19-1.32zM19 17.5a1.5 1.5 0 110 3 1.5 1.5 0 110-3z" />
    </SvgIcon>
  );
}

export default Icon;
import { AutocompleteInput, BulkExportButton, Datagrid, DateField, ReferenceArrayInput, AutocompleteArrayInput, ListContext, ListView, NumberInput, ReferenceInput, TextField, TextInput, useListController, useTranslate } from 'react-admin';
import ManualSubmitFilter from '../Layout/filter/ManualSubmitFilter';
import SearchInput from '../Inputs/SearchInput';
import customExporter, { dateFormatter, enumFormatter } from '../../exporter';
import IPInput from '../Inputs/IPInput';
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import EnumField from '../Fields/EnumField';
import EnumInput from '../Inputs/EnumInput'
import MoreButton from "../Buttons/MoreButton";
import React from 'react';
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";
import StatusChip from "../GasMeter/GasMeterStatusChip";
import { ability } from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';
import BulkUploadXMLButton from "../Buttons/BulkUploadXMLButton";



const matchTrue = () => true;

const useFilterStyles = makeStyles(theme => ({
    mediumWidth: { width: theme.spacing(19) },
}));

const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const DeviceBulkActionButtons = props => (
    <>
        {/*{ability.can('remove', 'GasMeter') && <BulkRemoveButton {...props} />}*/}
        <BulkUploadXMLButton {...props} />
        {ability.can('getList', 'Device') && <BulkExportButton {...props} />}
    </>
);

const dbIdMatch = (filter, choice) => {
    return true

    // return !filter || filter === choice.dbId.toString()
}

const dbIdFilterToQuery = (searchText) => (searchText ? { dbId: searchText } : {})

const DeviceFilter = (props) => {
    const classes = useFilterStyles();
    return (
        <ManualSubmitFilter {...props}>
            <SearchInput source="q" alwaysOn resettable />
            {ability.can('getList', 'Device', 'aisId') && <TextInput source="aisId" alwaysOn resettable className={classes.mediumWidth} />}
            {ability.can('getList', 'Device', 'dbId') && <NumberInput source="dbId" alwaysOn className={classes.mediumWidth} />}
            {ability.can('getList', 'Device', 'dbId') && <ReferenceArrayInput
                source="id_in"
                reference="Device"
                filterToQuery={dbIdFilterToQuery}
                label="resources.Device.fields.dbId_in"
                allowEmpty={false}>
                <AutocompleteArrayInput optionValue="id" optionText="dbId" matchSuggestion={matchTrue} />
            </ReferenceArrayInput>}
            {ability.can('getList', 'Device', 'ip') && <IPInput source="ip" alwaysOn resettable className={classes.mediumWidth} />}
            <ReferenceInput source="id" reference="GasMeterUnit" label="resources.Device.fields.gasMeterUnit" sort={{ field: 'name', order: 'ASC' }} allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>
            <ReferenceInput source="controllerId" reference="Controller" label="resources.Device.fields.controller" allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>
            {ability.can('getList', 'Device', 'controllerType') && <EnumInput source="controllerType" Enum="ControllerType" option="resources.ControllerType.enums.type" emptyText="ra.message.no" />}

            <ReferenceInput source="gasMeterId" reference="GasMeter" label="resources.Device.fields.gasMeter" sort={{ field: 'name', order: 'ASC' }} allowEmpty emptyText="ra.message.no">
                <AutocompleteInput optionText="name" matchSuggestion={matchTrue} />
            </ReferenceInput>
            {ability.can('getList', 'Device', 'gasMeterType') && <EnumInput source="gasMeterType" Enum="GasMeterType" option="resources.GasMeterType.enums.type" emptyText="ra.message.no" />}


            {ability.can('getList', 'Device', 'mode') && <EnumInput source="mode" Enum="ControllerMode" label="resources.ControllerConfiguration.fields.mode" option="resources.ControllerConfiguration.enums.mode" />}
            {ability.can('getList', 'Device', 'serialNumber') && <TextInput source="serialNumber" />}

        </ManualSubmitFilter>
    )
};

const modelExport = {
    id: true,
    aisId: true,
    dbId: true,
    name: true,
    description: true,
    createdAt: dateFormatter,
    updatedAt: dateFormatter,
    status: enumFormatter,
    type: enumFormatter,
    serialNumber: true,
    url: true,
    controllerId: true,
    gasMeterId: true,
    __resource: "Device",
};

const DeviceList = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const controllerProps = useListController(props);
    const exporter = customExporter(controllerProps.defaultTitle, modelExport, { translate })
    const manualSubmitForm = React.useRef(null);

    return (
        <ListContext.Provider value={{ ...controllerProps, exporter }}>
            <ListView
                {...props}
                {...controllerProps}
                bulkActionButtons={<DeviceBulkActionButtons />}
                filters={<DeviceFilter formRef={manualSubmitForm} />}
            >
                <Datagrid rowClick="show" classes={{ row: classes.row }}>
                    {ability.can('getList', 'Device', 'status') && <StatusChip cellClassName={classes.cellMinWidth} />}
                    {ability.can('getList', 'Device', 'name') && <TextField source="name" />}
                    {ability.can('getList', 'Device', 'description') && <TextField source="description" />}
                    {ability.can('getList', 'Device', 'type') && <EnumField source="type" label="resources.Device.fields.controllerType" option="resources.ControllerType.enums.type" />}
                    {ability.can('getList', 'Device', 'serialNumber') && <TextField source="serialNumber" label="resources.Device.fields.sn" sortable={false} />}
                    <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                        {ability.can('getOne', 'Device') && <ShowBlankItem />}
                        {ability.can('update', 'Device') && <EditMenuItem />}
                    </MoreButton>
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

DeviceList.defaultProps = {
    filter: {},
    perPage: 25,
    sort: { field: 'name', order: 'ASC' }
};


export default DeviceList;

import React from 'react';
import { Create, SimpleForm, TextInput, maxLength, required } from 'react-admin';
import EnumInput from '../Inputs/EnumInput';
import JsonInput from '../Inputs/JsonInput';
import { ability } from "../../abilityProvider";



const ControllerConfigurationCreate = props => {
    return (
        <Create {...props} >
            <SimpleForm redirect="show" >
                {ability.can('create', 'ControllerConfiguration', 'name') && <TextInput source="name" fullWidth validate={required()} />}
                {ability.can('create', 'ControllerConfiguration', 'description') && <TextInput source="description" fullWidth />}
                {ability.can('create', 'ControllerConfiguration', 'type') && <EnumInput source="type" Enum="ControllerType" option="resources.ControllerType.enums.type" validate={required()} />}
                {ability.can('create', 'ControllerConfiguration', 'mode') && <EnumInput source="mode" Enum="ControllerMode" validate={required()} />}
                {ability.can('create', 'ControllerConfiguration', 'ns') && <TextInput source="ns" validate={maxLength(1)} />}
                {ability.can('create', 'ControllerConfiguration', 'parameterSet') && <JsonInput source="parameterSet" fullWidth />}
                {ability.can('create', 'ControllerConfiguration', 'functionalGroupSet') && <JsonInput source="functionalGroupSet" fullWidth />}
                {ability.can('create', 'ControllerConfiguration', 'protocolSet') && <JsonInput source="protocolSet" fullWidth />}
                {ability.can('create', 'ControllerConfiguration', 'schedulerSet') && <JsonInput source="schedulerSet" fullWidth />}
                {ability.can('create', 'ControllerConfiguration', 'converterSet') && <JsonInput source="converterSet" fullWidth />}
                {ability.can('create', 'ControllerConfiguration', 'scadaSet') && <JsonInput source="scadaSet" fullWidth />}
                {ability.can('create', 'ControllerConfiguration', 'metaTemplate') && <JsonInput source="metaTemplate" fullWidth />}
            </SimpleForm>
        </Create>
    );
};

export default ControllerConfigurationCreate
import React from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { AutocompleteInput, Create, ReferenceInput, DateTimeInput, SimpleForm, required } from 'react-admin';
import Alert from '@material-ui/lab/Alert';
import ReferenceControllerInput from '../Controller/ReferenceControllerInput';
import ReferenceGasMeterInput from '../GasMeter/ReferenceGasMeterInput';
import WarningIcon from '@material-ui/icons/Warning';
import { ability } from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    flexInput: {
        display: "flex",
        alignItems: "flex-start",
    },
}));


const dateValidation = (value, allValues) => {
    return (value && value instanceof Date && isNaN(value)) ? 'ra.validation.date_invalid' : undefined
};

const validate = [required()];
const validateDate = [dateValidation, required()];

const DeviceCreate = props => {
    const classes = useStyles();
    const gasMeterDefaultValue = {
        timeZone: 3,
        commercialHour: 10,
    }

    return (
        <>
            <Alert severity="warning" icon={<WarningIcon />} style={{ border: "1px solid #ff9800", marginTop: 24 }} >
                <span>Внимание. Для создания <strong>Устройства</strong> необходимо заполнить поля: </span>
                <ul>
                    <li>УУГ (в список попадут УУГ, у которых нет связей с Контроллерами и Корректорами)</li>
                    <li>Контроллер (в список попадут Контроллеры в статусе Черновик)</li>
                    <li>Корректор (в список попадут Корректоры в статусе Черновик, без связей с УУГ и Корректором)</li>
                </ul>
                <span>Результатом станет создание связей между УУГ, Контроллером и Корректором. Кроме того будут добавлены записи в журналы УУГ: Владение Контроллерами, Владение Корректорами.</span>
            </Alert>
            <Create {...props} >
                <SimpleForm redirect="show" initialValues={gasMeterDefaultValue}>
                    <ReferenceInput source="id" validate={validate} reference="GasMeterUnit" label="resources.Device.fields.gasMeterUnit" filter={{ gasMeter: null, controller: null, }} sort={{ field: 'name', order: 'ASC' }} fullWidth >
                        <AutocompleteInput source="name" />
                    </ReferenceInput>
                    <ReferenceControllerInput source="controllerId" reference="Controller" validate={validate} label="resources.Device.fields.controller" filter={{ status: 'DRAFT' }} sort={{ field: 'name', order: 'ASC' }} fullWidth formClassName={classes.flexInput} />
                    <ReferenceGasMeterInput source="gasMeterId" reference="GasMeter" validate={validate} label="resources.Device.fields.gasMeter" filter={{ gasMeterUnit: null, controller: null, status: 'DRAFT' }} sort={{ field: 'name', order: 'ASC' }} fullWidth formClassName={classes.flexInput} />
                    <DateTimeInput source="dateFrom" label="resources.Device.fields.dateFromOwnership" validate={validateDate} defaultValue={moment().format(moment.HTML5_FMT.DATETIME_LOCAL)} />
                </SimpleForm>
            </Create>
        </>

    );
};

export default DeviceCreate
import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Create, SimpleForm, SelectInput, TextInput, required } from 'react-admin';
// import EnumInput from '../Inputs/EnumInput';
import ColorInput from '../Inputs/ColorInput';
import { ability } from "../../abilityProvider";




const resources = [
    { id: 'Controller', name: 'resources.Tag.enums.resource.Controller' },
    { id: 'GasMeter', name: 'resources.Tag.enums.resource.GasMeter' },
]

// const useStyles = makeStyles(theme => ({

// }));

const TagCreate = props => {
    // const classes = useStyles();

    return (
        <Create {...props} >
            <SimpleForm redirect="show">
                {ability.can('create', 'Tag', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                {ability.can('create', 'Tag', 'description') && <TextInput source="description" multiline fullWidth />}
                {/* {ability.can('create', 'Tag', 'type') && <EnumInput source="type" Enum="TagType" validate={required()}/>} */}
                {ability.can('create', 'Tag', 'resource') && <SelectInput source="resource" choices={resources} validate={required()} />}
                {ability.can('create', 'Tag', 'color') && <ColorInput source="color" />}
            </SimpleForm>
        </Create>
    );
};

export default TagCreate;
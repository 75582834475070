import React from 'react';
import { ability, rulesToFields } from '../../abilityProvider';
import { makeStyles } from '@material-ui/core/styles';
import { EditContextProvider, useEditController, SimpleForm, TextInput, Toolbar, SaveButton, required, minLength } from 'react-admin';
import { EditView } from '../Layout/EditView';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import CardBlankLayout from '../Layout/CardBlankLayout';
import UserBanner from '../User/UserBanner';
import TitleForRecord from '../Layout/TitleForRecord';
import RemoveButton from '../Buttons/RemoveButton';
import CancelButton from '../Buttons/CancelButton';
import BanButton from '../Buttons/BanButton';
import PhoneInput from '../Inputs/PhoneInput';
import PasswordInput from '../Inputs/PasswordInput';
import EnumInput from '../Inputs/EnumInput';
import { shallowEqual, useSelector } from 'react-redux';
import get from 'lodash/get';


const filterRoles = (value) => {
    const conditions = rulesToFields("update", "User")
    const role_in = (conditions.role && conditions.role.$in) || [];
    return role_in.includes(value.name);
}

const logined = (value, values) => {
    if (!values.email && !values.phone && !values.login) {
        return 'ra.action.one_of_fields_is_required';
    }
};
const validateLogin = [logined];
const validateName = [required(), minLength(2)];
const validateRole = [required()];

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
}));



const UserToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {ability.can("update", "User") && <SaveButton />}
            {ability.can("update", "User") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {ability.can("ban", "User") && <BanButton redirect="show" />}
            {ability.can("remove", "User") && <RemoveButton redirect="list" />}
            <CancelButton />
        </Toolbar>
    );
}

const UserEdit = props => {
    const controllerProps = useEditController(props);
    // const classes = useStyles();
    const user = useSelector(state => state.user, shallowEqual);
    const itsMe = get(controllerProps, ["record", "id"]) === get(user, ["id"])

    return (
        <EditContextProvider value={controllerProps}>
            <EditView
                title={<TitleForRecord source="name" {...props} />}
                {...props}
                {...controllerProps}
            >
                <UserBanner />
                <CardBlankLayout >
                    <SimpleForm toolbar={<UserToolbar />}>
                        {ability.can("update", "User", "name") && <TextInput source="name" validate={validateName} fullWidth />}
                        {ability.can("update", "User", "description") && <TextInput source="description" fullWidth />}
                        {ability.can("update", "User", "position") && <TextInput source="position" fullWidth />}
                        {ability.can("update", "User", "role") && !itsMe && <EnumInput source="role" Enum="UserRole" filter={filterRoles} validate={validateRole} />}
                        {ability.can("update", "User", "login") && <TextInput source="login" validate={validateLogin} />}
                        {ability.can("update", "User", "email") && <TextInput source="email" type="email" validate={validateLogin} inputProps={{ autoComplete: "new-password" }} />}
                        {ability.can("update", "User", "phone") && <PhoneInput source="phone" validate={validateLogin} inputProps={{ autoComplete: "new-password" }} />}
                        {ability.can("update", "User", "phoneContact") && <PhoneInput source="phoneContact" />}
                        {ability.can("update", "User", "password") && <PasswordInput source="password" />}
                    </SimpleForm>
                </CardBlankLayout>
            </EditView>
        </EditContextProvider>
    );
};

export default UserEdit
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


import backgroundImage from "../../files/DashboardBanner.svg";

const styles = theme => ({
    banner: {
        height: 240,
        position: "relative",
        overflow: "unset",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        flexGrow: 1,
        zIndex: 1,
    },
    title: {
        fontWeight: 500,
    },

    backgroundImage: {
        position: "absolute",
        bottom: -41,
        right: 40,
    },

});

const WidgetBanner = withStyles(styles)(({ classes, ...props }) => {
    return (
        <Card className={classes.banner}>

            <img src={backgroundImage} alt="lorem" className={classes.backgroundImage} />
            <CardContent >
                <Typography variant="h5" color="inherit" gutterBottom className={classes.title}>Добро пожаловать на портал АСКУГ Управление!</Typography>
            </CardContent>
        </Card>

    )

});


export default WidgetBanner;



import React, { useState, cloneElement } from 'react';
import inflection from 'inflection';
import { get } from 'lodash';
import { makeStyles } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import CardHeader from '@material-ui/core/CardHeader';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import IconContentAdd from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { crudGetMatching, useTranslate, Toolbar, useCreate, useNotify } from 'react-admin';
import { useForm, useFormState } from "react-final-form";
import { useDispatch } from 'react-redux';



const useStyles = makeStyles({
    button: {
        verticalAlign: "bottom",
        position: "relative",
        marginTop: 8,
        marginBottom: 4,
    },
    paper: {
        minWidth: "16rem",
    },
    mlAuto: { marginLeft: "auto" },
    drawerForm: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    drawerToolbar: {
        marginTop: "auto",
    },
});

const QuickCreateButton = ({ resource, source, reference, basePath, children, isArray, ...props }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [create] = useCreate(reference);
    const notify = useNotify();
    const translate = useTranslate();
    const form = useForm();
    const { values } = useFormState();
    const [open, setOpen] = useState(false);

    const referenceName = translate(`resources.${reference}.name`, {
        smart_count: 1,
        _: inflection.humanize(inflection.singularize(resource)),
    });
    const defaultTitle = translate('ra.page.create', {
        name: `${referenceName}`,
    });

    const handleClick = (event) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleCloseClick = (event) => {
        event.preventDefault();
        setOpen(false);
    };

    const onSave = (data) => {
        const { pagination = { page: 1, perPage: 25 }, sort = { field: "id", order: "DESC" }, filter = {} } = props;
        dispatch(
            crudGetMatching(
                reference,
                `${resource}@${source}`,
                pagination,
                sort,
                filter,
            )
        );

        if (isArray) {
            const value = get(values, source, [])
            form.change(source, [...value, data.id]);
        } else {
            form.change(source, data.id)
        }

        setOpen(false);
    }

    const handleSave = async values => {

        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    setOpen(false);
                    onSave(data);
                },
                onFailure: (error) => {
                    notify(error.message, 'error');
                }
            })
    };


    return (
        <>
            <Tooltip title={translate("ra.action.create")} enterDelay={700}>
                <IconButton onClick={handleClick} aria-label="QuickCreateButton" className={classes.button}>
                    <IconContentAdd />
                </IconButton>
            </Tooltip>
            <Drawer anchor="right" open={open} onClose={handleCloseClick} classes={{ paper: classes.paper }}>
                <CardHeader
                    title={defaultTitle}
                    action={
                        <IconButton onClick={handleCloseClick} aria-label="Close" size="small">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                />
                {cloneElement(children, {
                    basePath: basePath.replace(resource, reference),
                    resource: reference,
                    save: handleSave,
                    redirect: false,
                    className: classes.drawerForm,
                    toolbar: <Toolbar className={classes.drawerToolbar} />,
                })}
            </Drawer>
        </>
    );

}

export default QuickCreateButton;
import React, { useState, useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import PublishIcon from '@material-ui/icons/Publish';
import {
    useNotify,
    Button,
    fetchStart,
    fetchEnd

} from 'react-admin';
import UploadXMLButtonDialog from "./UploadXMLButtonDialog";
import uploadXml from "../../dataProvider/UploadXML/uploadXml";
import moment from 'moment'


///Заглушка
/*
const uploadXML = ({ dbIds, dateFrom, dateTo }) => {

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(dbIds);
        }, 1000)
    });
}*/







const UploadXMLButton = ({ basePath, record, resource, className, redirect: redirectTo, ...props }) => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [process, setProcess] = useState(false);

    const handleOpen = useCallback(() => {
        if (process == true) { return; }
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleUpload = useCallback(({ dateFrom, dateTo, uploadMods }) => {
        if (!uploadMods || process == true) { return; }

        setProcess(true);
        setOpen(false);
        dispatch(fetchStart());
        uploadXml({
            ids: [record.dbId],
            from: moment(dateFrom).toISOString(),
            to: moment(dateTo).toISOString(),
            mods: uploadMods,
        }).then(({ data: { data } }) => {
            if (data.status == 'ERROR') {
                notify('Upload XML: ' + data.error, 'warning');
            } else {
                notify('XML архивы выгружены. ' + data.info.join('. '), 'info');
            }
        })
            .catch((error) => {

                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                setProcess(false);
                dispatch(fetchEnd());
            });
    }, [record, dispatch, process]);


    return (
        <Fragment>
            <Button
                disabled={process}
                onClick={handleOpen}
                label='ra.uploadXML.upload'
                className={className}
            >
                <PublishIcon />
            </Button>
            <UploadXMLButtonDialog
                open={open}
                onClose={handleClose}
                onUpload={handleUpload}
            />
        </Fragment>
    );
};

export default UploadXMLButton;
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import EllipsisLoader from "./EllipsisLoader";
import { getFinalType, formatValue } from './connectUtils';



const useStyles = makeStyles(theme => ({
    labelValue: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    },
    labelUnit: {
        flexBasis: 56
    },
    labelTimestamp: {
        whiteSpace: 'nowrap',
    },
}));


export const getValueColor = (quality) => {

    switch (quality) {
        case 11: {
            return "#EF6C00" // тест
        } break;
        case 0: {
            return "rgba(0, 0, 0, 0.87)" // норма
        } break;
        default: {
            return "#757575"
        }
    }
};

const getQualityWriteDescription = (quality) => {
    switch (true) {
        case quality === 1: {
            return "Приложение верхнего уровня успешно приняло значение на запись"
        } break;
        case quality === 2: {
            return "Шина данных успешно приняла значение на запись"
        } break;
        case quality === 3: {
            return "Шина данных значение на запись не приняла"
        } break;
        case quality === 4: {
            return "Сервер опроса устройств принял значение на запись"
        } break;
        case quality === 5: {
            return "Сервер опроса устройств отклонил значение на запись"
        } break;
        case quality === 6: {
            return "Сервер опроса устройств ожидает передачи значение на запись в устройство"
        } break;
        case quality === 7: {
            return "Сервер опроса устройств передал значение на запись в устройство"
        } break;
        case quality === 8: {
            return "Сервер опроса получил от устройства отклонил значение на запись"
        } break;
        case quality === 9: {
            return "Сервер опроса устройств успешно обработал значение на запись (переменная только сервера опроса устройств)"
        } break;
        case quality === 10: {
            return "Конечное устройство успешно обработало значение на запись (переменная в устройстве)"
        } break;
        default: {
            return ""
        }
    }
};



const ConnectGroupTreeLeaf = ({ node, tags, ...props }) => {

    const classes = useStyles();
    const translate = useTranslate();

    const { id, isWrite, type, format, enums } = node;
    const idWrite = isWrite ? id | 32768 : undefined;
    const tag = tags && tags[id];
    const tagWrite = tags && tags[idWrite];
    const value = tag && tag.value;
    const quality = tag && tag.quality;
    const timestamp = tag && tag.timestampSrv && formatValue(tag.timestampSrv, "DATETIME", "L LTS");
    const valueWrite = tagWrite && tagWrite.value;
    const qualityWrite = tagWrite && tagWrite.quality;
    const timestampWrite = tagWrite && tagWrite.timestampSrv && formatValue(tagWrite.timestampSrv, "DATETIME", "L LTS");
    const valueColor = getValueColor(quality, timestamp, timestampWrite);
    const qualityWriteDescription = getQualityWriteDescription(qualityWrite);
    const showValue = Boolean(tag)
    const showValueWrite = Boolean(qualityWrite)
    const finalType = getFinalType(type, format)
    const valueFormated = formatValue(value, finalType, format, enums, translate)
    const valueWriteFormated = formatValue(valueWrite, finalType, format, enums, translate)

    return (
        <>
            {qualityWriteDescription && <Typography variant="caption" color="primary" >{qualityWriteDescription}<EllipsisLoader /></Typography>}
            {showValue && <Typography variant="body2" className={classes.labelValue} style={{ color: valueColor }}>{valueFormated}</Typography>}
            {showValueWrite && <Typography variant="body2" className={classes.labelValue} >{`( ${valueWriteFormated} )`}</Typography>}
            <Typography variant="caption" color="textSecondary" className={classes.labelTimestamp}>{timestamp}</Typography>
        </>
    )
};

export default React.memo(ConnectGroupTreeLeaf);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/ru';

import { useInput, FieldTitle, InputProps } from 'ra-core';

import { InputHelperText } from 'react-admin';

import { useFormState } from 'react-final-form';

// import { Field } from 'react-final-form';
// import { Labeled } from 'react-admin';

const useStyles = makeStyles(theme => {
    const light = theme.palette.type === 'light';
    const backgroundColor = light ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.09)';

    return {
        root: {
            position: 'relative',
            backgroundColor,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            transition: theme.transitions.create('background-color', {
                duration: theme.transitions.duration.shorter,
                easing: theme.transitions.easing.easeOut,
            }),
            '&:hover': {
                backgroundColor: light ? 'rgba(0, 0, 0, 0.13)' : 'rgba(255, 255, 255, 0.13)',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor,
                },
            },
            '&$focused': {
                backgroundColor: light ? 'rgba(0, 0, 0, 0.09)' : 'rgba(255, 255, 255, 0.09)',
            },
            '&$disabled': {
                backgroundColor: light ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
            },
        },
        label: {
            paddingLeft: 12,
        }
    };
});


const sanitizeRestProps = ({
    alwaysOn,
    basePath,
    component,
    defaultValue,
    formClassName,
    initializeForm,
    input,
    isRequired,
    label,
    limitChoicesToValue,
    locale,
    meta,
    options,
    optionText,
    optionValue,
    record,
    resource,
    allowEmpty,
    source,
    textAlign,
    translate,
    translateChoice,
    ...rest
}) => rest;

const githubTheme = {
    default: 'rgba(0, 0, 0, 0.87)',
    background: 'rgba(0, 0, 0, 0.04)',
    background_warning: '#fdd',
    string: '#d14',
    number: '#008080',
    colon: '#49B8F7',
    keys: 'rgba(0, 0, 0, 0.87)',
    keys_whiteSpace: '#835FB6',
    primitive: '#386FA4',
    error: "#e45649"
};

const RenderJsonInput = ({ id, name, value, onChange, onFocus, onBlur, label, helperText, theme, className, ...props }) => {

    const classes = useStyles();

    const handlerChange = ({ error, jsObject = null, json, ...editor }) => {

        if (error) {
            // onChange(new Error("jdjjkdf"));
        } else {
            onChange(jsObject);
        }
    };

    const jsObject = (value) ? (typeof value === 'string') ? JSON.parse(value) : value : null;


    return (
        <FormControl {...props} margin='dense' >
            <div className={`${classes.root}`} >
                {label && <Typography variant="caption" color="textSecondary" className={classes.label} gutterBottom>{label}</Typography>}
                <JSONInput
                    id={id}
                    placeholder={jsObject}
                    onChange={handlerChange}
                    colors={{ ...theme, background: "none" }}
                    locale={locale}
                    height="360px"
                    width="100%"
                    style={{
                        body: {
                            fontSize: '1rem',
                        },
                        contentBox: {
                            color: 'rgba(0, 0, 0, 0.87)',
                        },
                    }}
                />
            </div>
            {helperText && <FormHelperText id={id} >{helperText}</FormHelperText>}
        </FormControl>
    );
};


const JsonInput = ({ label, helperText, onBlur, onFocus, onChange, options, resource, source, validate, className, ...props }) => {

    const { id, input, isRequired, meta: { error, touched } } = useInput({ onBlur, onChange, onFocus, resource, source, validate, ...props });
    const { values } = useFormState();

    return (
        <RenderJsonInput
            id={id}
            {...input}
            className={className}
            label={
                label !== '' &&
                label !== false && (
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                )
            }
            error={!!(touched && error)}
            helperText={
                (touched && error) || helperText ? (
                    <InputHelperText
                        touched={touched}
                        error={error}
                        helperText={helperText}
                    />
                ) : null
            }
            {...options}
            {...sanitizeRestProps(props)}
        />

    )
}

JsonInput.defaultProps = {
    theme: githubTheme,
};

export default JsonInput;

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M14 8h-3v6H6V8H3l5.5-6L14 8m1.5 14l5.5-6h-3v-6h-5v6h-3l5.5 6z" />
    </SvgIcon>
  );
}

export default Icon;
import gql from 'graphql-tag'
import apolloUploadXmlClient from './apolloUploadXmlClient';

export const EXEC_UPLOAD_XML = gql`
    mutation execExtSync($data: ExecInput!) {
        data: execExtSync(data:$data) {
            status
            error
            info
        }
    }
`

export default (args) => {
    return apolloUploadXmlClient.mutate({
        mutation: EXEC_UPLOAD_XML,
        variables: { data: args }
    });
};

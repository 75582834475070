import React from 'react';
import PowerIcon from '@material-ui/icons/Power';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import { Button } from 'react-admin';



const stopPropagation = e => e.stopPropagation();

const ConnectButton = ({
    basePath = '',
    record,
    ...props
}) => (
    <Button
        component={Link}
        to={`${linkToRecord(basePath, record && record.id)}/connect`}
        label='ra.action.connect'
        onClick={stopPropagation}
        {...props}
    >
        <PowerIcon />
    </Button>
);

export default ConnectButton;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useListController, useTranslate, ListContext, ListView, Datagrid, DateField, TextField, Filter, BulkExportButton, BulkDeleteButton } from 'react-admin';
import SearchInput from '../Inputs/SearchInput';
import customExporter, { dateFormatter, enumFormatter } from '../../exporter';
import EnumField from '../Fields/EnumField';
import ColorField from '../Fields/ColorField';
import { ability } from "../../abilityProvider";
import MoreButton from "../Buttons/MoreButton";
import EditMenuItem from "../Buttons/EditButtonAsMenuItem";
import ShowBlankItem from "../Buttons/ShowBlankButtonAsMenuItem";



const useStyles = makeStyles(theme => ({
    row: { height: 40 },
    cellMinWidth: { width: 1 },
    noWrap: { whiteSpace: "nowrap" },
    cellButton: {
        width: 24,
        padding: "0 16px 0 12px"
    },
}));

const TagBulkActionButtons = props => (
    <>
        {ability.can('remove', 'Tag',) && <BulkDeleteButton {...props} />}
        {ability.can('getList', 'Tag',) && <BulkExportButton {...props} />}
    </>
);

const TagFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn resettable />
    </Filter>
);

const modelExport = {
    id: true,
    name: true,
    description: true,
    createdBy: {
        id: true,
        name: true,
        __resource: "User",
    },
    createdAt: dateFormatter,
    updatedAt: dateFormatter,
    color: true,
    type: enumFormatter,
    resource: true,
    __resource: "Tag",
};

const TagList = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const controllerProps = useListController(props);
    const exporter = customExporter(controllerProps.defaultTitle, modelExport, { translate })

    return (
        <ListContext.Provider value={{ ...controllerProps, exporter }}>
            <ListView
                {...props}
                {...controllerProps}
                bulkActionButtons={<TagBulkActionButtons />}
                filters={<TagFilter />}
            >
                <Datagrid rowClick="show" classes={{ row: classes.row }}>
                    <TextField source="name" />
                    <TextField source="description" />
                    {ability.can('getList', 'Tag', 'type') && <EnumField source="type" />}
                    {ability.can('getList', 'Tag', 'resource') && <EnumField source="resource" />}
                    {ability.can('getList', 'Tag', 'color') && <ColorField source="color" hex />}
                    <DateField source="createdAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <DateField source="updatedAt" textAlign="right" cellClassName={classes.cellMinWidth} />
                    <MoreButton cellClassName={`${classes.cellMinWidth} ${classes.cellButton}`}>
                        {ability.can('getOne', 'Tag') && <ShowBlankItem />}
                        {ability.can('update', 'Tag') && <EditMenuItem />}
                    </MoreButton>
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

TagList.defaultProps = {
    filter: { resource_in: ['GasMeter', 'Controller'] },
    perPage: 50,
    sort: { field: 'name', order: 'ASC' },
};



export default TagList;
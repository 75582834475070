import React from 'react';
import { ShowButton, TopToolbar } from 'react-admin';
import { ConnectView } from '../Layout/Connect';
import TitleForRecord from '../Layout/TitleForRecord';
import GasMeterBanner from './GasMeterBanner';
import useConnectController from "../../dataProvider/Core/useConnectController";
import ConnectLayout from "../Layout/ConnectLayout";
import ConnectGroupTree from "../Layout/ConnectGroupTree";

const GasMeterConnectActions = ({ basePath, className, data, hasEdit, hasList, ...props }) => (
    <TopToolbar {...props}>
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);


const GasMeterConnect = props => {
    const controllerProps = useConnectController(props);
    const configuration = controllerProps && controllerProps.record && controllerProps.record.configuration

    return (
        <ConnectView 
            title={<TitleForRecord source="name" {...props}/>}
            actions={<GasMeterConnectActions />}
            {...controllerProps}
        >
            <GasMeterBanner />

            <ConnectLayout >
                <ConnectGroupTree configuration={configuration}/>
            </ConnectLayout>
        </ConnectView>
       
        
    );
};

export default GasMeterConnect;
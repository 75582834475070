import Icon from '../Icons/CorrectorSettings';
import GasMeterConfigurationList from './GasMeterConfigurationList';
import GasMeterConfigurationCreate from './GasMeterConfigurationCreate';
import GasMeterConfigurationEdit from './GasMeterConfigurationEdit';
import GasMeterConfigurationShow from './GasMeterConfigurationShow';

const GasMeterConfigurationIcon = Icon;


export {
    GasMeterConfigurationIcon,
    GasMeterConfigurationList,
    GasMeterConfigurationShow,
    GasMeterConfigurationCreate,
    GasMeterConfigurationEdit,
};


import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles(
    theme => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: 60,
        },
        icon: {
            width: 40,
            height: 40,
        },
        message: {
            textAlign: 'center',
            fontFamily: 'Roboto, sans-serif',
            opacity: 0.5,
            margin: '0 1em',
        },
    }),
    { name: 'ReportLoading' }
);

const Error = ({
    classes: classesOverride,
    className,
    errorPrimary,
    error,
}) => {
    const classes = useStyles({ classes: classesOverride });
    const translate = useTranslate();
    return (
        <div className={`${classes.container} ${className}`}>
            <div className={classes.message}>
                <WarningIcon className={classes.icon} />
                {errorPrimary && <h3>{translate(errorPrimary)}</h3>}
                {error.message && <div>{error.message}</div>}
            </div>
        </div>
    );
};

Error.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    errorPrimary: PropTypes.string,
};

Error.defaultProps = {
    errorPrimary: 'ra.page.error',
};

export default Error;

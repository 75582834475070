import { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import apolloDatabusClient from './apolloDatabusClient';
import get from "lodash/get"
import keyBy from "lodash/keyBy"



const readRegex = /R/i;
const writeRegex = /W/i;
const historyRegex = /H/i;

const SUBSCRIBE_DATA = gql`
  subscription tags($filter: TagSubscriptionFilter!) {
    tags( filter: $filter ) {
      data {
        id {
          ns
          id
          serial
        }
        value
        quality
        timestamp
        timestampSrv
      }
    }
  }  
`

export default function({record, ...props}) {
            
  const ns = get(record, "configuration.ns");
  const serial = get(record, "dbId");
  const uaVariables = get(record, "configuration.parameterSet.uaVariable", []);
  const tagsIds = uaVariables.reduce((result, uaVariable) => {
    
    const isWrite = writeRegex.test(uaVariable.AccessLevel);

    if (isWrite) {
      result.push({ns, serial, id: uaVariable.ID}, {ns, serial, id: uaVariable.ID | 32768})
    } else {
      result.push({ns, serial, id: uaVariable.ID});
    };

    return result;
     
  }, []);


  const version = props.version

  const [state, setState] = useState({
    data: undefined,
    error: null,
    loading: true,
    loaded: false,
  })

  useEffect(() => {
    if (!tagsIds.length > 0 || !serial || !ns) return;
    
    const sub = apolloDatabusClient.subscribe({
      query: SUBSCRIBE_DATA,
      variables: {
        filter: {
          id_in: tagsIds
        },
      },
      fetchPolicy: 'no-cache',
    })

    const subscription = sub.subscribe((nextResult) => {


      setState(prevState => {

        const newTags = get(nextResult, "data.tags.data", [])
        const newTagsMap = keyBy(newTags, (tag) => tag.id.id);
        const prevTags = get(prevState, "data.tags", {})
        const mergedTags = {...prevTags, ...newTagsMap}
        
        return {
          data: {
            tags: mergedTags
          },
          loading: false,
          loaded: true,
        };
      })
     
      },
      error => {
        setState({ 
          error,
          loading: false,
          loaded: false
        });
      }
    );
    
    return () => {
      setState(prevState => ({ ...prevState, loading: true, loaded: false }));
      subscription.unsubscribe();
    };
  },[uaVariables, version]);

  return state;
}

import { createContext } from 'react';


const ConnectContext = createContext({
    data: undefined,
    error: null,
    loading: true,
    loaded: false,
});

ConnectContext.displayName = 'ConnectContext';

export default ConnectContext;

import React, { useCallback } from 'react';
import moment from 'moment'
import 'moment/locale/ru';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Alert from '@material-ui/lab/Alert';
import WarningIcon from '@material-ui/icons/Warning';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {
    useTranslate,
    ReferenceInput,
    AutocompleteInput,
    DateTimeInput,
    TextInput,
    required
} from 'react-admin';
import SwapIcon from '../Icons/Swap';
import StatusChip from "./GasMeterStatusChip";







const useStyles = makeStyles(theme => ({
    formGrid: {
        // display: "grid",
        // gridTemplateColumns: "1fr",
        // gridGap: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    }
}));

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
    values: true,
};

const dateValidation = (value, allValues) => {
    return (value && value instanceof Date && isNaN(value)) ? 'ra.validation.date_invalid' : undefined
};


const validateDate = [dateValidation, required()];
const validateGasMeter = [required()];
const matchSuggestion = () => true;

const GasMeterSwapButtonDialog = ({
    open,
    onClose,
    onSwap,
    record,
    records
}) => {
    const classes = useStyles()
    const translate = useTranslate();

    const handleClose = useCallback(() => {
        onClose()
    }, [onClose])

    const handleSwap = useCallback((values) => {
        onSwap(values)
    }, [onSwap])

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">{translate('ra.swapGasMeter.title', { smart_count: 1 })}</DialogTitle>
            <DialogContent>
                <DialogContentText variant="body1" color="textSecondary" gutterBottom>
                    Для замены выберите Корректор со статусом <StatusChip resource="GasMeter" record={{ status: "DRAFT" }} component="span" /> из списка.
                </DialogContentText>
                {/* <DialogContentText id="alert-dialog-description">{translate('ra.swapGasMeter.content', { smart_count: 1 })}</DialogContentText>
                <DialogContentText id="alert-dialog-description">{translate('ra.swapGasMeter.question', { smart_count: 1 })}</DialogContentText> */}
            </DialogContent>
            <Form
                initialValues={{
                    dateFrom: moment().format(moment.HTML5_FMT.DATETIME_LOCAL)
                }}
                onSubmit={handleSwap}
                subscription={defaultSubscription}
                keepDirtyOnReinitialize
                render={({ handleSubmit, invalid, values, ...other }) => {

                    const selected = records[values.gasMeterId]
                    return (
                        <form>
                            <div className={classes.formGrid}>
                                <ReferenceInput source="gasMeterId" reference="GasMeter" filter={{ status: "DRAFT" }} validate={validateGasMeter} label='ra.swapGasMeter.fields.gasMeter' fullWidth>
                                    <AutocompleteInput optionText="name" matchSuggestion={matchSuggestion} />
                                </ReferenceInput>
                                <TextInput source="reason" fullWidth label='ra.swapGasMeter.fields.reason' />
                                <DateTimeInput source="dateFrom" label='ra.swapGasMeter.fields.dateFrom' validate={validateDate} fullWidth />
                            </div>
                            {selected && <DialogContent>
                                <DialogContentText variant="body1" color="textSecondary" gutterBottom className={classes.mt3}>
                                    Корректор <Typography color="textPrimary" component="span">{record.name}</Typography> потеряет все свои связи и получит статус <StatusChip resource="GasMeter" record={{ status: "SWAP" }} />
                                </DialogContentText>
                                <DialogContentText variant="body1" color="textSecondary" align="center"><SwapIcon /></DialogContentText>
                                <DialogContentText variant="body1" color="textSecondary" gutterBottom>
                                    Корректор <Typography color="textPrimary" component="span">{selected.name}</Typography> получит связи текущего Корректора и статус <StatusChip resource="GasMeter" record={{ status: "TEST" }} />
                                </DialogContentText>
                                <Alert severity="warning" icon={<WarningIcon />} style={{ border: "1px solid #ff9800", marginTop: 24 }} >
                                    <div>Внимание! Данные по старому корректору могут быть перезаписанны данными с нового корректора на глубину выгрузки архивных данных. Убедитесь в отсутствии  наложения данных.</div>
                                </Alert>
                            </DialogContent>}
                            <DialogActions>
                                <Button onClick={handleClose} >{translate(`ra.action.cancel`)}</Button>
                                <Button
                                    disabled={invalid}
                                    onClick={handleSubmit}
                                    color="primary"
                                >{translate(`ra.swapGasMeter.swap`)}</Button>
                            </DialogActions>
                        </form>
                    )
                }}
            />
        </Dialog>
    );
};

export default GasMeterSwapButtonDialog;
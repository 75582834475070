import React from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles, alpha } from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';
import { MenuItemLink, getResources, useTranslate, DashboardMenuItem } from 'react-admin';
import MenuGroup from './MenuGroup';
import classNames from 'classnames';


const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.shape.borderRadius,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginBottom: 4,
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.12),
        },
        '&:last-child': {
            marginBottom: 0,
        },
    },
    active: {
        color: theme.palette.primary.dark,
        backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
    icon: { color: "inherit" },
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: '1.5em',

    },
    stickyContainer: {
        height: "100%",
    },
    sticky: {
        position: "sticky",
        top: '1.5em',
    }
}));


const Menu = ({
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...props
}) => {
    const translate = useTranslate();
    const classes = useStyles({ classes: classesOverride });
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources, shallowEqual);
    const groupResources = groupBy(resources, resource => resource.options.menuGroup || 'default');

    useSelector(state => state.router.location.pathname); // used to force redraw on navigation

    return (
        <div className={classes.stickyContainer}>
            <div />
            <div className={classNames(classes.main, classes.sticky, className)} {...props}>
                {hasDashboard && <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} classes={{ root: classes.root, active: classes.active, icon: classes.icon }} dense={false} />}
                {Object.keys(groupResources).map(group => (
                    <MenuGroup
                        key={group}
                        title={translate(`menu.groups.${group}`)}
                    >
                        {groupResources[group].filter(r => r.hasList).map(resource => (
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                classes={{ root: classes.root, active: classes.active, icon: classes.icon }}
                                primaryText={translate(`resources.${resource.name}.menu`, { _: resource.name, smart_count: 2 })}
                                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                            />
                        ))}
                    </MenuGroup>
                ))
                }
            </div>
        </div>

    );
};

Menu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
    onMenuClick: () => null,
};

export default Menu;

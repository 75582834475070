import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ErrorIcon from '@material-ui/icons/Error';
import get from 'lodash/get';


const getInitials = name => {
    const initials = name.split(" ").map(part => part.charAt(0).toUpperCase()).slice(0, 2).join('');

    return initials;
}

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: "1rem",
        height: 32,
        width: 32,
        margin: -4,
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.primary.light,
    },
}));


const AvatarField = ({ className, record, name, src, ...props }) => {

    const classes = useStyles();
    if (!record) return (
        <Avatar className={className} classes={{ root: classes.root }} >
            <ErrorIcon />
        </Avatar>
    );
    const fullname = get(record, name);
    const avatar = get(record, src);

    return (
        <Avatar alt={fullname} src={avatar} className={className} classes={{ root: classes.root }} >
            {getInitials(fullname)}
        </Avatar>
    );
};

// AvatarField.defaultProps = {
//     addLabel: false,
// };

export default AvatarField;
import React, { useContext } from 'react';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import { makeStyles } from '@material-ui/core/styles';
import ConnectContext from "./ConnectContext";


const useStyles = makeStyles(theme => ({
    root: {
        '& span': {
            opacity: 0,
            animation: "$loader 1s infinite",
        },
        '& span:nth-child(1)': {
            ["-webkit-animation-delay"]: "0.0s",
            animationDelay: "0.0s",
        },
        '& span:nth-child(2)': {
            ["-webkit-animation-delay"]: "0.1s",
            animationDelay: "0.1s",
        },
        '& span:nth-child(3)': {
            ["-webkit-animation-delay"]: "0.2s",
            animationDelay: "0.2s",
        },
    },
    "@keyframes loader": {
        "0%": { opacity: 0 },
        "50%": { opacity: 1 },
        "100%": { opacity: 0 },
    },

}));


const Ellipsisloader = (props) => {
    const classes = useStyles();

    return <span className={classes.root}><span>.</span><span>.</span><span>.</span></span>;
}

export default Ellipsisloader;
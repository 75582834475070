import { Form } from 'react-final-form';
import React, { useEffect, useState } from 'react';
import { fetchEnd, fetchStart } from 'react-admin';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { DateTimeInput } from 'react-admin';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import get from "lodash/get";
import getTagHistory from "../../dataProvider/Databus/getTagHistory";
import moment from "moment";
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-admin';
import { getFinalType, formatValue } from './connectUtils';




const ConnectDialogWriteTag = ({ node, handleClose, ...props }) => {


    const { ns, serial, id, name, unit, type, format, enums } = node;
    const dispatch = useDispatch();
    const translate = useTranslate();
    const [data, setData] = useState();
    const [args, setArgs] = useState({
        id: { ns, serial, id },
        timestamp_gte: moment().add(-1, "day").startOf('day'),
        timestamp_lte: moment().endOf('day')
    });

    const handleFinalFormSubmit = (values) => {
        const { timestamp_gte, timestamp_lte } = values;
        setArgs((prev) => ({
            ...prev,
            timestamp_gte: moment(timestamp_gte),
            timestamp_lte: moment(timestamp_lte),
        }))
    };

    useEffect(() => {

        const getHistoryData = (args) => {
            dispatch(fetchStart());
            getTagHistory(args).then(response => {

                const data = get(response, "data.data.data", []);
                setData(data)
            }).finally(() => {
                dispatch(fetchEnd());

            })
        };

        getHistoryData(args);

        return () => { };

    }, [args, dispatch]);

    if (!node) return null;


    return (

        <>
            <DialogTitle id="form-dialog-title">История</DialogTitle>
            <Box padding="0 24px">
                <Typography variant="body1" color="textSecondary" gutterBottom>{`${name}${unit ? ` ( ${unit} )` : ""}`}</Typography>
            </Box>
            <Form
                initialValues={args}
                onSubmit={handleFinalFormSubmit}
                keepDirtyOnReinitialize
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" padding="0 24px">
                            <DateTimeInput source="timestamp_gte" label="connect.fields.fromAt" inputProps={{ max: moment(args.timestamp_lte).format("YYYY-MM-DD") }} />
                            <span>&nbsp;</span>
                            <DateTimeInput source="timestamp_lte" label="connect.fields.toAt" inputProps={{ min: moment(args.timestamp_gte).format("YYYY-MM-DD") }} />
                            <span>&nbsp;</span>
                            <Button variant="contained" color="primary" disableElevation type="submit" style={{ marginTop: 8, marginBottom: 8 }} >Запрос</Button>
                        </Box>
                    </form>
                )}
            />
            {data && <TableContainer >
                <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Значение</TableCell>
                            <TableCell align="right">Качество</TableCell>
                            <TableCell align="right">Метка времени</TableCell>
                            <TableCell align="right">Метка времени (серв.)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => {
                            const finalType = getFinalType(type, format)
                            const value = formatValue(row.value, finalType, format, enums, translate)
                            const timestamp = formatValue(row.timestamp, "DATETIME", "L LTS");
                            const timestampSrv = formatValue(row.timestampSrv, "DATETIME", "L LTS");

                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row" >{value}</TableCell>
                                    <TableCell align="right">{row.quality}</TableCell>
                                    <TableCell align="right">{timestamp}</TableCell>
                                    <TableCell align="right">{timestampSrv}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>}
            <DialogActions>

                <Button onClick={handleClose} color="primary">
                    Закрыть
</Button>
            </DialogActions>
        </>
    )
};


ConnectDialogWriteTag.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        ns: PropTypes.string.isRequired,
        serial: PropTypes.number.isRequired,
        access: PropTypes.string,
        unit: PropTypes.string,
        type: PropTypes.string,
        isRead: PropTypes.bool,
        isWrite: PropTypes.bool,
        isHistory: PropTypes.bool,
    }),
};

export default React.memo(ConnectDialogWriteTag);
import React, { useState, useEffect } from 'react';
import { Admin, Resource, Pagination, ExportButton } from 'react-admin';
import { authProvider } from './authPrivider';
import { dataProviderFactory } from './dataProvider/dataProviderFactory';
import { i18nProvider } from './i18n/i18nProvider';
import { theme } from './defaultTheme';
import customRoutes from './customRoutes';
import { MyLayout } from './components/Layout';
import LoginPage from './components/Auth/Login';
import user from './redux/user/reducer';
import onlineUsers from './redux/onlineUsers/reducer';
import access from './redux/access/reducer';
import Dashboard from './components/Dashboard';

import { GasMeterList, GasMeterCreate, GasMeterEdit, GasMeterShow, GasMeterIcon } from './components/GasMeter';
import { GasMeterConfigurationList, GasMeterConfigurationCreate, GasMeterConfigurationEdit, GasMeterConfigurationShow, GasMeterConfigurationIcon } from './components/GasMeterConfiguration';
import { ControllerList, ControllerCreate, ControllerEdit, ControllerShow, ControllerIcon } from './components/Controller';
import { ControllerConfigurationList, ControllerConfigurationCreate, ControllerConfigurationEdit, ControllerConfigurationShow, ControllerConfigurationIcon } from './components/ControllerConfiguration';
import { UserList, UserCreate, UserEdit, UserShow, UserIcon } from './components/User';
import { TagList, TagCreate, TagEdit, TagShow, TagIcon } from './components/Tag';
import { HistoryList, HistoryShow, HistoryIcon } from './components/History';
import { TrashItemList, TrashItemIcon } from './components/TrashItem';

import { ability } from "./abilityProvider";
import DeviceList from "./components/Device/DeviceList";
import DeviceShow from "./components/Device/DeviceShow";
import DeviceEdit from "./components/Device/DeviceEdit";
import DeviceCreate from "./components/Device/DeviceCreate";
import { DeviceIcon } from "./components/Device";

ExportButton.defaultProps = {
  maxResults: 5000
}

Pagination.defaultProps = {
  rowsPerPageOptions: [10, 25, 50, 100]
}

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const onMount = () => {
      dataProviderFactory().then(dataProvider => {
        setDataProvider(() => dataProvider);
        setLoading(false);
      });
    }

    onMount();

    return () => {
      setDataProvider(null);
    };

  }, []);

  if (loading) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <Admin
      disableTelemetry
      theme={theme}
      layout={MyLayout}
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      customReducers={{ user, onlineUsers, access }}
      customRoutes={customRoutes}
    >
      { permissions => [
        <Resource name="Device"
          list={ability.can('getList', 'Device') && DeviceList}
          create={ability.can('create', 'GasMeter') && DeviceCreate}
          edit={ability.can('update', 'GasMeter') && DeviceEdit}
          show={ability.can('getOne', 'Device') && DeviceShow}
          icon={DeviceIcon}
        />,
        <Resource name="Controller"
          list={ability.can('getList', 'Controller') && ControllerList}
          create={ability.can('create', 'Controller') && ControllerCreate}
          edit={ability.can('update', 'Controller') && ControllerEdit}
          show={ability.can('getOne', 'Controller') && ControllerShow}
          icon={ControllerIcon}
          options={{ menuGroup: "equipment" }} />,
        <Resource name="GasMeter"
          list={ability.can('getList', 'GasMeter') && GasMeterList}
          create={ability.can('create', 'GasMeter') && GasMeterCreate}
          edit={ability.can('update', 'GasMeter') && GasMeterEdit}
          show={ability.can('getOne', 'GasMeter') && GasMeterShow}
          icon={GasMeterIcon}
          options={{ menuGroup: "equipment" }} />,
        <Resource name="ControllerConfiguration"
          list={ability.can('getList', 'ControllerConfiguration') && ControllerConfigurationList}
          create={ability.can('create', 'ControllerConfiguration') && ControllerConfigurationCreate}
          edit={ability.can('update', 'ControllerConfiguration') && ControllerConfigurationEdit}
          show={ability.can('getOne', 'ControllerConfiguration') && ControllerConfigurationShow}
          icon={ControllerConfigurationIcon}
          options={{ menuGroup: "configurations" }} />,
        <Resource name="GasMeterConfiguration"
          list={ability.can('getList', 'GasMeterConfiguration') && GasMeterConfigurationList}
          create={ability.can('create', 'GasMeterConfiguration') && GasMeterConfigurationCreate}
          edit={ability.can('update', 'GasMeterConfiguration') && GasMeterConfigurationEdit}
          show={ability.can('getOne', 'GasMeterConfiguration') && GasMeterConfigurationShow}
          icon={GasMeterConfigurationIcon}
          options={{ menuGroup: "configurations" }} />,


        <Resource name="User"
          icon={UserIcon}
          list={ability.can('getList', 'User') && UserList}
          show={ability.can('getOne', 'User') && UserShow}
          create={ability.can('create', 'User') && UserCreate}
          edit={ability.can('update', 'User') && UserEdit}
          options={{ menuGroup: "access" }} />,
        <Resource name="Tag"
          icon={TagIcon}
          list={ability.can('getList', 'Tag') && TagList}
          show={ability.can('getOne', 'Tag') && TagShow}
          create={ability.can('create', 'Tag') && TagCreate}
          edit={ability.can('update', 'Tag') && TagEdit}
          options={{ menuGroup: "utils" }} />,
        <Resource name="History"
          list={ability.can('getList', 'History') && HistoryList}
          show={ability.can('getOne', 'History') && HistoryShow}
          icon={HistoryIcon}
          options={{ menuGroup: "utils" }} />,
        <Resource name="TrashItem"
          list={ability.can('getList', 'TrashItem') && TrashItemList}
          icon={TrashItemIcon}
          options={{ menuGroup: "utils" }} />,
        <Resource name="Profile" />,
        <Resource name="GasMeterUnit" />,
      ]}

    </Admin>
  );
};

export default App;
import React from 'react';
import { ability, abilitySubjectConditions as asc, rulesToFields } from '../../abilityProvider';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
    useListController,
    ListView,
    DateField,
    Datagrid,
    TextField,
    Filter,
    useNotify,
    TextInput,
    ListContext
} from 'react-admin';
import SearchInput from '../Inputs/SearchInput';
import EnumField from '../Fields/EnumField';
import BulkRestoreButton from '../Buttons/BulkRestoreButton';
import { DeleteButton } from 'react-admin';
import RestoreButton from '../Buttons/RestoreButton';
import BulkDeleteButton from "../Buttons/BulkDeleteButton";

const useStyles = makeStyles(theme => ({
    row: {
        height: 52
    }
}));

const TrashItemBulkActionButtons = props => {
    const notify = useNotify();
    const handleFailture = (error) => {
        const { graphQLErrors } = error
        const firstError = graphQLErrors[0]
        return (firstError.code === 3042)
            ? notify(`ra.notification.relation_error`, 'warning')
            : notify(`ra.notification.delete_error`, 'warning')
    }

    return (
        <>
            {ability.can("restore", "TrashItem") && <BulkRestoreButton {...props} />}
            {ability.can("delete", "TrashItem") && <BulkDeleteButton {...props} icon={<DeleteForeverIcon />} onFailure={handleFailture} />}
            {/*<BulkExportButton {...props} />*/}
        </>
    )
};

const TrashItemFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn resettable />
    </Filter>
);



const EmbededRestoreButton = (props) => ability.can('restore', props.record.resource) && (<RestoreButton {...props} resource={props.record.resource} record={{ id: props.record.item }} variant="text" />);
const EmbededDeleteButton = (props) => {
    const notify = useNotify();
    const handleFailture = (error) => {
        const { graphQLErrors } = error
        const firstError = graphQLErrors[0]
        return (firstError.code === 3042)
            ? notify(`ra.notification.relation_error`, 'warning')
            : notify(`ra.notification.delete_error`, 'warning')
    }


    return ability.can('delete', props.record.resource) && (
        <DeleteButton
            {...props}
            resource={props.record.resource}
            record={{ id: props.record.item }}
            icon={<DeleteForeverIcon />}
            variant="text"
            onFailure={handleFailture}
        />
    )
};


const TrashItemList = props => {
    const filter = { resource_in: ["GasMeter", "GasMeterConfiguration", "Controller", "ControllerConfiguration", "User"].filter((resource) => ability.can('getList', resource)) };
    const controllerProps = useListController({ ...props, filter });
    const classes = useStyles();

    return (
        <ListContext.Provider value={controllerProps}>
            <ListView
                filters={<TrashItemFilter />}
                bulkActionButtons={<TrashItemBulkActionButtons />}
                exporter={false}
                {...props}
                {...controllerProps}
            >
                <Datagrid /*rowClick={redirectToItem}*/ classes={{ row: classes.row }}>
                    <EnumField source="resource" />
                    <TextField source="name" />
                    <TextField source="description" />
                    <TextField source="deletedBy.name" label="resources.TrashItem.fields.deletedBy" sortable={false} />
                    <DateField source="deletedAt" />
                    <EmbededRestoreButton />
                    <EmbededDeleteButton />
                </Datagrid>
            </ListView>
        </ListContext.Provider>
    )
};

TrashItemList.defaultProps = {
    perPage: 25,
    sort: { field: 'deletedAt', order: 'DESC' },
};

export default TrashItemList;
import gql from 'graphql-tag'
import { apolloAuthClient } from '../../authPrivider/apolloAuthClient';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { URI_CORE } from '../../appsettings';


const GUEST = gql`
    mutation guest {
        data: guest { token refreshToken }
    }
`

async function getGuestToken() {

    const guest = await apolloAuthClient.mutate({
        mutation: GUEST,
        onError: (error) => {
            alert(error)
        },
    });

    return guest.data.data.token
}



const httpLink = createHttpLink({
    uri: URI_CORE
})

const authLink = setContext(async (_, { headers }) => {

    const token = await getGuestToken();

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const apolloCoreClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
    },
});

const BAN_USER = gql`
    mutation banUser( $where: UserWhereUniqueInput!, $banned: Boolean ) {
        data: banUser( where: $where, banned: $banned) {id authId banned}
    }
`
export default (args) => {
    return apolloCoreClient.mutate({
        mutation: BAN_USER,
        variables: { ...args },
        onError: (error) => {
            alert(error)
        },
    });
};
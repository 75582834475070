import { AutocompleteArrayInput, ReferenceArrayInput, SaveButton, SimpleForm, TextInput, Toolbar, required } from 'react-admin';
import CardBlankLayout from '../Layout/CardBlankLayout';
import ControllerBanner from './ControllerBanner';
import { Edit } from '../Layout/Edit';
import ToolbarSpacer from '../Layout/ToolbarSpacer';
import EnumInput from '../Inputs/EnumInput';
import JsonInput from '../Inputs/JsonInput';
import JsonVariableInput from '../Inputs/JsonVariableInput';
import JsonVariableInputs from '../Inputs/JsonVariableInputs';
import PhoneInput from '../Inputs/PhoneInput';
import React from 'react';
// import ReferenceArrayGasMeterInput from './ReferenceArrayGasMeterInput';
import ReferenceArrayGasMeterUnitInput from './ReferenceArrayGasMeterUnitInput';
import ReferenceConfigurationInput from './ReferenceConfigurationInput';
import RemoveButton from '../Buttons/RemoveButton';
import TitleForRecord from '../Layout/TitleForRecord';
import TypeInput from './TypeInput';
import { ability } from "../../abilityProvider";
import { makeStyles } from '@material-ui/core/styles';



const matchTrue = () => true;

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
}));

const GasMeterToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            {ability.can("update", "Controller") && <SaveButton />}
            {ability.can("update", "Controller") && <SaveButton redirect="show" label="ra.action.save_and_continue" submitOnEnter={false} variant="outlined" />}
            <ToolbarSpacer />
            {ability.can("delete", "Controller") && <RemoveButton redirect="list" />}
        </Toolbar>
    );
}

const ControllerEdit = props => {
    // const classes = useStyles();

    return (
        <Edit title={<TitleForRecord source="name" {...props} />} {...props} >
            <ControllerBanner />
            <CardBlankLayout >
                <SimpleForm toolbar={<GasMeterToolbar />} >
                    {ability.can('update', 'Controller', 'status') && <EnumInput source="status" Enum="ControllerStatus" />}
                    {ability.can('update', 'Controller', 'name') && <TextInput source="name" validate={required()} fullWidth />}
                    {ability.can('update', 'Controller', 'description') && <TextInput source="description" fullWidth />}
                    {ability.can('update', 'Controller', 'gasMeterUnits') && <ReferenceArrayGasMeterUnitInput source="gasMeterUnitsIds" reference="GasMeterUnit" filter={{ controller: null }} label="resources.Controller.fields.gasMeterUnits" fullWidth />}
                    {ability.can('update', 'Controller', 'type') && <TypeInput source="type" Enum="ControllerType" option="resources.ControllerType.enums.type" validate={required()} />}
                    {ability.can('update', 'Controller', 'configuration') && <ReferenceConfigurationInput source="configuration.id" reference="ControllerConfiguration" label="resources.Controller.fields.configuration" allowEmpty emptyText="ra.message.no" />}
                    {ability.can('update', 'Controller', 'serialNumber') && <TextInput source="serialNumber" />}
                    {ability.can('update', 'Controller', 'url') && <TextInput source="url" />}
                    {ability.can('update', 'Controller', 'phoneNumber') && <PhoneInput source="phoneNumber" />}
                    {ability.can('update', 'Controller', 'metaVariable') && <JsonVariableInputs source="meta.metaVariable">
                        <JsonVariableInput />
                    </JsonVariableInputs>}
                    {ability.can('update', 'Controller', 'meta') && <JsonInput source="meta" fullWidth />}
                    {/* Убрал из-за путаницы с созданием Устройства. Теперь связь назначается только в Устройстве */}
                    {/* {ability.can('update', 'Controller', 'gasMetersIds') && <ReferenceArrayGasMeterInput source="gasMetersIds" reference="GasMeter" label="resources.Controller.fields.gasMeters" filter={{ controller: null }} formClassName={classes.flexReferenceArrayInput} fullWidth />}    */}
                    {ability.can('update', 'Controller', 'tags') && <ReferenceArrayInput source="tagsIds" filter={{ resource: "Controller" }} reference="Tag" label="resources.Controller.fields.tags" fullWidth>
                        <AutocompleteArrayInput matchSuggestion={matchTrue} />
                    </ReferenceArrayInput>}
                </SimpleForm>
            </CardBlankLayout>
        </Edit>
    );
};

export default ControllerEdit
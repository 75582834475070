import React, { useCallback, useState } from 'react';
import { Button as RaButton, fetchEnd, fetchStart, useNotify, useRedirect, useRefresh } from 'react-admin';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SwapIcon from '../Icons/Swap';
import GasMeterSwapButtonDialog from './GasMeterSwapButtonDialog';
import swapGasMeter from '../../dataProvider/Core/swapGasMeter';



const SwapButton = ({ basePath, record, resource, className, redirect: redirectTo, ...props }) => {
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const records = useSelector(state => state.admin.resources[resource].data, shallowEqual);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSwap = useCallback(({ gasMeterId, reason, dateFrom }) => {
        setLoading(true);
        dispatch(fetchStart());

        swapGasMeter({
            where: { id: record.id },
            swap: { id: gasMeterId },
            reason,
            dateFrom,
        }).then(() => {
            notify('ra.swapGasMeter.swaped', 'info', { smart_count: 1 });
            redirect(redirectTo, basePath, record.id);
            refresh();
            setOpen(false);
        })
            .catch((error) => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    }, [record, dispatch, setLoading, fetchStart, fetchEnd, notify, redirect, refresh, setOpen]);




    return (
        <>
            <RaButton
                onClick={handleOpen}
                disabled={loading}
                label='ra.swapGasMeter.swap'
                className={`ra-swap-button ${className}`}
                key="button"
            >
                <SwapIcon />
            </RaButton>
            <GasMeterSwapButtonDialog
                open={open}
                onClose={handleClose}
                onSwap={handleSwap}
                record={record}
                records={records}
            />
        </>
    );

};

export default SwapButton;